<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ building.name }}</h4>
                    <div class="building-description">
                        {{ building.description }}
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="storage-capacity-wrapper">
                        <div class="row">
                            <div class="col label">Current Capacity: {{ capacityAtCurrentLevel }}</div>
                        </div>
                        <div class="row">
                            <div class="col label">Capacity at Level {{ building.level + 1 }}: {{ capacityAtNextLevel }}</div>
                        </div>
                    </div>
                    <div class="storage-capacity-table">
                        <div class="row">
                            <div class="col label small">Resource</div>
                            <div class="col label large">Storage Full</div>
                            <div class="col label small">Time Until Full</div>
                        </div>
                        <div class="row">
                            <div class="col small">
                                <img src="../assets/icons/metal_icon.svg" class="resource-icon" />
                                Metal
                            </div>
                            <div class="col large">Tomorrow, 09:56:32 PM</div>
                            <div class="col small">36:34:03</div>
                        </div>
                        <div class="row">
                            <div class="col small">
                                <img src="../assets/icons/oil_icon.svg" class="resource-icon" />
                                Oil
                            </div>
                            <div class="col large">Tomorrow, 09:56:32 PM</div>
                            <div class="col small">36:34:03</div>
                        </div>
                        <div class="row">
                            <div class="col small">
                                <img src="../assets/icons/aluminum_icon.svg" class="resource-icon" />
                                Aluminium
                            </div>
                            <div class="col large">Tomorrow, 09:56:32 PM</div>
                            <div class="col small">36:34:03</div>
                        </div>
                        <div class="row">
                            <div class="col small">
                                <img src="../assets/icons/rations_icon.svg" class="resource-icon" />
                                Rations
                            </div>
                            <div class="col large">Tomorrow, 09:56:32 PM</div>
                            <div class="col small">36:34:03</div>
                        </div>
                    </div>
                    <div class="storage-sliders-table">
                        <div class="row">
                            <div class="col label small">Resource</div>
                            <div class="col label large">Percentage</div>
                            <div class="col label small">Capacity</div>
                        </div>
                        <div class="row">
                            <div class="col small">
                                <img src="../assets/icons/metal_icon.svg" class="resource-icon" />
                                Metal
                            </div>
                            <div class="col large">
                                <vue-slider
                                    v-bind="options"
                                    v-model="metalPercentage"
                                    :class="{ 'slider-disabled': !editPercentages }"
                                />
                                <span class="resource-percentage">{{ metalPercentage }}%</span>
                            </div>
                            <div class="col small">
                                {{ Math.floor((capacityAtCurrentLevel * metalPercentage) / 100) }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col small">
                                <img src="../assets/icons/oil_icon.svg" class="resource-icon" />
                                Oil
                            </div>
                            <div class="col large">
                                <vue-slider
                                    v-bind="options"
                                    v-model="oilPercentage"
                                    :class="{ 'slider-disabled': !editPercentages }"
                                />
                                <span class="resource-percentage">{{ oilPercentage }}%</span>
                            </div>
                            <div class="col small">
                                {{ Math.floor((capacityAtCurrentLevel * oilPercentage) / 100) }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col small">
                                <img src="../assets/icons/aluminum_icon.svg" class="resource-icon" />
                                Aluminium
                            </div>
                            <div class="col large">
                                <vue-slider
                                    v-bind="options"
                                    v-model="aluminiumPercentage"
                                    :class="{ 'slider-disabled': !editPercentages }"
                                />
                                <span class="resource-percentage">{{ aluminiumPercentage }}%</span>
                            </div>
                            <div class="col small">
                                {{ Math.floor((capacityAtCurrentLevel * aluminiumPercentage) / 100) }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col small">
                                <img src="../assets/icons/rations_icon.svg" class="resource-icon" />
                                Rations
                            </div>
                            <div class="col large">
                                <vue-slider
                                    v-bind="options"
                                    v-model="rationsPercentage"
                                    :class="{ 'slider-disabled': !editPercentages }"
                                />
                                <span class="resource-percentage">{{ rationsPercentage }}%</span>
                            </div>
                            <div class="col small">
                                {{ Math.floor((capacityAtCurrentLevel * rationsPercentage) / 100) }}
                            </div>
                        </div>
                    </div>
                    <div class="buttons-wrapper">
                        <button v-if="!editPercentages" class="btn yellow-button" @click="toggleEditPercentages">
                            Change percentages
                        </button>
                        <button v-if="editPercentages" class="btn yellow-button" @click="toggleEditPercentages">Confirm</button>
                        <button v-if="editPercentages" class="btn red-button" @click="resetSliders">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component';

export default {
    name: 'StorageBuildingModal',
    components: { VueSlider },
    props: {
        building: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            metalPercentage: 25,
            oilPercentage: 25,
            aluminiumPercentage: 25,
            rationsPercentage: 25,
            editPercentages: false,
            options: {
                dotSize: 14,
                width: '100%',
                height: 4,
                contained: false,
                direction: 'ltr',
                data: null,
                dataLabel: 'label',
                dataValue: 'value',
                min: 0,
                interval: 1,
                disabled: false,
                clickable: true,
                duration: 0,
                adsorb: true,
                lazy: false,
                tooltip: 'active',
                tooltipPlacement: 'top',
                tooltipFormatter: '{value}%',
                useKeyboard: false,
                keydownHook: null,
                dragOnClick: false,
                enableCross: true,
                fixed: false,
                order: true,
                marks: false,
            },
        };
    },
    computed: {
        capacityAtCurrentLevel() {
            return this.building.perLevel[this.building.level - 1];
        },
        capacityAtNextLevel() {
            return this.building.perLevel[this.building.level];
        },
    },
    methods: {
        toggleEditPercentages() {
            this.editPercentages = !this.editPercentages;
        },
        resetSliders() {
            this.metalPercentage = 25;
            this.oilPercentage = 25;
            this.aluminiumPercentage = 25;
            this.rationsPercentage = 25;
            this.toggleEditPercentages();
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

.btn {
    padding: 0.25rem 1rem;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2px;
    margin: 0;
    min-height: 2rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
}

.col.label {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    font-size: 1rem;
}

.small {
    flex-basis: 25%;
}

.large {
    flex-basis: 50%;
}

.storage-capacity-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 2rem;
}

.storage-capacity-table,
.storage-sliders-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 2rem;
}

.storage-sliders-table {
    margin-bottom: 1rem;
    /*opacity: 0.75;*/
}

.resource-icon {
    height: 1rem;
    margin-right: 0.25rem;
}

.slider-disabled {
    pointer-events: none;
    opacity: 50%;
}

.resource-percentage {
    min-width: 2rem;
    text-align: right;
}

.buttons-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 80%;
    }
    .modal-content {
        padding: 3rem 1rem;
    }
    .modal-title {
        width: 80%;
    }
    .building-description {
        width: 80%;
    }
    .storage-capacity-wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .col {
        font-size: 0.75rem;
        min-width: 3rem;
        padding: 0.25rem;
        gap: 0.25rem;
    }
    .col.label {
        font-size: 0.875rem;
    }
    .resource-icon {
        height: 0.75rem;
        margin-right: 0;
    }
    .storage-capacity-table .col:first-child,
    .storage-sliders-table .col:first-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .btn {
        font-size: 0.75rem;
        padding: 0.25rem 0.75rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 96%;
        margin: 0 auto;
    }
    .building-description {
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

@media screen and (max-width: 450px) {
    .modal-content {
        padding: 3rem 0;
    }
    .modal-title {
        width: 100%;
    }
    .building-description {
        width: 100%;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
