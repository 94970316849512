<template>
    <div class="minimap-large-wrapper">
        <div
            v-dragscroll
            ref="minimapWrapper"
            class="minimap-wrapper"
            @scroll="throttle(emitScrollCoords, throttleTimerCoordsEmit, 16)"
            @touchstart="stopCoordsEmit"
            @mousedown="stopCoordsEmit"
        >
            <canvas ref="minimapCanvas" class="minimap" />
        </div>
        <div class="minimap-center-square" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Minimap',
    props: {
		allCities: {
			type: Array,
			default: () => [],
		},
        worldMapScrollCoords: {
            type: Object,
            default: () => {},
        },
        windowSize: {
            type: Object,
            default: () => {},
        },
        mapScaleFactor: {
            type: Number,
            default: 1,
        },
		mapSizeVertical: {
			type: Number,
			default: null,
		},
		mapSizeHorizontal: {
			type: Number,
			default: null,
		},
		currentCityId: {
			type: String,
			default: '',
		},
    },
    data() {
        return {
            decorationsMatrix: [],
            minimapWrapper: null,
            canvas: null,
            ctx: null,
            cityCoords: null,
            canvasWidth: 554,
            canvasHeight: 160,
            canvasScaleFactor: window.devicePixelRatio || 1,
            pointWidth: 13,
            pointHeight: 7.5,
			coordsEmitStopped: false,
            // throttle timers are objects so that they can be passed by reference
            // to throttle function, rather than by value
            throttleTimerCoordsEmit: {
                timer: null,
            },
        };
    },
    computed: {
        dataLoaded() {
            return !!(this.allCities.length && this.mapSizeHorizontal);
        },
        minimapCenterSquareWidth() {
            return (this.windowSize?.width * (this.canvasWidth / (this.mapSizeHorizontal * 214))) / this.mapScaleFactor;
        },
        minimapCenterSquareHeight() {
            return (this.windowSize?.height * (this.canvasHeight / (this.mapSizeVertical * 62))) / this.mapScaleFactor;
        },
        minimapPaddingX() {
            return 120 - this.minimapCenterSquareWidth / 2;
        },
        minimapPaddingTop() {
            return 120 - this.minimapCenterSquareHeight / 2 - this.pointHeight / 4;
        },
        minimapPaddingBottom() {
            return 120 - this.minimapCenterSquareHeight / 2 - this.pointHeight;
        },
    },
    watch: {
        mapScaleFactor: {
            handler() {
                // stop view from sliding when zooming out on map border
                this.stopCoordsEmit();
                this.allowCoordsEmit();
            },
        },
        '$store._state.data.city.decorationsMatrix': {
            handler() {
                if (this.$store._state.data.city.decorationsMatrix.length) {
                    this.decorationsMatrix = this.getDecorationsMatrix();
                }
            },
            deep: true,
        },
        dataLoaded: {
            handler() {
                if (this.dataLoaded) {
                    this.canvasWidth = this.pointWidth * this.mapSizeHorizontal;
                    this.canvasHeight = (this.pointHeight * this.mapSizeVertical) / 2;
                    this.drawMinimap();
                }
            },
            deep: true,
        },
        worldMapScrollCoords: {
            handler() {
                if (!this.coordsEmitStopped) {
                    if (this.minimapWrapper !== undefined) {
                        this.minimapWrapper.scrollLeft =
                            this.worldMapScrollCoords.x * (this.canvasWidth / (this.mapSizeHorizontal * 214));
                        this.minimapWrapper.scrollTop =
                            this.worldMapScrollCoords.y * (this.canvasHeight / (this.mapSizeVertical * 62));
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        ...mapGetters(['getDecorationsMatrix']),
        throttle(func, timer, delay) {
            if (timer.timer === null) {
                func();
                timer.timer = setTimeout(() => {
                    timer.timer = null;
                }, delay);
            }
        },
        drawMinimap() {
            this.initializeMinimap();
            this.drawDecorations();
            this.drawGrid();
            this.drawCities();
        },
        initializeMinimap() {
            this.minimapWrapper = this.$refs.minimapWrapper;
            this.canvas = this.$refs.minimapCanvas;
            this.canvas.width = this.canvasWidth * this.canvasScaleFactor;
            this.canvas.height = this.canvasHeight * this.canvasScaleFactor;
            this.ctx = this.canvas.getContext('2d');
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.ctx.scale(this.canvasScaleFactor, this.canvasScaleFactor);
        },
        drawGrid() {
			// here we can use both values (mapSizeVertical && mapSizeHorizontal) interchangeably
            for (let i = 0; i <= this.mapSizeHorizontal * 2; i++) {
                const startX = this.pointWidth * i;
                const startY = 0;
                const endX = 0;
                const endY = this.pointHeight * i;

                this.ctx.beginPath();
                this.ctx.moveTo(startX, startY);
                this.ctx.lineTo(endX, endY);
                this.ctx.closePath();

                this.ctx.strokeStyle = '#5d4c36';
                this.ctx.lineWidth = 1;
                this.ctx.stroke();
            }
            for (let i = this.mapSizeHorizontal; i >= -this.mapSizeHorizontal; i--) {
                const startX = 0;
                const startY = this.pointHeight * i;
                const endX = this.pointWidth * (this.mapSizeHorizontal - i);
                const endY = this.pointHeight * this.mapSizeHorizontal;

                this.ctx.beginPath();
                this.ctx.moveTo(startX, startY);
                this.ctx.lineTo(endX, endY);
                this.ctx.closePath();

                this.ctx.strokeStyle = '#5d4c36';
                this.ctx.lineWidth = 1;
                this.ctx.stroke();
            }
            for (let i = 0; i <= this.mapSizeHorizontal; i += 18.5) {
                this.ctx.beginPath();
                this.ctx.moveTo(0, this.pointHeight * i);
                this.ctx.lineTo(this.pointWidth * this.mapSizeHorizontal, this.pointHeight * i);
                this.ctx.closePath();

                this.ctx.strokeStyle = 'black';
                this.ctx.lineWidth = 1;
                this.ctx.stroke();
            }
			for (let i = 0; i <= this.mapSizeHorizontal; i += 12.5) {
				this.ctx.beginPath();
				this.ctx.moveTo(this.pointWidth * i, 0);
				this.ctx.lineTo(this.pointWidth * i, this.pointHeight * this.mapSizeHorizontal);
				this.ctx.closePath();

				this.ctx.strokeStyle = 'black';
				this.ctx.lineWidth = 1;
				this.ctx.stroke();
			}
        },
        drawCities() {
            for (let i = 0; i < this.allCities.length; i++) {
                const x =
                    this.pointWidth * (this.allCities[i].coords.x - 1) -
                    (this.allCities[i].coords.y % 2 === 0 ? 0 : this.pointWidth / 2);
                const y = (this.allCities[i].coords.y - 1) * (this.pointHeight / 2);
                this.ctx.beginPath();
                this.ctx.moveTo(x, y - this.pointHeight / 2); // Top point
                this.ctx.lineTo(x + this.pointWidth / 2, y); // Right point
                this.ctx.lineTo(x, y + this.pointHeight / 2); // Bottom point
                this.ctx.lineTo(x - this.pointWidth / 2, y); // Left point
                this.ctx.closePath();
                this.ctx.fillStyle = this.getCityMinimapColor(this.allCities[i]);
                this.ctx.strokeStyle = 'black';
                this.ctx.lineWidth = 1;
                this.ctx.fill();
                this.ctx.stroke();
            }
        },
        drawDecorations() {
            for (let i = 0; i < this.decorationsMatrix.length; i++) {
                const x =
                    this.pointWidth * (this.decorationsMatrix[i].x - 1) -
                    (this.decorationsMatrix[i].y % 2 === 0 ? 0 : this.pointWidth / 2);
                const y = (this.decorationsMatrix[i].y - 1) * (this.pointHeight / 2);
                this.ctx.beginPath();
                this.ctx.moveTo(x, y - this.pointHeight / 2); // Top point
                this.ctx.lineTo(x + this.pointWidth / 2, y); // Right point
                this.ctx.lineTo(x, y + this.pointHeight / 2); // Bottom point
                this.ctx.lineTo(x - this.pointWidth / 2, y); // Left point
                this.ctx.closePath();
                this.ctx.fillStyle = this.decorationsMatrix[i].name === 'Forest' ? '#7c7f46' : '#927d6a';
                this.ctx.fill();
            }
        },
        stopCoordsEmit() {
            this.coordsEmitStopped = true;
            window.addEventListener('mouseup', this.allowCoordsEmit);
            window.addEventListener('touchend', this.allowCoordsEmit);
        },
        allowCoordsEmit() {
            setTimeout(() => {
                this.coordsEmitStopped = false;
                window.removeEventListener('mouseup', this.allowCoordsEmit);
                window.removeEventListener('touchend', this.allowCoordsEmit);
            }, 100);
        },
		getCityMinimapColor(city) {
			switch (city.color) {
				case 'Dark Grey':
					return 'darkgray'
				case 'White':
					return 'white'
				case 'Yellow':
					return 'gold'
			}
		},
        emitScrollCoords() {
            this.$emit('minimap-scroll', {
                x: this.minimapWrapper.scrollLeft / (this.canvasWidth / (this.mapSizeHorizontal * 214)),
                y: this.minimapWrapper.scrollTop / (this.canvasHeight / (this.mapSizeVertical * 62)),
            });
        },
    },
};
</script>

<style scoped>
.minimap-large-wrapper {
    position: relative;
}

/*.minimap-large-wrapper::before {*/
/*  position: absolute;*/
/*  content: "";*/
/*  height: 1px;*/
/*  width: 236px;*/
/*  background: linear-gradient(*/
/*    to right,*/
/*    black,*/
/*    black v-bind(118 - minimapCenterSquareWidth / 2 + "px"),*/
/*    transparent v-bind(118 - minimapCenterSquareWidth / 2 + "px"),*/
/*    transparent v-bind(118 + minimapCenterSquareWidth / 2 + "px"),*/
/*    black v-bind(118 + minimapCenterSquareWidth / 2 + "px")*/
/*  );*/
/*  top: 50%;*/
/*  left: 2px;*/
/*  z-index: 1;*/
/*}*/

/*.minimap-large-wrapper::after {*/
/*  position: absolute;*/
/*  content: "";*/
/*  width: 1px;*/
/*  height: 236px;*/
/*  background: linear-gradient(*/
/*      to bottom,*/
/*      black,*/
/*      black v-bind(118 - minimapCenterSquareHeight / 2 + "px"),*/
/*      transparent v-bind(118 - minimapCenterSquareHeight / 2 + "px"),*/
/*      transparent v-bind(118 + minimapCenterSquareHeight / 2 + "px"),*/
/*      black v-bind(118 + minimapCenterSquareHeight / 2 + "px")*/
/*  );*/
/*  left: 50%;*/
/*  top: 2px;*/
/*  z-index: 1;*/
/*}*/

.minimap-wrapper {
    width: 240px;
    height: 240px;
    overflow: hidden;
    position: relative;
    border: 2px solid #434343;
    background: #79664b;
    cursor: move;
    /*display: flex;*/
    /*align-items: center;*/
}

.minimap {
    /*position: absolute;*/
    width: v-bind(canvasWidth + 'px');
    height: v-bind(canvasHeight + 'px');
    margin: v-bind(minimapPaddingTop + 'px') v-bind(minimapPaddingX + 'px') v-bind(minimapPaddingBottom + 'px')
        v-bind(minimapPaddingX + 'px');
}

.minimap-center-square {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: v-bind(minimapCenterSquareWidth + 'px');
    height: v-bind(minimapCenterSquareHeight + 'px');
    outline: 1px solid black;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;
}
</style>
