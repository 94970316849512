<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                    <p class="center-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Purus gravida quis blandit turpis cursus in hac. Egestas sed sed risus pretium. At
                        erat pellentesque adipiscing commodo elit at imperdiet dui. Ipsum dolor sit amet consectetur adipiscing.
                        Donec ultrices tincidunt arcu non sodales neque sodales ut. At elementum eu facilisis sed odio morbi. In
                        nibh mauris cursus mattis. Nulla aliquet porttitor lacus luctus accumsan. Vivamus at augue eget arcu
                        dictum varius duis at. Massa tempor nec feugiat nisl pretium. Elit scelerisque mauris pellentesque
                        pulvinar pellentesque habitant. Iaculis eu non diam phasellus vestibulum lorem sed risus. Turpis nunc eget
                        lorem dolor sed viverra ipsum.
                    </p>
                    <p class="center-text">
                        Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Nisl pretium fusce id velit ut
                        tortor. Donec adipiscing tristique risus nec feugiat in fermentum posuere urna. Nibh tellus molestie nunc
                        non blandit massa enim nec dui. Scelerisque purus semper eget duis at. Pharetra sit amet aliquam id diam
                        maecenas ultricies mi. Rutrum quisque non tellus orci ac auctor augue mauris. Tellus id interdum velit
                        laoreet. Risus in hendrerit gravida rutrum quisque non tellus orci. Laoreet non curabitur gravida arcu ac
                        tortor. Non odio euismod lacinia at quis risus. Egestas dui id ornare arcu odio ut sem nulla pharetra.
                        Adipiscing at in tellus integer feugiat scelerisque varius. Commodo nulla facilisi nullam vehicula.
                        Vulputate odio ut enim blandit volutpat. Elementum nisi quis eleifend quam adipiscing vitae proin. Nunc
                        sed augue lacus viverra vitae congue. Ac felis donec et odio pellentesque diam volutpat commodo. Neque
                        sodales ut etiam sit amet nisl purus in. Aliquet nibh praesent tristique magna sit amet.
                    </p>
                    <p class="center-text">
                        Mattis nunc sed blandit libero volutpat sed cras. Enim blandit volutpat maecenas volutpat blandit aliquam
                        etiam. Non tellus orci ac auctor augue mauris. Gravida cum sociis natoque penatibus et magnis dis.
                        Ullamcorper morbi tincidunt ornare massa eget egestas purus. Pharetra et ultrices neque ornare aenean
                        euismod elementum. Ornare arcu dui vivamus arcu felis bibendum. Duis at tellus at urna condimentum mattis
                        pellentesque id nibh. Est pellentesque elit ullamcorper dignissim. Sed velit dignissim sodales ut eu sem
                        integer vitae. Cursus risus at ultrices mi. Ullamcorper eget nulla facilisi etiam dignissim diam quis
                        enim. Diam maecenas ultricies mi eget mauris pharetra et. Consectetur a erat nam at.
                    </p>
                    <p class="center-text">
                        Ornare suspendisse sed nisi lacus sed viverra tellus in hac. Nibh tortor id aliquet lectus proin. Amet est
                        placerat in egestas erat imperdiet sed euismod nisi. Egestas tellus rutrum tellus pellentesque eu
                        tincidunt tortor aliquam. Quisque sagittis purus sit amet volutpat consequat mauris nunc congue. Volutpat
                        ac tincidunt vitae semper quis lectus nulla at volutpat. Gravida neque convallis a cras. Sem et tortor
                        consequat id porta nibh. Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque. Eget nulla
                        facilisi etiam dignissim diam quis enim lobortis. Elementum nisi quis eleifend quam adipiscing vitae proin
                        sagittis nisl. Id diam maecenas ultricies mi eget mauris. Lacus viverra vitae congue eu. A scelerisque
                        purus semper eget duis at tellus at urna. Aliquet nec ullamcorper sit amet. Tellus orci ac auctor augue
                        mauris augue neque gravida. Justo laoreet sit amet cursus sit amet dictum. Ultrices gravida dictum fusce
                        ut placerat orci nulla pellentesque.
                    </p>
                    <p class="center-text">
                        A iaculis at erat pellentesque adipiscing commodo elit at imperdiet. Urna molestie at elementum eu
                        facilisis sed. Elit duis tristique sollicitudin nibh sit amet commodo nulla. Integer quis auctor elit sed.
                        Gravida rutrum quisque non tellus orci ac auctor. Sit amet nisl purus in. Hendrerit dolor magna eget est
                        lorem ipsum. Diam vulputate ut pharetra sit amet aliquam id. Iaculis eu non diam phasellus vestibulum
                        lorem. Lacinia quis vel eros donec ac odio. Blandit aliquam etiam erat velit scelerisque in dictum non.
                        Tincidunt dui ut ornare lectus sit amet. Commodo odio aenean sed adipiscing diam donec adipiscing
                        tristique.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CookiePolicyModal',
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 4vh;
    padding: 0;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6rem 2rem 2rem 2rem;
}

.center-text {
    text-indent: 1.5rem;
    text-align: justify;
    color: #afafaf;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 60%;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 75%;
        margin: 0 auto;
    }
    .modal-body {
        padding: 4rem 1.25rem 1.25rem 1.25rem;
    }
    .center-text {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 450px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 380px) {
    .center-text {
        font-size: 0.75rem;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
