<template>
    <div id="register">
        <ErrorPopup :error="emailError" :pop-out="popOut" />
        <div class="form-layer">
            <div class="soldier-image col-xl-3 col-lg-3"></div>
            <div class="form-wrapper col-xl-3 col-lg-3 col-md-5 col-sm-6 col-10">
                <p class="warning-message">
                    Currently, our website is under development, so accessing it at the current stage is done at the user's own risk, our website is not assuming any risk in this regard.
                </p>
                <RegisterForm />
            </div>
        </div>
    </div>
</template>

<script>
import RegisterForm from '../components/RegisterForm';
import { mapGetters, mapActions } from 'vuex';
import ErrorPopup from '@/components/ErrorPopup';

export default {
    setup() {},
    components: {
        ErrorPopup,
        RegisterForm,
    },
    data() {
        return {
            emailError: '',
            popOut: false,
        };
    },
    methods: {
        ...mapGetters(['getEmail', 'getPassword', 'getUsername', 'getAreCredentialsValid', 'getEmailValid']),
        ...mapActions(['register', 'checkForToken', 'storeCredentials']),
    },

    //TO DO
    //USE WATCHER FOR MAKING REGISTER REQUEST

    watch: {
        emailError: {
            handler() {
                if (this.emailError) {
                    setTimeout(() => {
                        this.popOut = true;
                    }, 3000);
                    setTimeout(() => {
                        this.emailError = '';
                        this.popOut = false;
                    }, 3500);
                }
            },
        },
    },

    beforeRouteLeave(to, from, next) {
        this.emailError = '';

        if (to.fullPath === '/' || this.getAreCredentialsValid()) {
            next();
        }

        if (!this.getEmail()) {
            const checkEmailWasSet = setInterval(() => {
                if (this.getEmail()) {
                  clearInterval(checkEmailWasSet);
                  this.register({
                    email: this.getEmail(),
                    username: this.getUsername(),
                    password: this.getPassword(),
                  }).then(() => {
                    if (!this.getEmailValid()) {
                        this.emailError = "We've encountered an error. Please try again later";
                        return;
                    }
                    next();
                  });

                }
            }, 100);

        } else {
            this.register({
                email: this.getEmail(),
                username: this.getUsername(),
                password: this.getPassword(),
            }).then(() => {
                if (!this.getEmailValid()) {
                    this.emailError = "We've encountered an error. Please try again later";
                    return;
                }
                next();
            });
        }
    },
    created() {
        this.checkForToken().then(() => {
            const successfullLogin = this.getAreCredentialsValid();

            if (successfullLogin) {
                this.$router.push({ path: '/city' });
            }
        });
    },
};
</script>

<style scoped>
#register {
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    display: flex;
    align-items: center;
}

.form-layer {
    position: absolute;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.soldier-image {
    background-image: url('../assets/full_troop_images/infantryman-full.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    max-height: 100%;
    z-index: 1;
}

.form-wrapper {
    background: rgba(0, 0, 0, 0.63);
    padding: 2rem;
    border-top: none;
    border-bottom: none;

    /* BT-187 */
    overflow: overlay;
    height: 80vh;
    z-index: 1;
    /* BT-187 */
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.form-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.form-wrapper::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

/* BT-187 */
.warning-message {
    color: #d34848;
    font-size: 0.875rem;
    line-height: 0.875rem;
    text-indent: 2rem;
}
/* BT-187 */
</style>
