import { arrayWrapper } from '@/utils/functions/templateUtils';

export const COALITION_TEMPLATE = Object.freeze({
    _id: '',
    name: '',
    abbreviation: '',
    description: '',
    points: 0,
    allMembers: arrayWrapper({
        _id: '',
        username: '',
        points: 0,
        rank: 0,
        cities: [],
        status: '',
    }),
    general: '',
    lieutenants: [],
    members: [],
    alliances: arrayWrapper({
        name: '',
        _id: '',
    }),
    nonAggressionPacts: arrayWrapper({
        name: '',
        _id: '',
    }),
    wars: arrayWrapper({
        _id: '',
        initiatorCoalition: {
            name: '',
            _id: '',
        },
        targetCoalition: {
            name: '',
            _id: '',
        },
        initiatorNumOfCities: 0,
        targetNumOfCitites: 0,
        progressForInitiator: 0,
        progressForTarget: 0,
        effectsInitiator: {
            name: '',
            description: '',
            influencedProperties: [],
            ammount: [],
        },
        effectsTarget: {
            name: '',
            description: '',
            influencedProperties: [],
            ammount: [],
        },
        successfulAttacksFromInitiator: 0,
        successfulAttacksFromTarget: 0,
        achievedGoals: {
            name: '',
            description: '',
            owner: [],
        },
        dateCreated: '',
    }),
    numOfMembers: 0,
    maxNumOfMembers: 0,
    additionRule: '',
    events: arrayWrapper({
        text: '',
        dateCreated: '',
    }),
    chat: arrayWrapper({
        user: '',
        message: '',
        dateCreated: '',
    }),
    rights: arrayWrapper({
        user: '',
        rank: '',
        permissions: {
            permission: '',
            hasIt: false,
        },
    }),
    invites: arrayWrapper({
        _id: '',
        username: '',
    }),
    receivedRequests: arrayWrapper({
        _id: '',
        username: '',
    }),
    pactInvitations: arrayWrapper({
        id: '',
        from: {
            _id: '',
            name: '',
        },
        to: {
            _id: '',
            name: '',
        },
        pact: '',
        typeOfPeace: '',
        tribute: 0,
        warId: '',
        dateCreated: '',
    }),
    canAttackAllies: false,
    tributeToReceive: arrayWrapper({
        metal: 0,
        aluminum: 0,
        oil: 0,
        rations: 0,
        cities: [],
        coalition: {
            _id: '',
            name: '',
        },
    }),
    tributeToPay: arrayWrapper({
        coalition: {
            _id: '',
            name: '',
        },
        tributePerUser: {
            user: {
                _id: '',
                username: '',
            },
            cities: {
                metal: 0,
                aluminum: 0,
                oil: 0,
                rations: 0,
                city: '',
            },
            totalAmount: {
                metal: 0,
                aluminum: 0,
                oil: 0,
                rations: 0,
            },
        },
    }),
});
