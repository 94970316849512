// any object wrapped with an arrayWrapper function will disregard the fact that
// it's element 0 of an array and will be treated as an object by this parser

let FINAL_STRING = '';

function parserFunction(state_template) {
    const CURRENT_KEYS = Object.keys(state_template);

    FINAL_STRING += '{ ';
    CURRENT_KEYS.forEach(key => {
        FINAL_STRING += key;
        if (typeof state_template[key] === 'object' && !Array.isArray(state_template[key])) {
            FINAL_STRING += ' ';
            parserFunction(state_template[key]);
        } else if (Array.isArray(state_template[key]) && state_template[key].length) {
            if (state_template[key]?.length) {
                FINAL_STRING += ' ';
                parserFunction(state_template[key][0]);
            }
        } else {
            if (key !== CURRENT_KEYS[CURRENT_KEYS.length - 1]) {
                FINAL_STRING += ', ';
            }
        }
    });
    FINAL_STRING += ' }, ';
}

export default function parseTemplateForSubscription(state_template) {
    parserFunction(state_template);

    const STRING_ARRAY = FINAL_STRING.split(" ")
    STRING_ARRAY.splice(STRING_ARRAY.length - 2, 0, 'paths actions indexes toReplace')

    // FINAL_STRING is declared outside the function scope, so we need
    // to reset it for when we call parseTemplateForSubscription again in the future
    FINAL_STRING = '';

    return STRING_ARRAY.join(" ");
}