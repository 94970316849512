import { arrayWrapper } from '@/utils/functions/templateUtils';

export const CONVERSATIONS_TEMPLATE = Object.freeze({
    fromUsers: arrayWrapper({
        title: '',
        dateCreated: '',
        participants: [],
        messages: {
            from: '',
            text: '',
            dateCreated: '',
        },
        canRespond: false,
    }),
    fromCoalition: arrayWrapper({
        title: '',
        dateCreated: '',
        participants: [],
        messages: {
            from: '',
            text: '',
            dateCreated: '',
        },
        canRespond: false,
    }),
});
