<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close" @click="closeModal">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                    <div class="w-100 d-flex justify-content-evenly">
                        <span class="clickable">{{ peaceProposal?.from?.name || userCoalitionName }}</span>
                        <span>&#8680;</span>
                        <span class="clickable">{{ peaceProposal?.to?.name || userCoalitionName }}</span>
                    </div>
                    <div class="tribute-info-table mt-5 w-100">
                        <div class="row">
                            <div class="col standalone-label">Type of peace</div>
                            <div class="col">{{ typeOfPeace }}</div>
                        </div>
                        <div class="row">
                            <div class="col standalone-label">Tribute</div>
                            <div class="col">{{ peaceProposal?.tribute }}%</div>
                        </div>
                    </div>
                    <div class="d-flex gap-3 mt-5">
                        <button
                            v-if="peaceProposal?.from"
                            class="btn blue-button"
                            @click="$emit('open-confirm-modal-accept', peaceProposal)"
                        >
                            Accept
                        </button>
                        <button
                            v-if="peaceProposal?.from"
                            class="btn red-button"
                            @click="$emit('open-confirm-modal-refuse', peaceProposal)"
                        >
                            Refuse
                        </button>
                        <button
                            v-if="peaceProposal?.to"
                            class="btn yellow-button"
							@click="$emit('open-confirm-modal-retract', peaceProposal)"
                        >
                            Retract
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'TributeInfoModal',
    props: {
        userCoalitionName: {
            type: String,
            default: '',
        },
        userCoalitionId: {
            type: String,
            default: '',
        },
        peaceProposal: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            confirmationModalProps: {
                title: '',
                action: () => {},
            },
        };
    },
    emits: ['open-confirm-modal-accept', 'open-confirm-modal-refuse', 'open-confirm-modal-retract'],
    computed: {
        typeOfPeace() {
            switch (this.peaceProposal?.typeOfPeace) {
                case 'white peace':
                    return 'White Peace';
                case 'tribute':
                    return 'Tribute';
                default:
                    return '';
            }
        },
    },
    methods: {
        closeModal() {
            document.getElementById('tribute-info-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('tribute-info-modal').style.transform = 'translateY(-20px)';
            document.getElementById('tribute-info-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('tribute-info-modal').style.display = 'none';
                document.getElementById('tribute-info-modal').classList.remove('show');
                document.getElementById('tribute-info-modal').style.removeProperty('transform');
                document.getElementById('tribute-info-modal').style.removeProperty('opacity');
            }, 150);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 26vh;
    padding: 0;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 3rem 2rem 3rem;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    color: cornflowerblue;
}

.tribute-info-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.standalone-label {
    background: linear-gradient(to bottom, #2a2a2a, #181818) !important;
    /*color: ghostwhite;*/
    /*min-height: 2rem;*/
    /*padding-left: 0.5rem;*/
    /*display: flex;*/
    /*align-items: center;*/
}

.row {
    flex-wrap: nowrap;
    gap: 2px;
    margin: 0;
}

.col {
    position: relative;
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 2px;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
}

.btn {
    padding: 0.25rem 1rem;
    width: fit-content;
    height: fit-content;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.blue-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgb(34, 41, 58), rgba(255, 255, 255, 0.2));
    border: 3px solid dodgerblue;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.blue-button:hover {
    background: linear-gradient(to bottom, rgba(96, 95, 42, 0.2), rgb(52, 52, 70));
    color: ghostwhite;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 60%;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 75%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 450px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
