import { createStore } from 'vuex';
import authentication from './modules/Authentication/index';
import city from './modules/City/index';

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        authentication,
        city,
    },
});
