import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import PrimeVue from 'primevue/config';
import VueDragscroll from 'vue-dragscroll';
import Button from 'primevue/button';
import Password from 'primevue/password';
import 'primeflex/primeflex.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

const app = createApp(App);

app.use(store).use(router).use(PrimeVue);

app.component('Button', Button);
app.component('Password', Password);
app.use(VueDragscroll);
app.mount('#app');
