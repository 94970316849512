<template>
    <form id="login-form" class="row g-3" novalidate>
        <div class="form-group">
            <label for="username" :class="{ invalid: v$.username.$errors.length !== 0 }" class="form-label">Username:</label>
            <input
                type="text"
                :class="{ invalid: v$.username.$errors.length !== 0 }"
                class="form-control"
                id="username"
                name="username"
                v-model="username"
                required
            />
            <p v-for="error of v$.username.$errors" :key="error.$uid" class="form-error">
                {{ error.$message }}
            </p>
        </div>
        <div class="form-group">
            <label for="password" :class="{ invalid: v$.password.$errors.length !== 0 }" class="form-label">Password:</label>
            <input
                type="password"
                v-model="password"
                :class="{ invalid: v$.password.$errors.length !== 0 }"
                class="form-control"
                name="password"
                id="password"
                required
            />
            <p v-for="error of v$.password.$errors" :key="error.$uid" class="form-error">
                {{ error.$message }}
            </p>
        </div>
        <p v-if="!getAreCredentialsValid && getAttemptedLogin" class="form-error">Incorrect username or password</p>
        <div class="login-button-wrapper">
            <span class="forgot" type="button" data-bs-toggle="modal" data-bs-target="#credentials-recovery-modal">
                I forgot username or password
            </span>
            <router-link to="/city" tag="button">
                <button class="btn login-button" v-on:click="storeUserCredentials()">Login</button>
            </router-link>
        </div>
        <div class="register-group">
            <p class="account-question">Don't have an account yet?</p>
            <router-link to="/register" tag="button">
                <button class="btn register-button">Register now!</button>
            </router-link>
        </div>
    </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

export default {
    name: 'LoginForm',
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            username: '',
            password: '',
        };
    },
    validations() {
        return {
            username: {
                required: helpers.withMessage('Please fill in your username', required),
            }, // Matches this.username
            password: {
                required: helpers.withMessage('Please fill in your password', required),
            },
        };
    },
    watch: {
        username: {
            handler() {
                if (this.v$.$dirty) {
                    this.v$.$reset();
                }
                if (this.getAttemptedLogin) {
                    this.updateAttemptedLogin(false);
                }
            },
        },
        password: {
            handler() {
                if (this.v$.$dirty) {
                    this.v$.$reset();
                }
                if (this.getAttemptedLogin) {
                    this.updateAttemptedLogin(false);
                }
            },
        },
    },
    methods: {
        ...mapActions(['storeCredentials', 'updateAttemptedLogin']),

        storeUserCredentials() {
            this.v$.$touch();

            if (this.v$.$errors.length) {
                return;
            }

            this.storeCredentials({
                username: this.username,
                password: this.password,
            });
        },
    },
    computed: mapGetters(['getAreCredentialsValid', 'getAttemptedLogin']),
};
</script>

<style scoped>
#login-form {
    position: relative;
    font-family: 'sansation', sans-serif;
    font-weight: 500;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-label {
    font-size: 1rem;
    font-weight: 500;
    color: whitesmoke;
}

.form-control {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.form-error {
    margin: 0.25rem 0;
    color: #c72626;
}

.form-label.invalid {
    color: #c72626;
}

.form-control.invalid {
    border-color: #c72626;
}

.login-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.forgot {
    font-size: 1rem;
    color: ghostwhite;
    text-decoration: underline;
    margin: 0 0 1rem 0;
}

.forgot:hover {
    color: cornflowerblue;
}

.login-button {
    font-family: 'bebas', sans-serif;
    width: 10rem;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
}

.login-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.register-button {
    font-family: 'bebas', sans-serif;
    width: 10rem;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
}

.register-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.register-group {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
}

.account-question {
    color: ghostwhite;
    margin-bottom: 0.5rem;
    font-weight: 500;
}
</style>
