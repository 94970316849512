export default class MapActionCollection {
	actions = new Set;

	constructor(actions) {
		actions.forEach(action => actions.add(action));
	}

	addAction(action) {
		action.setCollectionPointer(this)
		this.actions.add(action);
	}

	deleteAction(action) {
		this.actions.delete(action);
	}

	startCountdowns() {
		this.actions.forEach(action => action.startCountdown())
	}
}