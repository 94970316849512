<template>
    <div
        id="credentials-recovery-modal"
        data-bs-backdrop="false"
        data-bs-keyboard="false"
        class="modal fade"
        tabindex="-1"
        role="dialog"
    >
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Account recovery</h5>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" @click.prevent="resetModal()">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div v-if="!emailSent" class="modal-body">
                    <form id="recovery-form" class="row g-3 col-lg-6" novalidate>
                        <div class="form-group">
                            <label for="email" :class="{ invalid: emailErrors.length !== 0 }" class="form-label">Email:</label>
                            <input
                                type="text"
                                :class="{ invalid: emailErrors.length !== 0 }"
                                class="form-control"
                                id="email"
                                name="email"
                                v-model="v$.email.$model"
                                @input.prevent="resetErrorList()"
                                required
                            />
                            <p v-for="error of emailErrors" :key="error.uid" class="form-error">
                                {{ error.message }}
                            </p>
                        </div>
                        <label for="radio-group" :class="{ invalid: emailErrors.length !== 0 }" class="radio-group-label"
                            >What do you want to recover?</label
                        >
                        <div class="radio-group" id="radio-group">
                            <div class="form-check">
                                <input
                                    :class="{ invalid: !optionChecked && v$.$dirty }"
                                    class="form-check-input"
                                    type="radio"
                                    name="radio"
                                    value="username"
                                    id="username-radio"
                                    v-model="optionChecked"
                                    required
                                />
                                <label for="username-radio" class="form-check-label"> Username </label>
                            </div>
                            <div class="form-check">
                                <input
                                    :class="{ invalid: !optionChecked && v$.$dirty }"
                                    class="form-check-input"
                                    type="radio"
                                    name="radio"
                                    value="password"
                                    id="password-radio"
                                    v-model="optionChecked"
                                    required
                                />
                                <label for="password-radio" class="form-check-label"> Password </label>
                            </div>
                            <div class="form-check">
                                <input
                                    :class="{ invalid: !optionChecked && v$.$dirty }"
                                    class="form-check-input"
                                    type="radio"
                                    name="radio"
                                    value="both"
                                    id="both-radio"
                                    v-model="optionChecked"
                                    required
                                />
                                <label for="both-radio" class="form-check-label"> Both </label>
                            </div>
                            <p v-if="!optionChecked && v$.$dirty" class="form-error">Please select one of the options</p>
                        </div>
                    </form>
                </div>
                <div v-else class="email-sent">
                    <span v-if="credentialsRecoveryResponse"> We sent you a recovery email. Please check your inbox. </span>
                    <span v-else> Something went wrong while sending you the recovery email. Please try again later. </span>
                </div>
                <div class="modal-footer">
                    <div class="buttons-wrapper">
                        <button v-if="!emailSent" type="button" class="confirm-button" @click.prevent="sendRecoveryEmail()">
                            Confirm
                        </button>
                        <button
                            v-if="!emailSent"
                            type="button"
                            class="cancel-button"
                            data-bs-dismiss="modal"
                            @click.prevent="resetModal()"
                        >
                            Cancel
                        </button>
                        <button v-else type="button" class="cancel-button" data-bs-dismiss="modal" @click.prevent="resetModal()">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'CredentialsRecoveryModal',
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            email: '',
            optionChecked: '',
            emailErrors: [],
            emailSent: false,
        };
    },
    computed: {
        credentialsRecoveryResponse() {
            return this.getCredentialsRecoveryResponse();
        },
    },
    validations() {
        return {
            email: {
                required: helpers.withMessage('Email cannot be empty', required),
                email: helpers.withMessage('This email is not valid', email),
                $lazy: true,
            },
        };
    },
    methods: {
        ...mapActions(['storeCredentials', 'emailAlreadyExists', 'usernameAlreadyExists', 'sendCredentialsEmail']),
        ...mapGetters(['getUniqueEmailBool', 'getUniqueUsernameBool', 'getCredentialsRecoveryResponse']),
        sendRecoveryEmail() {
            this.emailErrors = [];
            this.v$.$touch();
            if (this.v$.$errors.length) {
                if (this.v$.email.$errors.length) {
                    for (let err of this.v$.email.$errors) {
                        this.emailErrors.push({ message: err.$message, uid: err.$uid });
                    }
                }
                return;
            }
            if (this.emailErrors.length === 0 && this.optionChecked) {
                if (this.optionChecked === 'username') {
                    this.sendCredentialsEmail({ email: this.email, username: true }).then(() => {
                        this.showEmailSentSection();
                    });
                } else if (this.optionChecked === 'password') {
                    this.sendCredentialsEmail({ email: this.email, password: true }).then(() => {
                        this.showEmailSentSection();
                    });
                } else if (this.optionChecked === 'both') {
                    this.sendCredentialsEmail({ email: this.email, both: true }).then(() => {
                        this.showEmailSentSection();
                    });
                }
            }
        },
        showEmailSentSection() {
            this.emailSent = true;
        },
        resetModal() {
            setTimeout(() => {
                this.email = '';
                this.emailSent = false;
                this.resetErrorList();
                this.optionChecked = '';
                this.v$.$reset();
            }, 200);
        },
        resetErrorList() {
            this.emailErrors = [];
            this.v$.$reset();
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(255, 255, 255, 0.1);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header,
.modal-footer {
    border: none;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    font-family: 'bebas', sans-serif;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    top: 10vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 2rem 0;
    display: flex;
    justify-content: center;
}

.modal-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-control {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.form-check-input {
    background: none;
    border: 2px solid ghostwhite;
}

.form-error {
    margin: 0.25rem 0;
    color: #c72626;
}

.form-label.invalid {
    color: #c72626;
}

.form-control.invalid {
    border-color: #c72626;
}

.form-check-input.invalid {
    border: 2px solid #c72626;
}

.radio-group-label {
    margin: 2rem 0 0 0;
}

.email-sent {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
}

.buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 1rem;
    column-gap: 1rem;
}

.confirm-button {
    font-family: 'bebas', sans-serif;
    width: 10rem;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ghostwhite;
}

.confirm-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.cancel-button {
    font-family: 'bebas', sans-serif;
    width: 10rem;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ghostwhite;
}

.cancel-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
