<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Salut !!!</h4>
                    <button type="button" class="close" @click="closeModal">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="message-wrapper sender">
                        <div class="row label">
                            <div class="col justify-content-between">
                                <span class="message-sender">Costy-Fortza</span>
                                <span class="message-dateCreated">Yesterday, 12:56:09 AM</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p class="message-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="message-wrapper you">
                        <div class="row label">
                            <div class="col justify-content-between">
                                <span class="message-sender">You</span>
                                <span class="message-dateCreated">Yesterday, 12:56:09 AM</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p class="message-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center gap-2">
                        <button class="btn yellow-button" @click="toggleSendMessageVisible">Reply</button>
                        <button class="btn red-button">Delete</button>
                    </div>
                </div>
                <div v-if="sendMessageVisible" class="d-flex flex-column gap-3">
                    <div class="reply-wrapper">
                        <div class="row label">
                            <div class="col">
                                <div class="markup-icon-wrapper">
                                    <img src="../assets/icons/construction_icon.svg" class="markup-icon" />
                                </div>
                                <div class="markup-icon-wrapper">
                                    <img src="../assets/icons/construction_icon.svg" class="markup-icon" />
                                </div>
                                <div class="markup-icon-wrapper">
                                    <img src="../assets/icons/construction_icon.svg" class="markup-icon" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <textarea class="write-message-window" />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button class="btn yellow-button">Send</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OpenedMessageModal',
    data() {
        return {
            sendMessageVisible: false,
        };
    },
    methods: {
        closeModal() {
            document.getElementById('opened-message-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('opened-message-modal').style.transform = 'translateY(-20px)';
            document.getElementById('opened-message-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('opened-message-modal').style.display = 'none';
                document.getElementById('opened-message-modal').classList.remove('show');
                document.getElementById('opened-message-modal').style.removeProperty('transform');
                document.getElementById('opened-message-modal').style.removeProperty('opacity');
            }, 150);

            document.getElementById('opened-message-modal-2').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('opened-message-modal-2').style.transform = 'translateY(-20px)';
            document.getElementById('opened-message-modal-2').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('opened-message-modal-2').style.display = 'none';
                document.getElementById('opened-message-modal-2').classList.remove('show');
                document.getElementById('opened-message-modal-2').style.removeProperty('transform');
                document.getElementById('opened-message-modal-2').style.removeProperty('opacity');
            }, 150);
        },
        copyToClipboard() {
            const text = document.getElementById('invite-link');

            text.select();
            text.setSelectionRange(0, 99999);

            navigator.clipboard.writeText(text.value);
        },
        toggleSendMessageVisible() {
            this.sendMessageVisible = !this.sendMessageVisible;
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
}

.modal-title {
    width: 100%;
    font-family: 'sansation', sans-serif;
    text-align: center;
    font-size: 1.375rem;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 10vh;
    padding: 4rem 2rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

row {
    margin: 0;
    gap: 2px;
    flex-wrap: nowrap;
}

.label .col {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
}

.btn {
    padding: 0.25rem 1rem;
    width: fit-content;
    height: fit-content;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.message-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 1rem;
    width: 80%;
}

.reply-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 1rem;
}

.message-sender,
.message-dateCreated {
    font-size: 0.875rem;
}

.message-body {
    text-indent: 1rem;
    line-height: 1.5rem;
    padding: 0.5rem;
    margin: 0;
    text-align: justify;
}

.you {
    margin-left: 20%;
}

.write-message-window {
    width: 100%;
    background: rgba(0, 0, 0, 0.35);
    padding: 1rem;
    outline: none;
    color: ghostwhite;
    border: 1px solid #434343;
    height: 16rem;
    resize: none;
}

.markup-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border: 1px solid #434343;
    margin-right: 0.125rem;
}

.markup-icon {
    height: 1rem;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 60%;
    }
    .modal-content {
        padding: 3rem 2rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 75%;
        margin: 0 auto;
    }
    .modal-content {
        padding: 3rem 1rem;
    }
}

@media screen and (max-width: 450px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
    .message-body {
        font-size: 0.75rem;
        line-height: 1rem;
    }
    .message-sender,
    .message-dateCreated {
        font-size: 0.75rem;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
