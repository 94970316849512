<template>
    <div id="login">
        <div class="form-layer">
            <div class="soldier-image col-xl-3 col-lg-3"></div>
            <div class="form-wrapper col-xl-3 col-lg-3 col-md-5 col-sm-6 col-10">
                <p class="warning-message">
                    Currently, our website is under development, so accessing it at the current stage is done at the user's own risk, our website is not assuming any risk in this regard.
                </p>
                <LoginForm />
            </div>
        </div>
        <CredentialsRecoveryModal />
    </div>
</template>

<script>
import LoginForm from '../components/LoginForm';
import { mapGetters, mapActions } from 'vuex';
import CredentialsRecoveryModal from '@/components/CredentialsRecoveryModal';

export default {
    name: 'Login',
    setup() {},
    components: {
        LoginForm,
        CredentialsRecoveryModal,
    },
    methods: {
        ...mapGetters(['getUsername', 'getPassword', 'getAreCredentialsValid']),
        ...mapActions(['login', 'checkForToken', 'updateAttemptedLogin']),
    },
    beforeRouteLeave(to, from, next) {
        if (to.fullPath === '/register' || this.getAreCredentialsValid()) {
            next();
        }

        if (!this.getUsername()) {
            return;
        }

        this.login({
            username: this.getUsername(),
            password: this.getPassword(),
        }).then(() => {
            const successfullLogin = this.getAreCredentialsValid();
            if (successfullLogin) {
                next();
            } else {
                this.updateAttemptedLogin(true);
                return;
            }
        });
    },
    created() {
        this.checkForToken().then(() => {
            const successfullLogin = this.getAreCredentialsValid();

            if (successfullLogin) {
                this.$router.push({ path: '/city' });
            }
        });
    },
};
</script>

<style scoped>
#login {
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    display: flex;
    align-items: center;
}

.form-layer {
    position: absolute;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.soldier-image {
    background-image: url('../assets/full_troop_images/infantryman-full.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    max-height: 100%;
    z-index: 1;
}

.form-wrapper {
    background: rgba(0, 0, 0, 0.63);
    padding: 2rem;
    border-top: none;
    border-bottom: none;

    /* BT-187 */
    overflow: overlay;
    height: 80vh;
    z-index: 1;
    /* BT-187 */
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.form-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.form-wrapper::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

/* BT-187 */
.warning-message {
    color: #d34848;
    font-size: 0.875rem;
    line-height: 0.875rem;
    text-indent: 2rem;
}
/* BT-187 */
</style>
