<template>
    <nav v-if="numberOfPages > 1">
        <ul class="pagination">
            <li class="page-item">
                <a class="page-link" href="#" :class="{ disabled: currentPage === 1 }" @click="changeCurrentPage(currentPage - 1)"
                    >&larr;</a
                >
            </li>
            <li v-for="(page, page_index) in pages" :key="page_index" class="page-item">
                <a
                    :class="{
                        selected: page === currentPage,
                        disabled: typeof page !== 'number',
                    }"
                    class="page-link"
                    tabindex="-1"
                    @click="changeCurrentPage(page)"
                    >{{ page }}</a
                >
            </li>
            <li class="page-item">
                <a
                    class="page-link"
                    href="#"
                    :class="{
                        disabled: currentPage === numberOfPages,
                    }"
                    @click="changeCurrentPage(currentPage + 1)"
                    >&rarr;</a
                >
            </li>
        </ul>
    </nav>
    <slot v-if="numberOfPages > 0" :currentRows="currentRows" />
	<span v-else class="no-items-text">{{ noItemsText }}</span>
</template>

<script>
export default {
    name: 'TableWithPagination',
    props: {
        allRows: {
            type: Array,
            default: () => [],
        },
		noItemsText: {
			type: String,
			default: 'No items found'
		},
        onPageChange: {
            type: Function,
            default: () => null,
        },
    },
    data() {
        return {
            rowsPerPage: 10,
            currentPage: 1,
        };
    },
    computed: {
        numberOfPages() {
            if (this.allRows.length % this.rowsPerPage === 0) {
                return this.allRows.length / this.rowsPerPage;
            } else {
                return Math.floor(this.allRows.length / this.rowsPerPage) + 1;
            }
        },
        pages() {
            if (!this.allRows) {
                return [];
            }

            let pagesArray = [];

            for (let currentPageNumber = 1; currentPageNumber <= this.numberOfPages; currentPageNumber++) {
                if (
                    this.numberOfPages <= 7 ||
                    (this.currentPage <= 4 && currentPageNumber <= 5) ||
                    (this.currentPage >= this.numberOfPages - 3 && currentPageNumber >= this.numberOfPages - 4) ||
                    currentPageNumber === 1 ||
                    currentPageNumber === this.numberOfPages ||
                    (this.currentPage - currentPageNumber < 2 && currentPageNumber - this.currentPage < 2)
                ) {
                    pagesArray.push(currentPageNumber);
                }
            }

            for (let i = 1; i < pagesArray.length; i++) {
                if (typeof pagesArray[i - 1] === 'number' && pagesArray[i] - pagesArray[i - 1] > 1) {
                    pagesArray.splice(i, 0, '...');
                }
            }

            return pagesArray;
        },
        currentRows() {
            return this.allRows.slice(this.rowsPerPage * (this.currentPage - 1), this.rowsPerPage * this.currentPage);
        },
    },
	watch: {
		'pages.length': {
			handler() {
				if (this.currentPage > this.numberOfPages) {
					this.changeCurrentPage(this.numberOfPages)
				}
			}
		}
	},
    methods: {
        changeCurrentPage(page_number) {
            if (page_number === this.currentPage) {
                return;
            }
            if (page_number < 1 || page_number > this.pages[this.pages.length - 1]) {
                return;
            }

            this.currentPage = page_number;

            // timeout is needed here to ensure that the new page is
            // fully loaded before attempting to call the provided function
            setTimeout(() => {
                this.onPageChange();
            }, 10);
        },
    },
};
</script>

<style scoped>
.pagination {
    gap: 2px;
    flex-wrap: wrap;
}

.page-link {
    border: none;
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.25);
    color: ghostwhite;
}

.page-item {
    cursor: pointer;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    border-radius: 0;
}

.page-item:not(:first-child) .page-link {
    margin-left: 0;
}

.page-link:focus {
    box-shadow: none;
    color: ghostwhite;
    background: rgba(255, 255, 255, 0.25);
}

.page-link.selected {
    background: rgba(255, 255, 255, 0.5);
    color: ghostwhite;
}

.page-link.disabled {
    opacity: 0.5 !important;
    pointer-events: none;
}

.page-link:hover {
    color: ghostwhite;
    background: rgba(255, 255, 255, 0.6125);
}

.no-items-text {
	color: brown;
}
</style>
