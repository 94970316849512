<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                    <div class="contact-info-group">
                        <span class="info-label"> Email </span>
                        <span class="info-text"> </span>
                    </div>
                    <div class="contact-info-group">
                        <span class="info-label"> Phone </span>
                        <span class="info-text"> </span>
                    </div>
                    <div class="contact-info-group">
                        <span class="info-label"> Address </span>
                        <span class="info-text"> </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactInfoModal',
    methods: {
        closeModal() {
            document.getElementById('contact-info-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('contact-info-modal').style.transform = 'translateY(-20px)';
            document.getElementById('contact-info-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('contact-info-modal').style.display = 'none';
                document.getElementById('contact-info-modal').classList.remove('show');
                document.getElementById('contact-info-modal').style.removeProperty('transform');
                document.getElementById('contact-info-modal').style.removeProperty('opacity');
            }, 150);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 14vh;
    padding: 0;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6rem 2rem 2rem 2rem;
}

.contact-info-group {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
}

.info-label {
    font-size: 0.875rem;
    color: #afafaf;
}

.info-text {
    font-size: 1rem;
    color: ghostwhite;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 60%;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 75%;
        margin: 0 auto;
    }
    .modal-body {
        padding: 4rem 1.25rem 1.25rem 1.25rem;
    }
}

@media screen and (max-width: 450px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
    .info-label {
        font-size: 0.75rem;
    }
    .info-text {
        font-size: 0.875rem;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
