<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Cartofi Prajiti</h4>
                    <button type="button" class="close" aria-label="Close" @click="closeModal">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="coalition-description-table">
                        <div class="row">
                            <div class="col label">Description</div>
                        </div>
                        <div class="row">
                            <div class="col">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                                esse cillum dolore eu fugiat nulla pariatur.
                            </div>
                        </div>
                    </div>
                    <div class="coalition-info-table mt-4">
                        <div class="row">
                            <div class="col label">Name:</div>
                            <div class="col">100 ThievesJr</div>
                        </div>
                        <div class="row">
                            <div class="col label">Abbreviation:</div>
                            <div class="col">100TJr</div>
                        </div>
                        <div class="row">
                            <div class="col label">Members:</div>
                            <div class="col">7</div>
                        </div>
                        <div class="row">
                            <div class="col label">Ranking:</div>
                            <div class="col">45</div>
                        </div>
                        <div class="row">
                            <div class="col label">Enemies Defeated:</div>
                            <div class="col">576.214</div>
                        </div>
                    </div>
                    <nav aria-label="coalition-members-pages" class="mt-5">
                        <ul class="pagination">
                            <li class="page-item">
                                <a
                                    class="page-link"
                                    href="#"
                                    :class="{ disabled: currentPage === 0 }"
                                    @click="changeVisiblePage(currentPage - 1)"
                                    >&larr;</a
                                >
                            </li>
                            <li v-for="page in pages" :key="pages.indexOf(page)" class="page-item">
                                <a
                                    :class="{ selected: visiblePage[pages.indexOf(page)] }"
                                    class="page-link"
                                    href="#"
                                    @click="changeVisiblePage(pages.indexOf(page))"
                                    >{{ pages.indexOf(page) + 1 }}</a
                                >
                            </li>
                            <li class="page-item">
                                <a
                                    class="page-link"
                                    href="#"
                                    :class="{ disabled: currentPage === this.pages.length - 1 }"
                                    @click="changeVisiblePage(currentPage + 1)"
                                    >&rarr;</a
                                >
                            </li>
                        </ul>
                    </nav>
                    <div v-for="page in pages" :key="pages.indexOf(page)" class="members-table-wrapper">
                        <Transition name="page-transition">
                            <div v-if="this.visiblePage[pages.indexOf(page)]" class="members-table">
                                <div class="row">
                                    <div class="col label large">Name</div>
                                    <div class="col label small">Ranking</div>
                                    <div class="col label large">Points</div>
                                    <div class="col label small">Global Ranking</div>
                                    <div class="col label small">Cities</div>
                                </div>
                                <div v-for="member in page" :key="page.indexOf(member)" class="row">
                                    <div class="col large">
                                        <span class="clickable">
                                            {{ member.name }}
                                        </span>
                                    </div>
                                    <div class="col small">
                                        {{ page.indexOf(member) + 1 }}
                                    </div>
                                    <div class="col large">
                                        {{ getRandomInt(10000000, 90000000).toLocaleString('ro') }}
                                    </div>
                                    <div class="col small">
                                        {{ getRandomInt(1000, 10000).toLocaleString('ro') }}
                                    </div>
                                    <div class="col small">
                                        {{ getRandomInt(1, 20).toLocaleString('ro') }}
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CoalitionDetailsModal',
    components: {},
    data() {
        return {
            membersPerPage: 10,
            members: [
                {
                    name: 'AllyMama',
                    coalition: 'Lorem ipsum',
                },
                {
                    name: 'Chooseas',
                    coalition: 'Dolor sit amet',
                },
                {
                    name: 'Digypsaw',
                    coalition: 'Consectetur',
                },
                {
                    name: 'GrabsChone',
                    coalition: 'Adipiscing elit',
                },
                {
                    name: 'Laxrad',
                    coalition: 'Sed do',
                },
                {
                    name: 'Qicogn',
                    coalition: 'Eiusmod',
                },
                {
                    name: 'Aproma',
                    coalition: 'Tempor',
                },
                {
                    name: 'ExtraSlash',
                    coalition: 'Incididunt',
                },
                {
                    name: 'HeavenWanna',
                    coalition: 'Ut labore',
                },
                {
                    name: 'Markizz',
                    coalition: 'Dolore magna',
                },
                {
                    name: 'Returicra',
                    coalition: 'Aliqua',
                },
                {
                    name: 'AwayLeak',
                    coalition: 'Mi in nulla',
                },
                {
                    name: 'Closwa',
                    coalition: 'Posuere',
                },
                {
                    name: 'Folioneti',
                    coalition: 'Sollicitudin',
                },
                {
                    name: 'Icebill',
                    coalition: 'Aliquam',
                },
                {
                    name: 'MdoggSolomon',
                    coalition: 'Ultrices',
                },
                {
                    name: 'RosesDark',
                    coalition: 'Sagittis orci',
                },
                {
                    name: 'Countrymerg',
                    coalition: 'Egestas',
                },
                {
                    name: 'PlatinumSexy',
                    coalition: 'Pretium',
                },
                {
                    name: 'Chattype',
                    coalition: 'Aenean',
                },
                {
                    name: 'Dianista',
                    coalition: 'Pharetra',
                },
                {
                    name: 'Gloryta',
                    coalition: 'Magna ac',
                },
                {
                    name: 'KurisuHi',
                    coalition: 'Dui ut ornare',
                },
                {
                    name: 'Vetcham',
                    coalition: 'Lectus',
                },
                {
                    name: 'Stealmola',
                    coalition: 'Felis',
                },
                {
                    name: 'Istingal',
                    coalition: 'Imperdiet',
                },
            ],
            visiblePage: [],
        };
    },
    computed: {
        pages() {
            let numberOfPages;
            if (this.members.length % this.membersPerPage === 0) {
                numberOfPages = this.members.length / this.membersPerPage;
            } else {
                numberOfPages = Math.floor(this.members.length / this.membersPerPage) + 1;
            }

            let pagesArray = [];
            for (let i = 0; i < numberOfPages; i++) {
                let memberSlice = this.members.slice(this.membersPerPage * i, this.membersPerPage * (i + 1));
                pagesArray.push(memberSlice);
            }

            return pagesArray;
        },
        currentPage() {
            return this.visiblePage.indexOf(this.visiblePage.find(page => page === true));
        },
    },
    created() {
        this.visiblePage = new Array(this.pages.length).fill(false);
        this.visiblePage[0] = true;
    },
    methods: {
        openConfirmationModal() {
            document.getElementById('confirmation-modal').style.transform = 'translateY(-20px)';
            document.getElementById('confirmation-modal').style.opacity = '0';
            document.getElementById('confirmation-modal').style.display = 'block';
            document.getElementById('confirmation-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('confirmation-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('confirmation-modal').style.transform = 'translateY(0)';
                document.getElementById('confirmation-modal').style.opacity = '1';
            }, 0);
        },
        closeModal() {
            document.getElementById('coalition-details-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('coalition-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('coalition-details-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('coalition-details-modal').style.display = 'none';
                document.getElementById('coalition-details-modal').classList.remove('show');
                document.getElementById('coalition-details-modal').style.removeProperty('transform');
                document.getElementById('coalition-details-modal').style.removeProperty('opacity');
            }, 150);
        },
        changeVisiblePage(index) {
            const currentVisiblePage = this.visiblePage.indexOf(this.visiblePage.find(page => page === true));
            if (currentVisiblePage === index || index < 0 || index >= this.pages.length) {
                return;
            }
            this.visiblePage[currentVisiblePage] = false;
            setTimeout(() => {
                this.visiblePage[index] = true;
            }, 200);
        },
        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-dialog {
    max-width: 750px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
    padding: 0;
}

.modal-title {
    width: 100%;
    text-align: center;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 10vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

.pagination {
    gap: 2px;
    flex-wrap: wrap;
}

.page-link {
    border: none;
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.25);
    color: ghostwhite;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    border-radius: 0;
}

.page-item:not(:first-child) .page-link {
    margin-left: 0;
}

.page-link:focus {
    box-shadow: none;
    color: ghostwhite;
    background: rgba(255, 255, 255, 0.25);
}

.page-link.selected {
    background: rgba(255, 255, 255, 0.5);
    color: ghostwhite;
}

.page-link.disabled {
    opacity: 0.5 !important;
    pointer-events: none;
}

.page-link:hover {
    color: ghostwhite;
    background: rgba(255, 255, 255, 0.6125);
}

.page-transition-enter-active,
.page-transition-leave-active {
    transition: filter 0.2s linear;
}

.page-transition-enter-from,
.page-transition-leave-to {
    filter: blur(3px);
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2px;
    margin: 0;
    min-height: 2rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
}

.col.label {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    font-size: 1rem;
}

.large {
    flex-basis: 29%;
}

.small {
    flex-basis: 14%;
}

.clickable:hover {
    color: cornflowerblue;
    cursor: pointer;
}

.coalition-info-table,
.members-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.members-table .col {
    min-width: 0;
}

.members-table .col:first-child {
    word-break: break-all;
}

.coalition-info-table .col {
    flex-basis: 70%;
}

.coalition-info-table .col.label {
    flex-basis: 30%;
    font-size: 0.875rem;
    line-height: 0.875rem;
}

.members-table .col.label {
    font-size: 0.875rem;
    line-height: 0.875rem;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 74%;
    }
    .modal-content {
        padding: 3rem 1rem;
    }
}

@media screen and (max-width: 700px) {
    .col {
        font-size: 0.75rem;
        min-width: 3rem;
        padding: 0.25rem;
        gap: 0.25rem;
    }
    .col.label {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 450px) {
    .modal-content {
        padding: 3rem 0;
    }
    .members-table .col:nth-child(4) {
        display: none;
    }
    .members-table .col.label {
        word-break: break-all;
    }
    .large {
        flex-basis: 30%;
    }
    .small {
        flex-basis: 20%;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
