import { ActionDescriptionIdentifier, ActionTargetIdentifier } from '@/utils/enums/actions';
import { reactive } from 'vue';

export default class MapAction {
    collectionPointer = null;
    originalData = null;
    actionType = null;

    progressTracking = reactive({
        // static, only calculated on construction
        durationInSeconds: 0,
        arrivalTimestamp: 0,
        staticDuration: '',
        returnDate: '',

        // dynamic
        timeUntilArrival: 0,
        dynamicDurationString: '',
        dynamicDuration: {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        },
        currentTimeoutId: null,
    });

    actionDescription = '';
    targetName = '';
    targetId = null;

    constructor({ action, action_type }) {
        this.originalData = action;
        this.actionType = action_type;
        this.initializeProgressTracking();
        this.initializeActionInfo();
    }

    initializeProgressTracking() {
        this.initializeStaticProperties();
        this.setInitialProgress();
    }

    initializeStaticProperties() {
        this.progressTracking.durationInSeconds = this.originalData.duration;
        this.progressTracking.arrivalTimestamp = this.formatTimestamp(this.originalData.arrivalDate);
        this.progressTracking.staticDuration = this.getFormattedDuration(this.progressTracking.durationInSeconds * 1000);
        this.progressTracking.returnDate = this.getEstimatedReturn();
    }

    initializeActionInfo() {
        this.actionDescription = this.getActionDescription();
        this.targetName = this.getTargetName();
        this.targetId = this.getTargetId();
    }

    getActionDescription() {
        return ActionDescriptionIdentifier[this.actionType];
    }

    getTargetName() {
        const PropertyToAccess = ActionTargetIdentifier[this.actionType];

        return typeof this.originalData[PropertyToAccess] === 'object'
            ? this.originalData[PropertyToAccess].name
            : this.originalData[PropertyToAccess];
    }

    getTargetId() {
        const PropertyToAccess = ActionTargetIdentifier[this.actionType];

        return typeof this.originalData[PropertyToAccess] === 'object'
            ? this.originalData[PropertyToAccess]._id
            : this.originalData[PropertyToAccess];
    }

    startCountdown() {
        const Now = new Date().getTime();
        const TimeUntilNearestSecond = Now % 1000;

        this.progressTracking.currentTimeoutId = setTimeout(() => this.updateProgress(), TimeUntilNearestSecond);
    }

    stopCountdown() {
        clearTimeout(this.progressTracking.currentTimeoutId);
    }

    setInitialProgress() {
        const TimeUntilArrival = this.getTimeUntilArrival();

        this.progressTracking.timeUntilArrival = TimeUntilArrival;
        this.progressTracking.dynamicDurationString = this.getFormattedDuration(this.progressTracking.dynamicDuration);
        this.progressTracking.dynamicDuration = this.getDurationObject(TimeUntilArrival);
    }

    updateProgress() {
        if (this.progressTracking.timeUntilArrival <= 1000) {
            return this.selfDestruct();
        }

        this.progressTracking.timeUntilArrival -= 1000;

        this.progressTracking.dynamicDurationString = this.getFormattedDuration(this.progressTracking.dynamicDuration);

        if (this.progressTracking.dynamicDuration.seconds > 0) {
            this.progressTracking.dynamicDuration.seconds--;
        } else {
            this.progressTracking.dynamicDuration.seconds = 59;
            if (this.progressTracking.dynamicDuration.minutes > 0) {
                this.progressTracking.dynamicDuration.minutes--;
            } else {
                this.progressTracking.dynamicDuration.minutes = 59;
                if (this.progressTracking.dynamicDuration.hours > 0) {
                    this.progressTracking.dynamicDuration.hours--;
                } else {
                    this.progressTracking.dynamicDuration.hours = 23;
                    if (this.progressTracking.dynamicDuration.days > 0) {
                        this.progressTracking.dynamicDuration.days--;
                    } else {
                        this.progressTracking.dynamicDuration.seconds = 0;
                        this.progressTracking.dynamicDuration.minutes = 0;
                        this.progressTracking.dynamicDuration.hours = 0;
                        this.progressTracking.dynamicDuration.days = 0;
                    }
                }
            }
        }

        this.progressTracking.currentTimeoutId = setTimeout(() => this.updateProgress(), 1000);
    }

    // might not need this in the future
    formatTimestamp(timestamp) {
        return typeof timestamp === 'string' ? parseInt(timestamp) : timestamp;
    }

    getDurationObject(duration) {
        return {
            days: Math.floor(duration / (1000 * 60 * 60 * 24)),
            hours: Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((duration % (1000 * 60)) / 1000),
        };
    }

    getFormattedDuration(duration_object) {
        return `
            ${duration_object.days ? duration_object.days + 'd ' : ''}
            ${duration_object.hours ? duration_object.hours + 'h ' : ''}
            ${duration_object.minutes ? duration_object.minutes + 'm ' : ''}
            ${duration_object.seconds ? duration_object.seconds + 's' : ''}
        `;
    }

    getEstimatedReturn() {
        const ArrivalTimestamp = this.progressTracking.arrivalTimestamp;
        const Duration = this.progressTracking.durationInSeconds;

        const ArrivalDate = new Date(ArrivalTimestamp + Duration * 1000);

        return `${ArrivalDate.toLocaleDateString('en')} ${ArrivalDate.toLocaleTimeString('ro')}`;
    }

    getTimeUntilArrival() {
        const Now = new Date().getTime();
        return this.progressTracking.arrivalTimestamp - Now;
    }

    setCollectionPointer(collection) {
        this.collectionPointer = collection;
    }

    selfDestruct() {
        this.stopCountdown();
        this.collectionPointer.deleteAction(this);
    }
}
