<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Reports</h5>
                    <div class="building-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs" id="reports-nav" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link active"
                                id="attack-reports"
                                data-bs-toggle="tab"
                                data-bs-target="#attack-reports-page"
                                type="button"
                                role="tab"
                                aria-controls="attack-reports-tab"
                                aria-selected="true"
                            >
                                Attacks
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="reinforcement-reports"
                                data-bs-toggle="tab"
                                data-bs-target="#reinforcement-reports-page"
                                type="button"
                                role="tab"
                                aria-controls="reinforcement-reports-tab"
                                aria-selected="false"
                            >
                                Reinforcements
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="trade-reports"
                                data-bs-toggle="tab"
                                data-bs-target="#trade-reports-page"
                                type="button"
                                role="tab"
                                aria-controls="trade-reports-tab"
                                aria-selected="false"
                            >
                                Trade
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="invitation-reports"
                                data-bs-toggle="tab"
                                data-bs-target="#invitation-reports-page"
                                type="button"
                                role="tab"
                                aria-controls="invitation-reports-tab"
                                aria-selected="false"
                            >
                                Invitations
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="coalition-reports"
                                data-bs-toggle="tab"
                                data-bs-target="#coalition-reports-page"
                                type="button"
                                role="tab"
                                aria-controls="coalition-reports-tab"
                                aria-selected="false"
                            >
                                Coalition
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="shared-reports"
                                data-bs-toggle="tab"
                                data-bs-target="#shared-reports-page"
                                type="button"
                                role="tab"
                                aria-controls="shared-reports-tab"
                                aria-selected="false"
                            >
                                Shared
                            </button>
                        </li>
                        <li class="nav-filler" />
                    </ul>
                    <div class="tab-content" id="reports-pages">
                        <div
                            class="tab-pane fade show active"
                            id="attack-reports-page"
                            role="tabpanel"
                            aria-labelledby="attack-reports-tab"
                        >
                            <nav aria-label="attack-reports-pages">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === 0 }"
                                            @click="changeVisiblePage(currentPage - 1)"
                                            >&larr;</a
                                        >
                                    </li>
                                    <li v-for="page in pages" :key="pages.indexOf(page)" class="page-item">
                                        <a
                                            :class="{ selected: visiblePage[pages.indexOf(page)] }"
                                            class="page-link"
                                            href="#"
                                            @click="changeVisiblePage(pages.indexOf(page))"
                                            >{{ pages.indexOf(page) + 1 }}</a
                                        >
                                    </li>
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === this.pages.length - 1 }"
                                            @click="changeVisiblePage(currentPage + 1)"
                                            >&rarr;</a
                                        >
                                    </li>
                                </ul>
                            </nav>
                            <div v-for="page in pages" :key="pages.indexOf(page)" class="reports-table-wrapper">
                                <Transition name="page-transition">
                                    <div v-if="this.visiblePage[pages.indexOf(page)]" class="reports-table">
                                        <div class="row label">
                                            <div class="col large">Title</div>
                                            <div class="col small">Date</div>
                                        </div>
                                        <div v-for="report in page" :key="page.indexOf(report)" class="row">
                                            <div class="col large">
                                                <div class="form-check">
                                                    <input
                                                        v-model="attackCheckboxes[pages.indexOf(page)][page.indexOf(report)]"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        :value="true"
                                                    />
                                                    <label class="form-check-label clickable" @click="openModal">
                                                        <!--                            <img-->
                                                        <!--                                src="../assets/icons/inform_circle_icon.svg"-->
                                                        <!--                                class="report-icon"-->
                                                        <!--                            />-->
                                                        {{ report.title }}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col small">
                                                {{ getRandomDate() }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col justify-content-between">
                                                <div class="form-check">
                                                    <input
                                                        :checked="allCheckedAttacks(pages.indexOf(page))"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        @click="selectAllAttacks(pages.indexOf(page))"
                                                    />
                                                    <label class="form-check-label"> Select All </label>
                                                </div>
                                                <div class="d-flex gap-1">
                                                    <button class="btn red-button">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="reinforcement-reports-page"
                            role="tabpanel"
                            aria-labelledby="reinforcement-reports-tab"
                        >
                            <nav aria-label="reinforcement-reports-pages">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === 0 }"
                                            @click="changeVisiblePage(currentPage - 1)"
                                            >&larr;</a
                                        >
                                    </li>
                                    <li v-for="page in pages" :key="pages.indexOf(page)" class="page-item">
                                        <a
                                            :class="{ selected: visiblePage[pages.indexOf(page)] }"
                                            class="page-link"
                                            href="#"
                                            @click="changeVisiblePage(pages.indexOf(page))"
                                            >{{ pages.indexOf(page) + 1 }}</a
                                        >
                                    </li>
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === this.pages.length - 1 }"
                                            @click="changeVisiblePage(currentPage + 1)"
                                            >&rarr;</a
                                        >
                                    </li>
                                </ul>
                            </nav>
                            <div v-for="page in pages" :key="pages.indexOf(page)" class="reports-table-wrapper">
                                <Transition name="page-transition">
                                    <div v-if="this.visiblePage[pages.indexOf(page)]" class="reports-table">
                                        <div class="row label">
                                            <div class="col large">Title</div>
                                            <div class="col small">Date</div>
                                        </div>
                                        <div v-for="report in page" :key="page.indexOf(report)" class="row">
                                            <div class="col large">
                                                <div class="form-check">
                                                    <input
                                                        v-model="
                                                            reinforcementCheckboxes[pages.indexOf(page)][page.indexOf(report)]
                                                        "
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        :value="true"
                                                    />
                                                    <label class="form-check-label clickable" @click="openModal">
                                                        {{ report.title }}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col small">
                                                {{ getRandomDate() }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col justify-content-between">
                                                <div class="form-check">
                                                    <input
                                                        :checked="allCheckedReinforcements(pages.indexOf(page))"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        @click="selectAllReinforcements(pages.indexOf(page))"
                                                    />
                                                    <label class="form-check-label"> Select All </label>
                                                </div>
                                                <div class="d-flex gap-1">
                                                    <button class="btn red-button">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="trade-reports-page" role="tabpanel" aria-labelledby="trade-reports-tab">
                            <nav aria-label="trade-reports-pages">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === 0 }"
                                            @click="changeVisiblePage(currentPage - 1)"
                                            >&larr;</a
                                        >
                                    </li>
                                    <li v-for="page in pages" :key="pages.indexOf(page)" class="page-item">
                                        <a
                                            :class="{ selected: visiblePage[pages.indexOf(page)] }"
                                            class="page-link"
                                            href="#"
                                            @click="changeVisiblePage(pages.indexOf(page))"
                                            >{{ pages.indexOf(page) + 1 }}</a
                                        >
                                    </li>
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === this.pages.length - 1 }"
                                            @click="changeVisiblePage(currentPage + 1)"
                                            >&rarr;</a
                                        >
                                    </li>
                                </ul>
                            </nav>
                            <div v-for="page in pages" :key="pages.indexOf(page)" class="reports-table-wrapper">
                                <Transition name="page-transition">
                                    <div v-if="this.visiblePage[pages.indexOf(page)]" class="reports-table">
                                        <div class="row label">
                                            <div class="col large">Title</div>
                                            <div class="col small">Date</div>
                                        </div>
                                        <div v-for="report in page" :key="page.indexOf(report)" class="row">
                                            <div class="col large">
                                                <div class="form-check">
                                                    <input
                                                        v-model="tradeCheckboxes[pages.indexOf(page)][page.indexOf(report)]"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        :value="true"
                                                    />
                                                    <label class="form-check-label clickable" @click="openModal">
                                                        {{ report.title }}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col small">
                                                {{ getRandomDate() }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col justify-content-between">
                                                <div class="form-check">
                                                    <input
                                                        :checked="allCheckedTrade(pages.indexOf(page))"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        @click="selectAllTrade(pages.indexOf(page))"
                                                    />
                                                    <label class="form-check-label"> Select All </label>
                                                </div>
                                                <div class="d-flex gap-1">
                                                    <button class="btn red-button">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="invitation-reports-page"
                            role="tabpanel"
                            aria-labelledby="invitation-reports-tab"
                        >
                            <nav aria-label="invitation-reports-pages">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === 0 }"
                                            @click="changeVisiblePage(currentPage - 1)"
                                            >&larr;</a
                                        >
                                    </li>
                                    <li v-for="page in pages" :key="pages.indexOf(page)" class="page-item">
                                        <a
                                            :class="{ selected: visiblePage[pages.indexOf(page)] }"
                                            class="page-link"
                                            href="#"
                                            @click="changeVisiblePage(pages.indexOf(page))"
                                            >{{ pages.indexOf(page) + 1 }}</a
                                        >
                                    </li>
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === this.pages.length - 1 }"
                                            @click="changeVisiblePage(currentPage + 1)"
                                            >&rarr;</a
                                        >
                                    </li>
                                </ul>
                            </nav>
                            <div v-for="page in pages" :key="pages.indexOf(page)" class="reports-table-wrapper">
                                <Transition name="page-transition">
                                    <div v-if="this.visiblePage[pages.indexOf(page)]" class="reports-table">
                                        <div class="row label">
                                            <div class="col large">Title</div>
                                            <div class="col small">Date</div>
                                        </div>
                                        <div v-for="report in page" :key="page.indexOf(report)" class="row">
                                            <div class="col large">
                                                <div class="form-check">
                                                    <input
                                                        v-model="invitationCheckboxes[pages.indexOf(page)][page.indexOf(report)]"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        :value="true"
                                                    />
                                                    <label class="form-check-label clickable" @click="openModal">
                                                        {{ report.title }}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col small">
                                                {{ getRandomDate() }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col justify-content-between">
                                                <div class="form-check">
                                                    <input
                                                        :checked="allCheckedInvitations(pages.indexOf(page))"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        @click="selectAllInvitations(pages.indexOf(page))"
                                                    />
                                                    <label class="form-check-label"> Select All </label>
                                                </div>
                                                <div class="d-flex gap-1">
                                                    <button class="btn red-button">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="coalition-reports-page"
                            role="tabpanel"
                            aria-labelledby="coalition-reports-tab"
                        >
                            <nav aria-label="coalition-reports-pages">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === 0 }"
                                            @click="changeVisiblePage(currentPage - 1)"
                                            >&larr;</a
                                        >
                                    </li>
                                    <li v-for="page in pages" :key="pages.indexOf(page)" class="page-item">
                                        <a
                                            :class="{ selected: visiblePage[pages.indexOf(page)] }"
                                            class="page-link"
                                            href="#"
                                            @click="changeVisiblePage(pages.indexOf(page))"
                                            >{{ pages.indexOf(page) + 1 }}</a
                                        >
                                    </li>
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === this.pages.length - 1 }"
                                            @click="changeVisiblePage(currentPage + 1)"
                                            >&rarr;</a
                                        >
                                    </li>
                                </ul>
                            </nav>
                            <div v-for="page in pages" :key="pages.indexOf(page)" class="reports-table-wrapper">
                                <Transition name="page-transition">
                                    <div v-if="this.visiblePage[pages.indexOf(page)]" class="reports-table">
                                        <div class="row label">
                                            <div class="col large">Title</div>
                                            <div class="col small">Date</div>
                                        </div>
                                        <div v-for="report in page" :key="page.indexOf(report)" class="row">
                                            <div class="col large">
                                                <div class="form-check">
                                                    <input
                                                        v-model="coalitionCheckboxes[pages.indexOf(page)][page.indexOf(report)]"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        :value="true"
                                                    />
                                                    <label class="form-check-label clickable" @click="openModal">
                                                        {{ report.title }}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col small">
                                                {{ getRandomDate() }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col justify-content-between">
                                                <div class="form-check">
                                                    <input
                                                        :checked="allCheckedCoalition(pages.indexOf(page))"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        @click="selectAllCoalition(pages.indexOf(page))"
                                                    />
                                                    <label class="form-check-label"> Select All </label>
                                                </div>
                                                <div class="d-flex gap-1">
                                                    <button class="btn red-button">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="shared-reports-page" role="tabpanel" aria-labelledby="shared-reports-tab">
                            <nav aria-label="shared-reports-pages">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === 0 }"
                                            @click="changeVisiblePage(currentPage - 1)"
                                            >&larr;</a
                                        >
                                    </li>
                                    <li v-for="page in pages" :key="pages.indexOf(page)" class="page-item">
                                        <a
                                            :class="{ selected: visiblePage[pages.indexOf(page)] }"
                                            class="page-link"
                                            href="#"
                                            @click="changeVisiblePage(pages.indexOf(page))"
                                            >{{ pages.indexOf(page) + 1 }}</a
                                        >
                                    </li>
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === this.pages.length - 1 }"
                                            @click="changeVisiblePage(currentPage + 1)"
                                            >&rarr;</a
                                        >
                                    </li>
                                </ul>
                            </nav>
                            <div v-for="page in pages" :key="pages.indexOf(page)" class="reports-table-wrapper">
                                <Transition name="page-transition">
                                    <div v-if="this.visiblePage[pages.indexOf(page)]" class="reports-table">
                                        <div class="row label">
                                            <div class="col large">Title</div>
                                            <div class="col small">Date</div>
                                        </div>
                                        <div v-for="report in page" :key="page.indexOf(report)" class="row">
                                            <div class="col large">
                                                <div class="form-check">
                                                    <input
                                                        v-model="sharedCheckboxes[pages.indexOf(page)][page.indexOf(report)]"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        :value="true"
                                                    />
                                                    <label class="form-check-label clickable" @click="openModal">
                                                        {{ report.title }}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col small">
                                                {{ getRandomDate() }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col justify-content-between">
                                                <div class="form-check">
                                                    <input
                                                        :checked="allCheckedShared(pages.indexOf(page))"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        @click="selectAllShared(pages.indexOf(page))"
                                                    />
                                                    <label class="form-check-label"> Select All </label>
                                                </div>
                                                <div class="d-flex gap-1">
                                                    <button class="btn red-button">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <opened-report-modal id="opened-report-modal" />
    </div>
</template>

<script>
import OpenedReportModal from '@/components/OpenedReportModal';
export default {
    name: 'ReportsModal',
    components: { OpenedReportModal },
    data() {
        return {
            reportsPerPage: 10,
            attacks: [
                {
                    title: 'Lorem ipsum dolor sit amet',
                    coalition: 'Lorem ipsum',
                },
                {
                    title: 'Consectetur adipiscing elit, sed do eiusmod tempor',
                    coalition: 'Dolor sit amet',
                },
                {
                    title: 'Incididunt ut labore et',
                    coalition: 'Consectetur',
                },
                {
                    title: 'Dolore magna aliqua',
                    coalition: 'Adipiscing elit',
                },
                {
                    title: 'Maecenas sed enim ut sem viverra',
                    coalition: 'Sed do',
                },
                {
                    title: 'Donec et odio pellentesque diam volutpat',
                    coalition: 'Eiusmod',
                },
                {
                    title: 'Tristique senectus et netus et malesuada fames ac turpis egestas',
                    coalition: 'Tempor',
                },
                {
                    title: 'Nunc scelerisque viverra',
                    coalition: 'Incididunt',
                },
                {
                    title: 'Mauris in aliquam',
                    coalition: 'Ut labore',
                },
                {
                    title: 'Sem fringilla ut',
                    coalition: 'Dolore magna',
                },
                {
                    title: 'Pretium viverra suspendisse',
                    coalition: 'Aliqua',
                },
                {
                    title: 'Potenti nullam ac tortor vitae purus',
                    coalition: 'Mi in nulla',
                },
                {
                    title: 'Pulvinar pellentesque',
                    coalition: 'Posuere',
                },
                {
                    title: 'Habitant morbi tristique',
                    coalition: 'Sollicitudin',
                },
                {
                    title: 'Pellentesque pulvinar',
                    coalition: 'Aliquam',
                },
                {
                    title: 'Pellentesque habitant morbi tristique senectus et',
                    coalition: 'Ultrices',
                },
                {
                    title: 'Urna duis convallis',
                    coalition: 'Sagittis orci',
                },
                {
                    title: 'Convallis tellus id',
                    coalition: 'Egestas',
                },
                {
                    title: 'Interdum velit laoreet',
                    coalition: 'Pretium',
                },
                {
                    title: 'Sit amet dictum',
                    coalition: 'Aenean',
                },
                {
                    title: 'Justo donec enim',
                    coalition: 'Pharetra',
                },
                {
                    title: 'Massa tincidunt dui ut ornare lectus sit',
                    coalition: 'Magna ac',
                },
                {
                    title: 'Mattis ullamcorper velit',
                    coalition: 'Dui ut ornare',
                },
                {
                    title: 'Sed ullamcorper morbi',
                    coalition: 'Lectus',
                },
                {
                    title: 'Vel facilisis volutpat est velit',
                    coalition: 'Felis',
                },
                {
                    title: 'Egestas dui id',
                    coalition: 'Imperdiet',
                },
            ],
            visiblePage: [],
            attackCheckboxes: [],
            reinforcementCheckboxes: [],
            tradeCheckboxes: [],
            invitationCheckboxes: [],
            coalitionCheckboxes: [],
            sharedCheckboxes: [],
        };
    },
    computed: {
        pages() {
            let numberOfPages;
            if (this.attacks.length % this.reportsPerPage === 0) {
                numberOfPages = this.attacks.length / this.reportsPerPage;
            } else {
                numberOfPages = Math.floor(this.attacks.length / this.reportsPerPage) + 1;
            }

            let pagesArray = [];
            for (let i = 0; i < numberOfPages; i++) {
                let playerSlice = this.attacks.slice(this.reportsPerPage * i, this.reportsPerPage * (i + 1));
                pagesArray.push(playerSlice);
            }

            return pagesArray;
        },
        currentPage() {
            return this.visiblePage.indexOf(this.visiblePage.find(page => page === true));
        },
    },
    created() {
        this.visiblePage = new Array(this.pages.length).fill(false);
        this.visiblePage[0] = true;

        for (let i = 0; i < this.pages.length; i++) {
            let checkboxArray = new Array(this.pages[i].length).fill(false);
            this.attackCheckboxes.push(JSON.parse(JSON.stringify(checkboxArray)));
            this.reinforcementCheckboxes.push(JSON.parse(JSON.stringify(checkboxArray)));
            this.tradeCheckboxes.push(JSON.parse(JSON.stringify(checkboxArray)));
            this.invitationCheckboxes.push(JSON.parse(JSON.stringify(checkboxArray)));
            this.coalitionCheckboxes.push(JSON.parse(JSON.stringify(checkboxArray)));
            this.sharedCheckboxes.push(JSON.parse(JSON.stringify(checkboxArray)));
        }
    },
    methods: {
        openModal() {
            document.getElementById('opened-report-modal').style.transform = 'translateY(-20px)';
            document.getElementById('opened-report-modal').style.opacity = '0';
            document.getElementById('opened-report-modal').style.display = 'block';
            document.getElementById('opened-report-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('opened-report-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('opened-report-modal').style.transform = 'translateY(0)';
                document.getElementById('opened-report-modal').style.opacity = '1';
            }, 0);
        },
        changeVisiblePage(index) {
            const currentVisiblePage = this.visiblePage.indexOf(this.visiblePage.find(page => page === true));
            if (currentVisiblePage === index || index < 0 || index >= this.pages.length) {
                return;
            }
            this.visiblePage[currentVisiblePage] = false;
            setTimeout(() => {
                this.visiblePage[index] = true;
            }, 200);
        },
        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min);
        },
        getRandomDate() {
            const maxDate = Date.now();
            const timestamp = Math.floor(Math.random() * maxDate);

            const dateCreated = new Date(timestamp);

            let day = dateCreated.getDate();
            let month = dateCreated.getMonth();
            let year = dateCreated.getFullYear();

            let hour = dateCreated.getHours();
            let minute = dateCreated.getMinutes();
            let second = dateCreated.getSeconds();

            return day + '/' + month + '/' + year + ' ' + hour + ':' + minute + ':' + second;
        },
        selectAllAttacks(index) {
            if (this.attackCheckboxes[index].includes(false)) {
                for (let i = 0; i < this.attackCheckboxes[index].length; i++) {
                    this.attackCheckboxes[index][i] = true;
                }
            } else {
                for (let i = 0; i < this.attackCheckboxes[index].length; i++) {
                    this.attackCheckboxes[index][i] = false;
                }
            }
        },
        selectAllReinforcements(index) {
            if (this.reinforcementCheckboxes[index].includes(false)) {
                for (let i = 0; i < this.reinforcementCheckboxes[index].length; i++) {
                    this.reinforcementCheckboxes[index][i] = true;
                }
            } else {
                for (let i = 0; i < this.reinforcementCheckboxes[index].length; i++) {
                    this.reinforcementCheckboxes[index][i] = false;
                }
            }
        },
        selectAllTrade(index) {
            if (this.tradeCheckboxes[index].includes(false)) {
                for (let i = 0; i < this.tradeCheckboxes[index].length; i++) {
                    this.tradeCheckboxes[index][i] = true;
                }
            } else {
                for (let i = 0; i < this.tradeCheckboxes[index].length; i++) {
                    this.tradeCheckboxes[index][i] = false;
                }
            }
        },
        selectAllInvitations(index) {
            if (this.invitationCheckboxes[index].includes(false)) {
                for (let i = 0; i < this.invitationCheckboxes[index].length; i++) {
                    this.invitationCheckboxes[index][i] = true;
                }
            } else {
                for (let i = 0; i < this.invitationCheckboxes[index].length; i++) {
                    this.invitationCheckboxes[index][i] = false;
                }
            }
        },
        selectAllCoalition(index) {
            if (this.coalitionCheckboxes[index].includes(false)) {
                for (let i = 0; i < this.coalitionCheckboxes[index].length; i++) {
                    this.coalitionCheckboxes[index][i] = true;
                }
            } else {
                for (let i = 0; i < this.coalitionCheckboxes[index].length; i++) {
                    this.coalitionCheckboxes[index][i] = false;
                }
            }
        },
        selectAllShared(index) {
            if (this.sharedCheckboxes[index].includes(false)) {
                for (let i = 0; i < this.sharedCheckboxes[index].length; i++) {
                    this.sharedCheckboxes[index][i] = true;
                }
            } else {
                for (let i = 0; i < this.sharedCheckboxes[index].length; i++) {
                    this.sharedCheckboxes[index][i] = false;
                }
            }
        },
        allCheckedAttacks(index) {
            return !this.attackCheckboxes[index].includes(false);
        },
        allCheckedReinforcements(index) {
            return !this.reinforcementCheckboxes[index].includes(false);
        },
        allCheckedTrade(index) {
            return !this.tradeCheckboxes[index].includes(false);
        },
        allCheckedInvitations(index) {
            return !this.invitationCheckboxes[index].includes(false);
        },
        allCheckedCoalition(index) {
            return !this.coalitionCheckboxes[index].includes(false);
        },
        allCheckedShared(index) {
            return !this.coalitionCheckboxes[index].includes(false);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
    font-size: 1.5rem;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 3rem 1rem 1rem 1rem;
}

.nav-tabs {
    border: none;
}

.nav-tabs .nav-link {
    border-radius: 0;
    border: 2px solid #434343;
    border-bottom: none;
    color: #afafaf;
    transform: translateY(15px);
}

.nav-tabs .nav-item {
    margin-right: 0.25rem;
}

.nav-tabs .nav-item:nth-last-child(2) {
    margin-right: 0;
}

.nav-tabs .nav-link:hover {
    color: lightgray;
    border: 2px solid #434343;
    border-bottom: none;
}

.nav-tabs .nav-link.active {
    background: none;
    border: 2px solid #434343;
    border-bottom: none;
    color: ghostwhite;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transform: translateY(-1px);
}

.nav-filler {
    flex-grow: 1;
    border-bottom: 2px solid #434343;
}

.tab-pane {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    background: none;
    border: 2px solid #434343;
    border-top: none;
}

.pagination {
    gap: 2px;
    flex-wrap: wrap;
}

.page-link {
    border: none;
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.25);
    color: ghostwhite;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    border-radius: 0;
}

.page-item:not(:first-child) .page-link {
    margin-left: 0;
}

.page-link:focus {
    box-shadow: none;
    color: ghostwhite;
    background: rgba(255, 255, 255, 0.25);
}

.page-link.selected {
    background: rgba(255, 255, 255, 0.5);
    color: ghostwhite;
}

.page-link.disabled {
    opacity: 0.5 !important;
    pointer-events: none;
}

.page-link:hover {
    color: ghostwhite;
    background: rgba(255, 255, 255, 0.6125);
}

.page-transition-enter-active,
.page-transition-leave-active {
    transition: filter 0.2s linear;
}

.page-transition-enter-from,
.page-transition-leave-to {
    filter: blur(3px);
}

.row {
    margin: 0;
    gap: 2px;
    flex-wrap: nowrap;
}

.label .col {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
    word-break: break-all;
}

.large {
    flex-basis: 75%;
}

.small {
    flex-basis: 25%;
}

.btn {
    padding: 0.25rem 1rem;
    width: fit-content;
    height: fit-content;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.form-control {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
    height: 1.5rem;
    transition: opacity 0.3s;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:disabled {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
    opacity: 0.25;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.form-check-input {
    cursor: pointer;
}

.form-check-input:checked {
    background-color: #d34848;
    border: none;
    background-size: 10px;
}

.form-check-input:focus {
    box-shadow: none;
}

.reports-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.clickable:hover {
    color: cornflowerblue;
    cursor: pointer;
}

/*.report-icon {*/
/*  height: 1.25rem;*/
/*  width: 1.25rem;*/
/*  margin-right: 0.375rem;*/
/*  margin-left: 0.5rem;*/
/*}*/

@media screen and (max-width: 1199px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 850px) {
    .col,
    .label .col {
        font-size: 0.75rem;
        min-width: 2rem;
        padding: 0.25rem;
    }
    .col {
        gap: 0.25rem;
    }
    .nav-link {
        font-size: 0.875rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .btn {
        font-size: 0.75rem;
        padding: 0.25rem;
    }
    .modal-content {
        padding: 4rem 0 1rem 0;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 96%;
    }
    .building-description {
        width: 100%;
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

@media screen and (max-width: 500px) {
    .nav-tabs .nav-item {
        margin-right: 0.125rem;
    }
    .nav-link {
        font-size: 0.75rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
    .tab-pane {
        padding: 2rem 1rem 1rem 1rem;
    }
}

@media screen and (max-width: 450px) {
    .col {
        font-size: 0.625rem;
    }
    .search-table {
        width: 100%;
    }
    #players-ranking-page .reports-table .col:last-child {
        display: none;
    }
    .large {
        flex-basis: 30%;
    }
    .small {
        flex-basis: 10%;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
