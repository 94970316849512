import { createRouter, createWebHistory } from "vue-router";
import RegisterPage from "../views/RegisterPage.vue";
import LoginPage from "../views/LoginPage.vue";
import CityPage from "../views/CityPage.vue";
import ConfirmPage from "../views/ConfirmPage.vue";
import CredentialsPage from "../views/CredentialsPage.vue";
// import WorldMap from "@/views/WorldMap";

const routes = [
    {
        path: '/',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/register',
        name: 'RegisterPage',
        component: RegisterPage,
    },
    {
        path: '/confirm',
        name: 'ConfirmPage',
        component: ConfirmPage,
    },
    {
        path: '/credentials',
        name: 'CredentialsPage',
        component: CredentialsPage,
    },
    {
        path: '/city',
        name: 'CityPage',
        component: CityPage,
    },
    // {
    //   path: "/map",
    //   name: "WorldMap",
    //   component: WorldMap,
    // },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/',
    },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
