<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ building }}</h5>
                    <div class="building-description">
                        {{ description }}
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div :class="{ units: troopType === 'units' }" class="modal-body">
                    <ul class="nav nav-tabs" id="recruitment-nav" role="tablist">
                        <li
                            :class="troop.name"
                            v-for="(troop, troop_index) in troopsList"
                            :key="troop_index"
                            class="nav-item"
                            role="presentation"
                            @mouseover="toggleTroopNameVisibility(true, troop_index)"
                            @mouseleave="toggleTroopNameVisibility(false, troop_index)"
                        >
                            <button
                                :class="{ active: troop_index === 0 }"
                                class="nav-link"
                                :id="`tab_id_${troop_index}`"
                                data-bs-toggle="tab"
                                :data-bs-target="`#content_id_${troop_index}`"
                                type="button"
                                role="tab"
                                :aria-controls="`aria_id_${troop_index}`"
                                aria-selected="true"
                                @click="setCurrentTroop(troop)"
                            >
                                <Transition name="tooltip-transition">
                                    <div v-if="troopNameTooltipVisible[troop_index]" class="troop-name-tooltip">
                                        {{ troop.name }}
                                    </div>
                                </Transition>
                                <div class="tab-wrapper">
                                    <div class="current-troops">{{ troop.numOfTroops.toLocaleString('ro') }}</div>
                                    <div class="tab-img-wrapper">
                                        <img
                                            :src="require(`../assets/icons/troops/${toKebabCase(troop.name)}-tall.avif`)"
                                            alt=""
                                            :class="{ unavailable: troopsWithRequirementsNotMet.includes(troop.name) }"
                                        />
                                    </div>
                                    <div class="max-troops">
                                        + {{ troopsWithRequirementsNotMet.includes(troop.name) ? 0 : calculateMaxTroops(troop) }}
                                    </div>
                                </div>
                            </button>
                        </li>
                        <li class="nav-filler" />
                    </ul>
                    <div class="tab-content" id="recruitment-pages">
                        <div
                            v-for="(troop, troop_index) in troopsList"
                            :key="troop_index"
                            :class="{
                                show: troop_index === 0,
                                active: troop_index === 0,
                            }"
                            class="tab-pane fade"
                            :id="`content_id_${troop_index}`"
                            role="tabpanel"
                            :aria-labelledby="`aria_id_${troop_index}`"
                        >
                            <div v-if="troopsWithRequirementsNotMet.includes(troop.name)" class="building-requirements-section">
                                <span class="requirements-title">Needed:</span>
                                <div
                                    v-for="(req, req_index) in troop.requirements"
                                    :key="req_index"
                                    class="requirements-group"
                                    :class="{
                                        'd-none': requirementsMetPerTroop[troop_index].requirementsMet?.includes(req.name),
                                    }"
                                >
                                    <img
                                        :src="
                                            require(`../assets/icons/buildings/${toSnakeCase(
                                                req.name,
                                            )}_mini_${getBuildingAppearance(req)}.avif`)
                                        "
                                        alt=""
                                        class="requirement-icon"
                                        @click="openBuildingDetailsModal(getBuildingFromRequirementName(req.name))"
                                    />
                                    <div class="requirement-name-wrapper">
                                        <span
                                            class="requirement-name"
                                            @click="openBuildingDetailsModal(getBuildingFromRequirementName(req.name))"
                                            >{{ req.name }}</span
                                        >
                                        <span class="requirement-level">Level {{ req.level }}</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="d-flex flex-row justify-content-between flex-wrap"
                                :class="{ 'opacity-50': troopsWithRequirementsNotMet.includes(troop.name) }"
                            >
                                <div class="recruitment-window">
                                    <span class="section-title clickable" @click="openTroopDetailsModal()">{{ troop.name }}</span>
                                    <div class="recruitment-image-wrapper">
                                        <img :src="require(`../assets/icons/troops/${toKebabCase(troop.name)}-tall.avif`)" alt="" />
                                    </div>
                                    <div
                                        class="recruitment-command-wrapper"
                                        :class="{ 'pointer-events-none': troopsWithRequirementsNotMet.includes(troop.name) }"
                                    >
                                        <div class="recruitment-slider d-flex gap-3 align-items-center">
                                            <span class="min-recruit" @click="recruitMin(troop_index)">{{
                                                calculateMaxTroops(troop) ? 1 : 0
                                            }}</span>
                                            <vue-slider
                                                v-model="numberOfTroopsToRecruit[troop_index]"
                                                v-bind="{ ...options, min: calculateMaxTroops(troop) ? 1 : 0 }"
                                                :max="calculateMaxTroops(troop)"
                                            />
                                            <span class="max-recruit" @click="recruitMax(troop_index)">{{
                                                calculateMaxTroops(troop)
                                            }}</span>
                                        </div>
                                        <input
                                            class="form-control"
                                            v-model="numberOfTroopsToRecruit[troop_index]"
                                            type="number"
                                            :min="calculateMaxTroops(troop) ? 1 : 0"
                                            :max="calculateMaxTroops(troop)"
                                        />
                                        <button
                                            class="btn recruit-button"
                                            :disabled="
                                                troopsWithRequirementsNotMet.includes(troop.name) ||
                                                !calculateMaxTroops(troop) ||
                                                numberOfTroopsToRecruit[troop_index] > calculateMaxTroops(troop) ||
                                                numberOfTroopsToRecruit[troop_index] <= 0 ||
                                                awaitingResponse
                                            "
                                            @click="sendCommand(troop)"
                                        >
                                            Recruit
                                        </button>
                                    </div>
                                </div>
                                <div class="cost-window">
                                    <span class="section-title">Cost</span>
                                    <div class="resource-wrapper">
                                        <div class="icon-tooltip">Aluminium</div>
                                        <img src="../assets/icons/aluminum_circle_icon.svg" class="resource-icon" />
                                        <span
                                            >{{ troop.aluminum * numberOfTroopsToRecruit[troop_index] }} (
                                            {{ troop.aluminum }} )</span
                                        >
                                    </div>
                                    <div class="resource-wrapper">
                                        <div class="icon-tooltip">Oil</div>
                                        <img src="../assets/icons/oil_circle_icon.svg" class="resource-icon" />
                                        <span>{{ troop.oil * numberOfTroopsToRecruit[troop_index] }} ( {{ troop.oil }} )</span>
                                    </div>
                                    <div class="resource-wrapper">
                                        <div class="icon-tooltip">Metal</div>
                                        <img src="../assets/icons/metal_circle_icon.svg" class="resource-icon" />
                                        <span
                                            >{{ troop.metal * numberOfTroopsToRecruit[troop_index] }} ( {{ troop.metal }} )</span
                                        >
                                    </div>
                                    <div class="resource-wrapper">
                                        <div class="icon-tooltip">Population</div>
                                        <img src="../assets/icons/population_circle_icon.svg" class="resource-icon" />
                                        <span
                                            >{{ troop.population * numberOfTroopsToRecruit[troop_index] }} (
                                            {{ troop.population }} )</span
                                        >
                                    </div>
                                    <div class="resource-wrapper">
                                        <div class="icon-tooltip">Recruitment Time</div>
                                        <img src="../assets/icons/training_time_icon.svg" class="resource-icon" />
                                        <span
                                            >{{ calculateTotalTime(numberOfTroopsToRecruit[troop_index], troop.time) }}
                                            <span class="d-inline-block">( {{ troop.time }} )</span></span
                                        >
                                    </div>
                                </div>
                                <div class="troop-info-window">
                                    <span class="section-title">Statistics</span>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Anti-air Defense</div>
                                        <img src="../assets/icons/anti_air_defense_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop.antiAirDefense }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Anti-artillery Defense</div>
                                        <img src="../assets/icons/anti_artillery_tank_defense_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop.antiArtilleryAndTankDefense }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Anti-infantry Defense</div>
                                        <img src="../assets/icons/anti_infantry_defense_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop.antiInfantryDefense }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Attack</div>
                                        <img src="../assets/icons/attack_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop.attack }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Carry Capacity</div>
                                        <img src="../assets/icons/carry_capacity_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop.carryCapacity }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Movement Speed</div>
                                        <img src="../assets/icons/movement_speed_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop.movementSpeed }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Oil Consumption</div>
                                        <img src="../assets/icons/oil_circle_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop.oilConsumption }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Rations Consumption</div>
                                        <img src="../assets/icons/food_consumption_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop.rationsConsumption }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Unit Type</div>
                                        <img src="../assets/icons/food_consumption_icon.svg" class="troop-info-icon" />
                                        <span>Infantry</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Type of Attack</div>
                                        <img src="../assets/icons/food_consumption_icon.svg" class="troop-info-icon" />
                                        <span>Light Attack</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="troop-commands-wrapper d-flex w-100 gap-1">
                        <span class="section-title">Recruiting</span>
                        <div v-for="command in troopsCommandsToShow" :key="command" class="troop-command">
                            <div v-if="command" class="placed-command">
                                <Transition name="tooltip-transition">
                                    <div
                                        v-if="commandFinishTooltipVisible[troopsCommandsToShow.indexOf(command)]"
                                        class="recruitment-command-tooltip"
                                        @mouseover="toggleCommandTooltipVisibility(true, troopsCommandsToShow.indexOf(command))"
                                        @mouseleave="toggleCommandTooltipVisibility(false, troopsCommandsToShow.indexOf(command))"
                                    >
                                        <span class="tooltip-troop-name">{{ command.name }}</span>
                                        <span>Finish Date: {{ command.finishDateToShow }}</span>
                                    </div>
                                </Transition>
                                <div
                                    class="recruitment-image-wrapper"
                                    @mouseover="toggleCommandTooltipVisibility(true, troopsCommandsToShow.indexOf(command))"
                                    @mouseleave="toggleCommandTooltipVisibility(false, troopsCommandsToShow.indexOf(command))"
                                >
                                    <img :src="require(`../assets/icons/troops/${toKebabCase(command.name)}-tall.avif`)" />
                                    <div class="recruitment-number">
                                        {{ command.numOfTroops }}
                                    </div>
                                </div>
                                <div class="placed-command-info-wrapper">
                                    <span class="placed-command-total-time">{{ command.time }}</span>
                                    <div class="d-flex flex-row gap-1">
                                        <button class="btn recruit-button" :disabled="awaitingResponse">Cancel</button>
                                        <button class="btn premium-recruit-button" :disabled="awaitingResponse">
                                            <img src="../assets/icons/aluminum_icon.svg" class="premium-icon" />
                                            50
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="command-placeholder">
                                <img src="../assets/icons/recruitment_icon.svg" class="placeholder-icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { paramCase, snakeCase } from 'change-case';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

export default {
    name: 'RecruitmentModal',
    setup() {},
    props: {
		awaitingResponse: {
			type: Boolean,
			default: false,
		},
        troopType: {
            type: String,
            default: '',
        },
        building: {
            type: String,
            default: '',
        },
        buildings: {
            type: Array,
            default: () => [],
        },
        troopsList: {
            type: Array,
            default: () => [],
        },
        availableResources: {
            type: Object,
            default: () => ({}),
        },
        description: {
            type: String,
            default: '',
        },
        troopsCommandsToShow: {
            type: Array,
            default: () => [],
        },
        setCurrentTroop: {
            type: Function,
            default: () => null,
        },
        setCurrentBuilding: {
            type: Function,
            default: () => null,
        },
    },
    data() {
        return {
            troops: this.getTroops(),
            resources: this.getResources(),
            numberOfTroopsToRecruit: new Array(6).fill(0),
            validationInputErrorMessage: '',
            validationInputErrorMessageIndex: 0,
            troopNameTooltipVisible: new Array(6).fill(false),
            commandFinishTooltipVisible: new Array(7).fill(false),
            options: {
                dotSize: 14,
                width: 60,
                height: 4,
                contained: false,
                direction: 'ltr',
                data: null,
                dataLabel: 'label',
                dataValue: 'value',
                interval: 1,
                disabled: false,
                clickable: true,
                duration: 0,
                adsorb: true,
                lazy: false,
                tooltip: 'active',
                tooltipPlacement: 'top',
                useKeyboard: false,
                keydownHook: null,
                dragOnClick: false,
                enableCross: true,
                fixed: false,
                order: true,
                marks: false,
            },
        };
    },
    components: {
        VueSlider,
    },
    computed: {
        troopsWithRequirementsNotMet() {
            const filteredTroops = [];
            for (let i = 0; i < this.troops.length; i++) {
                if (!this.checkAllRequirementsAreMet(this.troops[i])) {
                    filteredTroops.push(this.troops[i].name);
                }
            }
            return filteredTroops;
        },
        requirementsMetPerTroop() {
            const troops = this.troopsList;
            const filteredTroops = [];
            for (let i = 0; i < troops.length; i++) {
                const troopObj = {
                    name: troops[i].name,
                    requirementsMet: [],
                };
                for (let j = 0; j < troops[i].requirements.length; j++) {
                    if (this.checkSingleRequirementIsMet(troops[i].requirements[j])) {
                        troopObj.requirementsMet.push(troops[i].requirements[j].name);
                    }
                }
                filteredTroops.push(troopObj);
            }
            return filteredTroops;
        },
    },
    methods: {
        ...mapGetters(['getResources', 'getTroops']),
        ...mapActions(['startTroopRecruitmentCommand']),

        openTroopDetailsModal() {
            document.getElementById('troop-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('troop-details-modal').style.opacity = '0';
            document.getElementById('troop-details-modal').style.display = 'block';
            document.getElementById('troop-details-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('troop-details-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('troop-details-modal').style.transform = 'translateY(0)';
                document.getElementById('troop-details-modal').style.opacity = '1';
            }, 0);
        },

        openBuildingDetailsModal(building) {
            if (building) {
                this.setCurrentBuilding(building);
            }
            document.getElementById('building-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('building-details-modal').style.opacity = '0';
            document.getElementById('building-details-modal').style.display = 'block';
            document.getElementById('building-details-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('building-details-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('building-details-modal').style.transform = 'translateY(0)';
                document.getElementById('building-details-modal').style.opacity = '1';
            }, 0);
        },

        sendCommand(data) {
            if (!this.verifyInput(this.numberOfTroopsToRecruit[this.troopsList.indexOf(data)])) {
                this.validationInputErrorMessage = 'Numar incorect';
                console.error(this.validationInputErrorMessage);
                this.validationInputErrorMessageIndex = this.troopsList.indexOf(data);
                return;
            } else {
                if (this.numberOfTroopsToRecruit[this.troopsList.indexOf(data)] <= 0) {
                    this.validationInputErrorMessage = 'Numarul nu poate fi negativ';
                    console.error(this.validationInputErrorMessage);
                    this.validationInputErrorMessageIndex = this.troopsList.indexOf(data);
                    return;
                }
            }
            if (
                this.resources.metal - data.metal * this.numberOfTroopsToRecruit[this.troopsList.indexOf(data)] < 0 ||
                this.resources.oil - data.oil * this.numberOfTroopsToRecruit[this.troopsList.indexOf(data)] < 0 ||
                this.resources.aluminum - data.aluminum * this.numberOfTroopsToRecruit[this.troopsList.indexOf(data)] < 0
            ) {
                this.validationInputErrorMessage = 'Nu ai suficiente resurse';
                console.error(this.validationInputErrorMessage);
                this.validationInputErrorMessageIndex = this.troopsList.indexOf(data);
                return;
            }
            this.startTroopRecruitmentCommand({
                name: data.name,
                numOfTroops: this.numberOfTroopsToRecruit[this.troopsList.indexOf(data)],
            });
        },

        calculateMaxTroops(troop) {
            const maxTroopsForMetal =
                this.availableResources.metal > troop.metal ? Math.floor(this.availableResources.metal / troop.metal) : 0;
            const maxTroopsForOil =
                this.availableResources.oil > troop.oil ? Math.floor(this.availableResources.oil / troop.oil) : 0;
            const maxTroopsForAluminum =
                this.availableResources.aluminum > troop.aluminum
                    ? Math.floor(this.availableResources.aluminum / troop.aluminum)
                    : 0;

            return Math.min(maxTroopsForMetal, maxTroopsForOil, maxTroopsForAluminum);
        },

        verifyInput(val) {
            return !(isNaN(val) || val === '');
        },

        calculateTotalTime(numOfTroops, timeStr) {
            // Convert time string to total seconds
            const timeArr = timeStr.split(':');
            const totalSeconds = parseInt(timeArr[0]) * 3600 + parseInt(timeArr[1]) * 60 + parseInt(timeArr[2]);

            // Multiply total seconds by integer parameter
            const multipliedSeconds = totalSeconds * numOfTroops;

            // Convert multiplied seconds back to time string format
            const hours = Math.floor(multipliedSeconds / 3600);
            const minutes = Math.floor((multipliedSeconds % 3600) / 60);
            const seconds = multipliedSeconds % 60;

            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
                .toString()
                .padStart(2, '0')}`;
        },

        toKebabCase(string) {
            return paramCase(string);
        },

        toSnakeCase(string) {
            return snakeCase(string);
        },

        recruitMax(troop) {
            this.numberOfTroopsToRecruit[troop] = this.calculateMaxTroops(this.troopsList[troop]);
        },

        recruitMin(troop) {
            this.numberOfTroopsToRecruit[troop] = this.calculateMaxTroops(this.troops.other[troop]) ? 1 : 0;
        },

        toggleTroopNameVisibility(value, index) {
            this.troopNameTooltipVisible[index] = value;
        },

        toggleCommandTooltipVisibility(value, index) {
            this.commandFinishTooltipVisible[index] = value;
        },

        getBuildingAppearance(requirement) {
            const building = this.buildings.filter(bld => bld.name === requirement.name)[0];
            if (building) {
                for (let i = building?.appearance?.length; i >= 0; i--) {
                    if (requirement.level >= building.appearance[i]) {
                        return i + 2;
                    }
                }
            }
            return 1;
        },

        getBuildingFromRequirementName(requirement_name) {
            return this.buildings.find(building => building.name === requirement_name);
        },

        checkSingleRequirementIsMet(requirement) {
            const allBuildings = this.buildings;
            const requiredBuilding = allBuildings.find(building => building.name === requirement.name);
            return requiredBuilding?.level >= requirement.level;
        },

        checkAllRequirementsAreMet(troop) {
            const allBuildings = this.buildings;
            let requirementsMet = true;
            for (let i = 0; i < allBuildings.length; i++) {
                for (let j = 0; j < troop.requirements.length; j++) {
                    if (!this.checkSingleRequirementIsMet(troop.requirements[j])) {
                        requirementsMet = false;
                    }
                }
            }
            return requirementsMet;
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
    font-size: 1.5rem;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 3rem 1rem 1rem 1rem;
}

.nav {
    align-items: baseline;
    border: none;
    transform: translateY(-2px);
}

.nav-item {
    margin-right: 0.25rem;
}

.nav-item:nth-last-child(2) {
    margin-right: 0;
}

.tab-wrapper {
    height: 6.5rem;
    width: calc(3rem + 8px);
    overflow: hidden;
    padding: 2px;
    border: 2px solid #434343;
    border-bottom: none;
    position: relative;
}

.tab-wrapper > .tab-img-wrapper {
    width: 3rem;
    transition: filter 0.3s;
    height: 4.25rem;
    overflow: hidden;
}

.tab-img-wrapper > img {
    width: 3rem;
}

.tab-img-wrapper > img.unavailable {
    filter: grayscale(1) contrast(1.25) brightness(0.5);
}

.units .tab-img-wrapper > img {
    transform: translateY(-8px);
}

.nav-tabs .nav-link {
    padding: 0;
    height: 6rem;
    border-radius: 0;
    border: none;
}

.nav-tabs .nav-link.active {
    background: none;
    transform: translateY(-31px);
}

.nav-tabs .nav-link:not(.active) {
    transform: translateY(-7px);
}

.nav-link.active .tab-wrapper {
    height: 8rem;
    width: calc(4rem + 8px);
}

.nav-link.active .tab-wrapper > .tab-img-wrapper {
    width: 4rem;
    height: 5.625rem;
}

.nav-link.active .tab-wrapper > .tab-img-wrapper > img {
    width: 4rem;
}

.nav-link:hover:not(.active) img {
    filter: brightness(1.2) contrast(1.1);
}

.nav-link:hover:not(.active) img.unavailable {
    filter: grayscale(1) contrast(1.5) brightness(0.75);
}

.nav-filler {
    flex-grow: 1;
    border-bottom: 2px solid #434343;
    transform: translateY(80px);
}

.current-troops {
    width: 100%;
    height: 1rem;
    font-size: 0.75rem;
    color: ghostwhite;
}

.max-troops {
    position: absolute;
    bottom: 0;
    width: calc(100% - 4px);
    height: 1rem;
    font-size: 0.75rem;
    color: ghostwhite;
}

.troop-name-tooltip,
.recruitment-command-tooltip {
    z-index: 1;
    position: absolute;
    background: black;
    color: ghostwhite;
    font-size: 0.75rem;
    line-height: 0.75rem;
    top: -1rem;
    padding: 0.25rem;
    display: flex;
    border: 1px solid #434343;
}

.recruitment-command-tooltip {
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    flex-direction: column;
}

.tooltip-troop-name {
    font-size: 1rem;
    padding: 0.25rem 1rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}

.tooltip-transition-enter-active,
.tooltip-transition-leave-active {
    transition: filter 0.05s linear;
}

.tooltip-transition-enter-from,
.tooltip-transition-leave-to {
    filter: blur(3px);
}

.tab-pane {
    background: rgba(0, 0, 0, 0.25);
    border: 2px solid #434343;
    border-top: none;
    padding: 3.5rem 2rem 2rem 2rem;
}

.requirements-title {
    font-size: 1.125rem;
}

.building-requirements-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2.75rem;
    margin-top: -1.75rem;
}

.requirements-group {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.requirement-icon {
    filter: grayscale(1) contrast(1.25);
    cursor: pointer;
	height: 2.5rem;
	width: auto;
}

.requirement-name-wrapper {
    display: flex;
    flex-direction: column;
}

.requirement-name {
    font-size: 1rem;
    line-height: 1rem;
    color: lightgray;
    cursor: pointer;
}

.requirement-name:hover {
    color: cornflowerblue;
}

.requirement-level {
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: darkgray;
}

.recruitment-window {
    position: relative;
    border: 2px solid #434343;
    display: flex;
    flex-direction: row;
    padding: 2px;
    width: 32%;
    background: black;
}

.recruitment-window .section-title:hover {
    cursor: pointer;
    color: cornflowerblue;
}

.recruitment-image-wrapper {
    position: relative;
}

.recruitment-image-wrapper > img {
    width: 6rem;
    height: 100%;
}

.recruitment-number {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25%;
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
}

.recruitment-number::before {
    content: '+';
    margin-right: 0.125rem;
}

.recruitment-command-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    margin-left: 2px;
    width: 100%;
}

.min-recruit,
.max-recruit {
    display: block;
    width: 2rem;
    text-align: center;
    border: 2px solid darkgray;
}

.min-recruit:hover,
.max-recruit:hover {
    cursor: pointer;
    color: cornflowerblue;
}

.recruit-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
    width: 100%;
}

.recruit-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.premium-recruit-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.premium-recruit-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.premium-icon {
    height: 0.875rem;
}

.cost-window {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2px;
    width: 32%;
    border: 2px solid #434343;
    padding: 2px;
    background: black;
}

.troop-info-window {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2px;
    border: 2px solid #434343;
    padding: 2px;
    background: black;
    width: 32%;
}

.resource-wrapper,
.troop-info-wrapper {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 1rem;
    width: 8rem;
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    padding: 0.25rem 0.5rem;
    position: relative;
}

.icon-tooltip {
    opacity: 0;
    filter: blur(3px);
    position: absolute;
    top: -1.375rem;
    pointer-events: none;
    transition: opacity 0.3s, filter 0.3s;
    background: black;
    color: ghostwhite;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.25rem;
    border: 1px solid #434343;
}

.resource-wrapper:hover .icon-tooltip,
.troop-info-wrapper:hover .icon-tooltip {
    opacity: 1;
    filter: blur(0);
}

.resource-icon,
.troop-info-icon {
    width: 1.5rem;
}

.section-title {
    position: absolute;
    top: -2rem;
}

.clickable {
    text-decoration: underline;
}

.troop-commands-wrapper {
    position: relative;
    margin-top: 3rem;
    flex-wrap: wrap;
}

.troop-command {
    display: flex;
    /*height: 6rem;*/
    border: 2px solid #434343;
    background: linear-gradient(to bottom, #2a2a2a, #181818);
}

.placed-command {
    border: 2px solid black;
    display: flex;
}

.placed-command .recruitment-image-wrapper {
    height: 6rem;
    width: initial;
}

.placed-command .recruitment-image-wrapper img {
    height: 6rem;
    width: initial;
}

.placed-command .recruitment-number {
    font-size: 0.875rem;
}

.placed-command .recruitment-number:before {
    display: none;
}

.placed-command-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-left: 2px solid black;
    padding: 0 0.375rem;
}

.placed-command-info-wrapper .recruit-button {
    line-height: 1.5rem;
    font-size: 0.875rem;
    padding: 0 0.375rem;
}

.placed-command-total-time {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 2px solid #606060;
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding: 0 1rem;
}

.command-placeholder {
    width: 6rem;
    height: calc(6rem + 4px);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
}

.placeholder-icon {
    height: 2rem;
    filter: contrast(0);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.form-control {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 2px solid darkgray;
    border-radius: 0;
    color: ghostwhite;
    /*height: 1.75rem;*/
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.pointer-events-none {
    pointer-events: none;
}

@media screen and (max-width: 1199px) {
    .recruitment-window {
        width: 48%;
    }
    .cost-window {
        width: 48%;
    }
    .troop-info-window {
        width: 100%;
        margin-top: 3rem;
    }
    .modal-title {
        width: 60%;
    }
    .building-description {
        width: 60%;
    }
}

@media screen and (max-width: 991px) {
    .modal-content {
        padding: 3rem 1rem;
    }
    .recruitment-window {
        width: 100%;
    }
    .cost-window {
        margin-top: 3rem;
        width: 100%;
    }
    .troop-info-window {
        width: 100%;
        margin-top: 3rem;
    }
    .modal-title {
        width: 80%;
    }
    .building-description {
        width: 80%;
    }
}

@media screen and (max-width: 460px) {
    .tab-pane {
        padding: 3.5rem 1rem 1rem 1rem;
    }
    .tab-wrapper {
        height: 5rem;
        width: calc(2rem + 8px);
    }
    .tab-wrapper > .tab-img-wrapper {
        width: 2rem;
        height: 2.75rem;
    }
    .tab-img-wrapper > img {
        width: 2rem;
    }
    .units .tab-img-wrapper > img {
        transform: translateY(-5px);
    }
    .nav-tabs .nav-link {
        height: 2rem;
    }
    .nav-link.active .tab-wrapper {
        height: 6.375rem;
        width: calc(3rem + 8px);
    }
    .nav-link.active .tab-wrapper > .tab-img-wrapper {
        width: 3rem;
        height: 4.125rem;
    }
    .nav-link.active .tab-wrapper > .tab-img-wrapper > img {
        width: 3rem;
    }
    .nav-filler {
        transform: translateY(18px);
    }
    .current-troops {
        height: 1rem;
        font-size: 0.625rem;
    }

    .max-troops {
        height: 1rem;
        font-size: 0.625rem;
    }
    .nav-tabs .nav-link.active {
        transform: translateY(-69px);
    }

    .nav-tabs .nav-link:not(.active) {
        transform: translateY(-47px);
    }
    .modal-body {
        padding: 5rem 1rem 1rem 1rem;
    }
    .modal-title {
        width: 100%;
    }
    .building-description {
        width: 100%;
    }
}

@media screen and (max-width: 380px) {
    .recruitment-window {
        height: 7rem;
    }
    .recruitment-slider {
        display: none !important;
    }
    .recruitment-image-wrapper {
        overflow-y: clip;
    }
    .recruitment-image-wrapper > img {
        height: initial;
        width: 5rem;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
