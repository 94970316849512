<template>
    <div id="nav">
        <div class="top-bar-right"></div>
        <div class="top-filler"></div>
        <div class="bottom-bar"></div>
        <div class="bottom-filler">
            <div class="bottom-filler-line"></div>
        </div>
        <div class="date-wrapper">
            <div class="current-date">{{ computedDate }}</div>
            <div class="current-time">
                Time: <span class="time">{{ time }}</span>
            </div>
        </div>
        <div v-if="$route.fullPath.includes('register') || $route.fullPath === '/'" class="legal-info-text copyright-text">
            © 2023 DINESCU ALEXANDRU-CATALIN PFA. All rights reserved.
        </div>
        <div
            v-if="$route.fullPath.includes('register') || $route.fullPath === '/'"
            class="legal-info-text contact-text legal-clickable"
            data-bs-toggle="modal"
            data-bs-target="#contact-info-modal"
        >
            Contact
        </div>
        <div
            v-if="$route.fullPath.includes('register') || $route.fullPath === '/'"
            class="legal-info-text terms-and-conditions-text"
        >
            <span class="legal-clickable" data-bs-toggle="modal" data-bs-target="#terms-and-conditions-modal"
                >Terms & Conditions</span
            >
            |
            <span class="legal-clickable" data-bs-toggle="modal" data-bs-target="#privacy-policy-modal"
                >Privacy Policy & Protection of personal data</span
            >
            | <span class="legal-clickable" data-bs-toggle="modal" data-bs-target="#cookie-policy-modal">Cookie Policy</span>
            <span class="visible-small">|</span>
            <span
                class="visible-small legal-info-text legal-clickable"
                data-bs-toggle="modal"
                data-bs-target="#contact-info-modal"
                >Contact</span
            >
        </div>
        <div
            v-if="$route.fullPath.includes('register') || $route.fullPath === '/'"
            class="developers-wrapper"
            @mouseover="meetDevelopersHover = true"
            @mouseleave="meetDevelopersHover = false"
        >
            <span class="developers-text">Meet the developers</span>
            <img src="../src/assets/inform_icon_2.svg" width="20" height="20" />
        </div>
        <div class="developers-tooltip" :class="{ 'developers-fade': !meetDevelopersHover }">
            <p class="tooltip-title">Alexandru Dinescu</p>
            <p class="tooltip-subtitle">Creator / Backend</p>
            <p class="tooltip-title">Ion Ionescu</p>
            <p class="tooltip-subtitle">Graphic design</p>
            <p class="tooltip-title">Razvan Duta</p>
            <p class="tooltip-subtitle">Frontend</p>
        </div>
        <router-view />
        <terms-and-conditions-modal id="terms-and-conditions-modal" />
        <privacy-policy-modal id="privacy-policy-modal" />
        <cookie-policy-modal id="cookie-policy-modal" />
        <contact-info-modal id="contact-info-modal" />
    </div>
</template>

<script>
import TermsAndConditionsModal from '@/components/TermsAndConditionsModal';
import PrivacyPolicyModal from '@/components/PrivacyPolicyModal';
import CookiePolicyModal from '@/components/CookiePolicyModal';
import ContactInfoModal from '@/components/ContactInfoModal';
export default {
    components: { ContactInfoModal, CookiePolicyModal, PrivacyPolicyModal, TermsAndConditionsModal },
    data() {
        return {
            interval: null,
            time: null,
            meetDevelopersHover: false,
        };
    },
    computed: {
        currentDate() {
            return new Date();
        },
        computedDate() {
            return (
                this.convertMonth(this.currentDate.getMonth()) +
                ' ' +
                this.currentDate.getDate() +
                ' ' +
                this.currentDate.getFullYear()
            );
        },
    },
    methods: {
        convertMonth(month) {
            const monthsArray = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ];
            return monthsArray[month];
        },
        updateTime() {
            let cd = new Date();
            this.time = cd.toLocaleString(navigator.language, {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true,
            });
        },
    },
    created() {
        this.updateTime();
        setInterval(() => {
            this.updateTime();
        }, 1000);
    },
};
</script>

<style>
@font-face {
    font-family: 'sansation';
    font-weight: 300;
    src: url('assets/fonts/sansation/Sansation_Light.ttf') format('truetype');
}

@font-face {
    font-family: 'sansation';
    font-weight: 500;
    src: url('assets/fonts/sansation/Sansation_Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'sansation';
    font-weight: 700;
    src: url('assets/fonts/sansation/Sansation_Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'lato';
    font-weight: 300;
    src: url('assets/fonts/lato/Lato-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'lato';
    font-weight: 300;
    font-style: italic;
    src: url('assets/fonts/lato/Lato-ThinItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'lato';
    font-weight: 400;
    src: url('assets/fonts/lato/Lato-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'lato';
    font-weight: 400;
    font-style: italic;
    src: url('assets/fonts/lato/Lato-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'lato';
    font-weight: 500;
    src: url('assets/fonts/lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'lato';
    font-weight: 500;
    font-style: italic;
    src: url('assets/fonts/lato/Lato-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'lato';
    font-weight: 600;
    src: url('assets/fonts/lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'lato';
    font-weight: 600;
    font-style: italic;
    src: url('assets/fonts/lato/Lato-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'lato';
    font-weight: 700;
    src: url('assets/fonts/lato/Lato-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'lato';
    font-weight: 700;
    font-style: italic;
    src: url('assets/fonts/lato/Lato-BlackItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'bebas';
    font-weight: 700;
    src: url('assets/fonts/bebas/Bebas-Regular.ttf') format('truetype');
}

.top-bar-right {
    background-image: url('assets/top_bar_right.png');
    background-size: 960px;
    background-position: top right;
    background-repeat: no-repeat;
    width: 50vw;
    height: 20vh;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;
}

.top-filler {
    position: absolute;
    top: 0;
    left: 0;
    width: 50vw;
    height: 57px;
    background: rgba(0, 0, 0, 0.63);
    border-bottom: 2px solid #434343;
    z-index: 1;
    pointer-events: none;
}

.bottom-bar {
    background-image: url('assets/bottom_bar.png');
    background-size: 1920px;
    background-position: bottom right;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    pointer-events: none;
}

.bottom-filler {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100vw - 1920px);
    height: 100px;
    background: rgba(0, 0, 0, 0.63);
    z-index: 1;
    pointer-events: none;
}

.bottom-filler-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100vw - 1920px);
    height: 89px;
    border-top: 2px solid #434343;
    z-index: 1;
    pointer-events: none;
}

.date-wrapper {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 2.5rem;
    z-index: 9;
}

.current-date,
.current-time {
    padding: 0 1rem;
    margin: 1rem;
    font-family: 'sansation', sans-serif;
    font-size: 0.75rem;
    color: lightgray;
    background: #1a1a1a;
    border: 1px solid #434343;
    letter-spacing: 0.05rem;
}

.current-time {
    margin-left: 0;
}

.time {
    text-transform: uppercase;
}

.legal-clickable {
    text-decoration: underline;
    cursor: pointer;
}

.legal-clickable:hover {
    text-decoration: none;
    color: cornflowerblue;
}

.legal-info-text {
    color: slategrey;
    font-size: 0.6875rem;
}

.copyright-text {
    position: absolute;
    left: 1rem;
    bottom: 1.75rem;
    z-index: 9;
}

.contact-text {
    position: absolute;
    left: 1rem;
    bottom: 0.75rem;
    z-index: 9;
}

.terms-and-conditions-text {
    position: absolute;
    bottom: 1.1875rem;
    right: 12rem;
    z-index: 9;
    text-align: right;
}

.visible-small {
    display: none;
}

.developers-wrapper {
    z-index: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;
    cursor: pointer;
}

.developers-text {
    font-size: 0.75rem;
    color: lightgray;
    margin-right: 0.5rem;
}

.developers-wrapper img {
    transform: translateY(-2px);
}

.developers-tooltip {
    font-family: 'sansation', sans-serif;
    z-index: 1;
    position: absolute;
    background: black;
    bottom: 3rem;
    right: 1rem;
    padding: 1rem;
    transition: opacity 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #434343;
}

.developers-fade {
    opacity: 0;
    z-index: 0;
}

.tooltip-title {
    color: ghostwhite;
    font-size: 1rem;
    margin: 0;
}

.tooltip-subtitle {
    color: lightgray;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
}

@media screen and (min-width: 1920px) {
    .top-filler {
        width: calc(50vw + calc(100vw - 1920px) / 2);
    }
}

@media screen and (max-width: 1279px) {
    .top-filler {
        height: 50px;
    }
    .top-bar-right {
        background-size: 850px;
    }
    .bottom-bar {
        transform: translateY(25px);
    }
    .current-date,
    .current-time {
        margin: 0.5rem 0.5rem;
    }
    .current-time {
        margin-left: 0;
    }
}

@media screen and (max-width: 991px) {
    .terms-and-conditions-text {
        width: 30%;
    }
}

@media screen and (max-width: 920px) {
    .top-filler {
        height: 45px;
    }
    .top-bar-right {
        background-size: 750px;
    }
    .bottom-bar {
        background-size: 1700px;
    }
    .date-wrapper {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .bottom-bar {
        display: none;
    }
    .bottom-filler {
        width: 100%;
        border-top: 2px solid #434343;
        height: 80px;
    }
    .copyright-text {
        width: 30%;
    }
}

@media screen and (max-width: 640px) {
    .top-bar-right {
        display: none;
    }
    .top-filler {
        width: 100%;
        height: 56px;
    }
}

@media screen and (max-width: 500px) {
    .developers-text {
        display: none;
    }
    .copyright-text {
        width: 10rem;
    }
    .terms-and-conditions-text {
        right: 3.75rem;
        width: 10rem;
    }
}

@media screen and (max-width: 380px) {
    .bottom-filler {
        height: 64px;
    }
    .terms-and-conditions-text {
        bottom: 0.5rem;
        width: 70%;
    }
    .copyright-text {
        bottom: unset;
        top: 0.25rem;
    }
    .visible-small {
        display: inline;
    }
    .contact-text {
        display: none;
    }
}
</style>
