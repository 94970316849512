<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div
            :class="{ 'small-modal': reportType === 'invite' || reportType === 'coalition' }"
            class="modal-dialog modal-lg"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Cartof attacked Mamaliga-Prajita's City</h4>
                    <button type="button" class="close" @click="closeModal">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="reportType === 'battle' || reportType === 'trade'" class="battle-report-table">
                        <div class="row">
                            <div class="col label attacker-info">
                                <img src="../assets/icons/buildings/main_building_mini_3.avif" class="city-image" />
                                <div class="info-wrapper">
                                    <span class="city-name clickable">Cartof's City</span>
                                    <span class="player-name clickable">Cartof</span>
                                    <span class="coalition-name clickable">Cartofi Prajiti</span>
                                </div>
                            </div>
                            <div class="col middle-image-wrapper"></div>
                            <div class="col label defender-info">
                                <img src="../assets/icons/buildings/main_building_mini_3.avif" class="city-image" />
                                <div class="info-wrapper">
                                    <span class="city-name clickable">Varza's City</span>
                                    <span class="player-name clickable">Varza</span>
                                    <span class="coalition-name clickable">Varza cu Ciolan</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col troops-wrapper">
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Bazooka Soldier</div>
                                    <img src="../assets/icons/troops/bazooka-soldier-tall.avif" class="unit-icon" />
                                    <span class="current-troops">54</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">General</div>
                                    <img src="../assets/icons/troops/general-tall.avif" class="unit-icon" />
                                    <span class="current-troops">3</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Machine Gunner</div>
                                    <img src="../assets/icons/troops/machine-gunner-tall.avif" class="unit-icon" />
                                    <span class="current-troops">27</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Medic</div>
                                    <img src="../assets/icons/troops/medic-tall.avif" class="unit-icon" />
                                    <span class="current-troops">9</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Officer</div>
                                    <img src="../assets/icons/troops/officer-tall.avif" class="unit-icon" />
                                    <span class="current-troops">32</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Sniper</div>
                                    <img src="../assets/icons/troops/sniper-tall.avif" class="unit-icon" />
                                    <span class="current-troops">12</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Infantryman</div>
                                    <img src="../assets/icons/troops/infantryman-tall.avif" class="unit-icon" />
                                    <span class="current-troops">163</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Spy</div>
                                    <img src="../assets/icons/troops/spy-tall.avif" class="unit-icon" />
                                    <span class="current-troops">16</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                            </div>
                            <div class="col modifiers-and-loot">
                                <div v-if="reportType === 'battle'" class="modifiers-wrapper">
                                    <div class="modifier-group">
                                        <img src="../assets/icons/metal_circle_icon.svg" class="modifier-icon" />
                                        <span class="modifier-name"> Fighting Power </span>
                                    </div>
                                    <div class="modifier-group">
                                        <img src="../assets/icons/metal_circle_icon.svg" class="modifier-icon" />
                                        <span class="modifier-name"> Luck </span>
                                    </div>
                                    <div class="modifier-group">
                                        <img src="../assets/icons/metal_circle_icon.svg" class="modifier-icon" />
                                        <span class="modifier-name"> Bonus 1 </span>
                                    </div>
                                    <div class="modifier-group">
                                        <img src="../assets/icons/metal_circle_icon.svg" class="modifier-icon" />
                                        <span class="modifier-name"> Bonus 2 </span>
                                    </div>
                                    <div class="modifier-group">
                                        <img src="../assets/icons/metal_circle_icon.svg" class="modifier-icon" />
                                        <span class="modifier-name"> Bonus 3 </span>
                                    </div>
                                </div>
                                <div v-if="reportType === 'battle'" class="destroyed-buildings-wrapper">
                                    <div class="building-group">
                                        <img src="../assets/icons/buildings/fortifications_mini_3.avif" class="building-image" />
                                        <span class="building-name"> Defensive Wall: 12 (-5) </span>
                                    </div>
                                    <div class="building-group">
                                        <img src="../assets/icons/buildings/main_building_mini_3.avif" class="building-image" />
                                        <span class="building-name"> Main Building: 16 (-2) </span>
                                    </div>
                                </div>
                                <div v-if="resourcesLooted" class="loot-wrapper">
                                    <div class="loot-group">
                                        <img src="../assets/icons/metal_circle_icon.svg" class="loot-icon" />
                                        <span class="loot-name"> +1500 </span>
                                    </div>
                                    <div class="loot-group">
                                        <img src="../assets/icons/oil_circle_icon.svg" class="loot-icon" />
                                        <span class="loot-name"> +200 </span>
                                    </div>
                                    <div class="loot-group">
                                        <img src="../assets/icons/aluminum_circle_icon.svg" class="loot-icon" />
                                        <span class="loot-name"> +1200 </span>
                                    </div>
                                </div>
                                <div v-else class="no-loot">No resources were looted</div>
                            </div>
                            <div v-if="defenderTroops" class="col troops-wrapper">
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Truck</div>
                                    <img src="../assets/icons/troops/truck-tall.avif" class="unit-icon" />
                                    <span class="current-troops">5</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Transport Plane</div>
                                    <img src="../assets/icons/troops/transport-plane-tall.avif" class="unit-icon" />
                                    <span class="current-troops">3</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Anti-air</div>
                                    <img src="../assets/icons/troops/anti-air-tall.avif" class="unit-icon" />
                                    <span class="current-troops">23</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Close Air Support</div>
                                    <img src="../assets/icons/troops/close-air-support-tall.avif" class="unit-icon" />
                                    <span class="current-troops">9</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Bombing Plane</div>
                                    <img src="../assets/icons/troops/bombing-plane-tall.avif" class="unit-icon" />
                                    <span class="current-troops">14</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Medium Tank</div>
                                    <img src="../assets/icons/troops/medium-tank-tall.avif" class="unit-icon" />
                                    <span class="current-troops">12</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Fighter</div>
                                    <img src="../assets/icons/troops/fighter-tall.avif" class="unit-icon" />
                                    <span class="current-troops">8</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Anti-tank</div>
                                    <img src="../assets/icons/troops/anti-tank-tall.avif" class="unit-icon" />
                                    <span class="current-troops">16</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Light Tank</div>
                                    <img src="../assets/icons/troops/light-tank-tall.avif" class="unit-icon" />
                                    <span class="current-troops">11</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Heavy Tank</div>
                                    <img src="../assets/icons/troops/heavy-tank-tall.avif" class="unit-icon" />
                                    <span class="current-troops">2</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                                <div class="unit-icon-wrapper">
                                    <div class="icon-tooltip">Spy Plane</div>
                                    <img src="../assets/icons/troops/spy-plane-tall.avif" class="unit-icon" />
                                    <span class="current-troops">34</span>
                                    <span class="lost-troops">{{ getRandomInt(0, 999) }}</span>
                                </div>
                            </div>
                            <div v-else class="col no-defenders">
                                {{ reportType === 'battle' ? 'No defenders' : '' }}
                            </div>
                        </div>
                    </div>
                    <div v-if="reportType === 'invite' || reportType === 'coalition'" class="other-report">
                        <p class="report-text-body">
                            <span class="player clickable">Costy-Fortza</span> invited you to join
                            <span class="coalition clickable">Mamaliga cu Paine (MCP)</span>
                        </p>
                        <div v-if="reportType === 'invite'" class="d-flex justify-content-center mt-5">
                            <button class="btn yellow-button" @click="openConfirmationModal">Accept</button>
                        </div>
                    </div>
                    <div v-if="canBeShared" class="d-flex justify-content-center mt-5">
                        <button class="btn yellow-button" @click="openShareModal">Share</button>
                    </div>
                </div>
            </div>
        </div>
        <share-modal id="share-modal" />
    </div>
</template>

<script>
import ShareModal from '@/components/ShareModal';
export default {
    name: 'OpenedReportModal',
    components: { ShareModal },
    data() {
        return {
            defenderTroops: true,
            resourcesLooted: true,
            canBeShared: false,
            reportType: 'battle', // 'battle' | 'trade' | 'invite' | 'coalition'
        };
    },
    methods: {
        openConfirmationModal() {
            document.getElementById('confirmation-modal').style.transform = 'translateY(-20px)';
            document.getElementById('confirmation-modal').style.opacity = '0';
            document.getElementById('confirmation-modal').style.display = 'block';
            document.getElementById('confirmation-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('confirmation-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('confirmation-modal').style.transform = 'translateY(0)';
                document.getElementById('confirmation-modal').style.opacity = '1';
            }, 0);
        },
        openShareModal() {
            document.getElementById('share-modal').style.transform = 'translateY(-20px)';
            document.getElementById('share-modal').style.opacity = '0';
            document.getElementById('share-modal').style.display = 'block';
            document.getElementById('share-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('share-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('share-modal').style.transform = 'translateY(0)';
                document.getElementById('share-modal').style.opacity = '1';
            }, 0);
        },
        closeModal() {
            document.getElementById('opened-report-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('opened-report-modal').style.transform = 'translateY(-20px)';
            document.getElementById('opened-report-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('opened-report-modal').style.display = 'none';
                document.getElementById('opened-report-modal').classList.remove('show');
                document.getElementById('opened-report-modal').style.removeProperty('transform');
                document.getElementById('opened-report-modal').style.removeProperty('opacity');
            }, 150);
        },
        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-dialog {
    max-width: 1000px;
}

.modal-dialog.small-modal {
    max-width: 600px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
    padding: 0;
}

.modal-title {
    width: 100%;
    font-family: 'sansation', sans-serif;
    text-align: center;
    font-size: 1.375rem;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 12vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2px;
    margin: 0;
    min-height: 2rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
}

.col.label {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    font-size: 1rem;
}

.btn {
    padding: 0.25rem 1rem;
    width: fit-content;
    height: fit-content;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.clickable:hover {
    color: cornflowerblue;
    cursor: pointer;
    opacity: 1;
}

.battle-report-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.attacker-info {
    display: flex;
    flex-direction: row;
}

.defender-info {
    display: flex;
    flex-direction: row-reverse;
    text-align: right;
}

.city-image {
    height: 5rem;
    width: auto;
}

.info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    font-size: 1rem;
}

.city-name {
    font-size: 1.125em;
}

.player-name {
    font-size: 1em;
    opacity: 0.8;
}

.coalition-name {
    font-size: 0.875em;
    opacity: 0.6;
}

.troops-wrapper {
    flex-wrap: wrap;
    align-content: flex-start;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background: rgba(15, 15, 15, 0.7);
}

.no-defenders {
    align-content: center;
    justify-content: center;
    background: rgba(15, 15, 15, 0.7);
    color: darkgray;
    text-align: center;
}

.unit-icon-wrapper {
    position: relative;
    margin-bottom: 1rem;
}

.unit-icon-wrapper .current-troops {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    min-width: 17px;
    height: 16px;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.625rem;
    color: ghostwhite;
    align-items: center;
    justify-content: center;
    background: darkslategray;
    padding: 0 0.125rem;
}

.unit-icon-wrapper .lost-troops {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    color: #d34848;
    text-align: center;
    font-size: 0.75rem;
}

.unit-icon-wrapper .lost-troops:before {
    content: '-';
}

.unit-icon {
    height: 64px;
    width: 34px;
}

.icon-tooltip {
    z-index: 1;
    opacity: 0;
    filter: blur(3px);
    position: absolute;
    bottom: -1.375rem;
    pointer-events: none;
    transition: opacity 0.3s, filter 0.3s;
    background: black;
    color: ghostwhite;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.25rem;
    border: 1px solid #434343;
}

.unit-icon-wrapper .icon-tooltip {
    bottom: initial;
    top: -2rem;
}

.unit-icon-wrapper:hover .icon-tooltip {
    opacity: 1;
    filter: blur(0);
}

.modifiers-and-loot {
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    padding-top: 2rem;
    padding-bottom: 2rem;
    gap: 1rem;
    /*background: rgba(20, 20, 20, 0.5);*/
}

.modifiers-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.modifier-group {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.modifier-icon {
    width: 1.25rem;
    height: 1.25rem;
}

.destroyed-buildings-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    align-items: flex-end;
    padding-bottom: 1rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}

.building-group {
    display: flex;
    flex-direction: row-reverse;
    gap: 0.5rem;
    align-items: center;
}

.building-image {
    width: 1.75rem;
    height: 1.75rem;
}

.building-name {
    text-align: right;
}

.loot-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    margin: 0 auto;
}

.no-loot {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    color: darkgray;
}

.loot-group {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.loot-icon {
    width: 1.25rem;
    height: 1.25rem;
}

.loot-name {
    color: darkseagreen;
}

.player,
.coalition {
    color: burlywood;
}

.report-text-body {
    /*color: ghostwhite;*/
    text-align: center;
}

@media screen and (max-width: 1200px) {
    .modal-dialog {
        max-width: 80%;
    }
    .modal-dialog.small-modal {
        max-width: 60%;
    }
}

@media screen and (max-width: 991px) {
    .modal-content {
        padding: 3rem 1rem;
    }
    .city-image {
        height: 4rem;
        width: auto;
    }
    .info-wrapper {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 850px) {
    .city-image {
        height: 3rem;
        width: auto;
    }
    .info-wrapper {
        font-size: 0.75rem;
    }
    .modal-dialog.small-modal {
        max-width: 70%;
    }
}

@media screen and (max-width: 700px) {
    .modal-title {
        font-size: 1rem;
    }
    .modal-body {
        padding-top: 1rem;
    }
    .col {
        font-size: 0.75rem;
        line-height: 0.75rem;
        min-width: 3rem;
        padding: 0.25rem;
    }
    .col.label {
        font-size: 0.875rem;
    }
    .modifiers-wrapper {
        gap: 0.25rem;
    }
    .modifier-icon,
    .loot-icon {
        width: 1rem;
        height: 1rem;
    }
    .building-image {
        width: 1.5rem;
        height: 1.5rem;
    }
    .troops-wrapper {
        gap: 0.375rem;
    }
    .unit-icon {
        height: 51px;
        width: 27px;
    }
    .unit-icon-wrapper .lost-troops {
        transform: translateY(120%);
        font-size: 0.6875rem;
    }
    .middle-image-wrapper {
        display: none;
    }
    .modifiers-and-loot {
        flex-basis: 165%;
        padding: 0.5rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 450px) {
    .modal-content {
        padding: 3rem 0;
    }
    .city-image {
        height: 2rem;
        width: auto;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
