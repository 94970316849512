<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Messages</h5>
                    <div class="building-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs" id="messages-nav" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link active"
                                id="inbox"
                                data-bs-toggle="tab"
                                data-bs-target="#inbox-page"
                                type="button"
                                role="tab"
                                aria-controls="inbox-tab"
                                aria-selected="true"
                            >
                                Inbox
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="coalition"
                                data-bs-toggle="tab"
                                data-bs-target="#coalition-page"
                                type="button"
                                role="tab"
                                aria-controls="coalition-tab"
                                aria-selected="false"
                            >
                                Coalition
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="write-message"
                                data-bs-toggle="tab"
                                data-bs-target="#write-message-page"
                                type="button"
                                role="tab"
                                aria-controls="write-message-tab"
                                aria-selected="false"
                            >
                                Write Message
                            </button>
                        </li>
                        <li class="nav-filler" />
                    </ul>
                    <div class="tab-content" id="messages-pages">
                        <div class="tab-pane fade show active" id="inbox-page" role="tabpanel" aria-labelledby="inbox-tab">
                            <nav aria-label="inbox-pages">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === 0 }"
                                            @click="changeVisiblePage(currentPage - 1)"
                                            >&larr;</a
                                        >
                                    </li>
                                    <li v-for="page in pages" :key="pages.indexOf(page)" class="page-item">
                                        <a
                                            :class="{ selected: visiblePage[pages.indexOf(page)] }"
                                            class="page-link"
                                            href="#"
                                            @click="changeVisiblePage(pages.indexOf(page))"
                                            >{{ pages.indexOf(page) + 1 }}</a
                                        >
                                    </li>
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === this.pages.length - 1 }"
                                            @click="changeVisiblePage(currentPage + 1)"
                                            >&rarr;</a
                                        >
                                    </li>
                                </ul>
                            </nav>
                            <div v-for="page in pages" :key="pages.indexOf(page)" class="messages-table-wrapper">
                                <Transition name="page-transition">
                                    <div v-if="this.visiblePage[pages.indexOf(page)]" class="messages-table">
                                        <div class="row label">
                                            <div class="col large">Title</div>
                                            <div class="col small">Sender</div>
                                            <div class="col small">Date</div>
                                        </div>
                                        <div v-for="message in page" :key="page.indexOf(message)" class="row">
                                            <div class="col large">
                                                <div class="form-check">
                                                    <input
                                                        v-model="inboxCheckboxes[pages.indexOf(page)][page.indexOf(message)]"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        :value="true"
                                                    />
                                                    <label class="form-check-label clickable" @click="openModal">
                                                        {{ message.title }}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col small">
                                                <span class="clickable">
                                                    {{ message.sender }}
                                                </span>
                                            </div>
                                            <div class="col small">
                                                {{ message.dateCreated }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col justify-content-between">
                                                <div class="form-check">
                                                    <input
                                                        :checked="allCheckedInbox(pages.indexOf(page))"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        @click="selectAllInbox(pages.indexOf(page))"
                                                    />
                                                    <label class="form-check-label"> Select All </label>
                                                </div>
                                                <button class="btn red-button">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="coalition-page" role="tabpanel" aria-labelledby="coalition-tab">
                            <nav aria-label="coalition-pages">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === 0 }"
                                            @click="changeVisiblePage(currentPage - 1)"
                                            >&larr;</a
                                        >
                                    </li>
                                    <li v-for="page in pages" :key="pages.indexOf(page)" class="page-item">
                                        <a
                                            :class="{ selected: visiblePage[pages.indexOf(page)] }"
                                            class="page-link"
                                            href="#"
                                            @click="changeVisiblePage(pages.indexOf(page))"
                                            >{{ pages.indexOf(page) + 1 }}</a
                                        >
                                    </li>
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === this.pages.length - 1 }"
                                            @click="changeVisiblePage(currentPage + 1)"
                                            >&rarr;</a
                                        >
                                    </li>
                                </ul>
                            </nav>
                            <div v-for="page in pages" :key="pages.indexOf(page)" class="messages-table-wrapper">
                                <Transition name="page-transition">
                                    <div v-if="this.visiblePage[pages.indexOf(page)]" class="messages-table">
                                        <div class="row label">
                                            <div class="col large">Title</div>
                                            <div class="col small">Sender</div>
                                            <div class="col small">Date</div>
                                        </div>
                                        <div v-for="message in page" :key="page.indexOf(message)" class="row">
                                            <div class="col large">
                                                <div class="form-check">
                                                    <input
                                                        v-model="coalitionCheckboxes[pages.indexOf(page)][page.indexOf(message)]"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        :value="true"
                                                    />
                                                    <label class="form-check-label clickable" @click="openModal">
                                                        {{ message.title }}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col small">
                                                <span class="clickable">
                                                    {{ message.sender }}
                                                </span>
                                            </div>
                                            <div class="col small">
                                                {{ message.dateCreated }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col justify-content-between">
                                                <div class="form-check">
                                                    <input
                                                        :checked="allCheckedCoalition(pages.indexOf(page))"
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        @click="selectAllCoalition(pages.indexOf(page))"
                                                    />
                                                    <label class="form-check-label"> Select All </label>
                                                </div>
                                                <button class="btn red-button">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="write-message-page" role="tabpanel" aria-labelledby="write-message-tab">
                            <div class="write-message-wrapper">
                                <div class="row label">
                                    <div class="col small">To:</div>
                                    <div class="col large">
                                        <input class="form-control" />
                                    </div>
                                    <div class="col small">
                                        <div class="flex flex-wrap">
                                            <span class="clickable">Friends</span> | <span class="clickable">Coalition</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row label">
                                    <div class="col small">Title:</div>
                                    <div class="col large">
                                        <input class="form-control" />
                                    </div>
                                    <div class="col small"></div>
                                </div>
                                <div class="row label">
                                    <div class="col">
                                        <div class="markup-icon-wrapper">
                                            <img src="../assets/icons/construction_icon.svg" class="markup-icon" />
                                        </div>
                                        <div class="markup-icon-wrapper">
                                            <img src="../assets/icons/construction_icon.svg" class="markup-icon" />
                                        </div>
                                        <div class="markup-icon-wrapper">
                                            <img src="../assets/icons/construction_icon.svg" class="markup-icon" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <textarea class="write-message-window" />
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center mt-2">
                                <button class="btn yellow-button">Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <opened-message-modal id="opened-message-modal" />
    </div>
</template>

<script>
import OpenedMessageModal from '@/components/OpenedMessageModal';

export default {
    name: 'MessagesModal',
    components: { OpenedMessageModal },
    data() {
        return {
            messagesPerPage: 10,
            messages: [
                {
                    title: 'Lorem ipsum dolor sit amet',
                    sender: 'AllyMama',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Sed ut perspiciatis unde omnis iste natus',
                    sender: 'Chooseas',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Nemo enim ipsam',
                    sender: 'Digypsaw',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Ut enim ad minima veniam',
                    sender: 'GrabsChone',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Quis nostrum exercitationem ullam corporis suscipit laboriosam',
                    sender: 'Laxrad',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Duis aute irure dolor',
                    sender: 'Qicogn',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Quis autem vel eum iure reprehenderit qui in ea voluptate velit',
                    sender: 'Aproma',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Sed pulvinar proin gravida hendrerit',
                    sender: 'ExtraSlash',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Purus in mollis',
                    sender: 'HeavenWanna',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Massa massa ultricies mi quis',
                    sender: 'Markizz',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Sit amet nisl purus',
                    sender: 'Returicra',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Sed faucibus',
                    sender: 'AwayLeak',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Turpis massa',
                    sender: 'Closwa',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Auctor elit sed vulputate mi sit',
                    sender: 'Folioneti',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Nullam non nisi',
                    sender: 'Icebill',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Natoque penatibus et magnis dis parturient',
                    sender: 'MdoggSolomon',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Malesuada fames ac turpis',
                    sender: 'RosesDark',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Viverra ipsum',
                    sender: 'Countrymerg',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'A diam sollicitudin tempor',
                    sender: 'PlatinumSexy',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Porttitor',
                    sender: 'Chattype',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Ac orci phasellus',
                    sender: 'Dianista',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Eu mi bibendum neque egestas',
                    sender: 'Gloryta',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Aliquam ut porttitor',
                    sender: 'KurisuHi',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Placerat duis',
                    sender: 'Vetcham',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Eleifend quam adipiscing vitae',
                    sender: 'Stealmola',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
                {
                    title: 'Velit aliquet sagittis',
                    sender: 'Istingal',
                    dateCreated: 'Yesterday, 14:51:10 PM',
                },
            ],
            visiblePage: [],
            inboxCheckboxes: [],
            coalitionCheckboxes: [],
        };
    },
    computed: {
        pages() {
            let numberOfPages;
            if (this.messages.length % this.messagesPerPage === 0) {
                numberOfPages = this.messages.length / this.messagesPerPage;
            } else {
                numberOfPages = Math.floor(this.messages.length / this.messagesPerPage) + 1;
            }

            let pagesArray = [];
            for (let i = 0; i < numberOfPages; i++) {
                let messageSlice = this.messages.slice(this.messagesPerPage * i, this.messagesPerPage * (i + 1));
                pagesArray.push(messageSlice);
            }

            return pagesArray;
        },
        currentPage() {
            return this.visiblePage.indexOf(this.visiblePage.find(page => page === true));
        },
    },
    created() {
        this.visiblePage = new Array(this.pages.length).fill(false);
        this.visiblePage[0] = true;

        for (let i = 0; i < this.pages.length; i++) {
            let checkboxArray = new Array(this.pages[i].length).fill(false);
            this.inboxCheckboxes.push(JSON.parse(JSON.stringify(checkboxArray)));
            this.coalitionCheckboxes.push(JSON.parse(JSON.stringify(checkboxArray)));
        }

        // for (let i = 0; i < this.pages.length; i++) {
        //   let checkboxArray = new Array(this.pages[i].length).fill(false);
        //   this.coalitionCheckboxes.push(checkboxArray)
        // }
    },
    methods: {
        openModal() {
            document.getElementById('opened-message-modal').style.transform = 'translateY(-20px)';
            document.getElementById('opened-message-modal').style.opacity = '0';
            document.getElementById('opened-message-modal').style.display = 'block';
            document.getElementById('opened-message-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('opened-message-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('opened-message-modal').style.transform = 'translateY(0)';
                document.getElementById('opened-message-modal').style.opacity = '1';
            }, 0);
        },
        closeModal() {
            document.getElementById('messages-modal').style.transition = 'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('messages-modal').style.transform = 'translateY(-20px)';
            document.getElementById('messages-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('messages-modal').style.display = 'none';
                document.getElementById('messages-modal').classList.remove('show');
                document.getElementById('messages-modal').style.removeProperty('transform');
                document.getElementById('messages-modal').style.removeProperty('opacity');
            }, 150);
        },
        changeVisiblePage(index) {
            const currentVisiblePage = this.visiblePage.indexOf(this.visiblePage.find(page => page === true));
            if (currentVisiblePage === index || index < 0 || index >= this.pages.length) {
                return;
            }
            this.visiblePage[currentVisiblePage] = false;
            setTimeout(() => {
                this.visiblePage[index] = true;
            }, 200);
        },
        selectAllInbox(index) {
            if (this.inboxCheckboxes[index].includes(false)) {
                for (let i = 0; i < this.inboxCheckboxes[index].length; i++) {
                    this.inboxCheckboxes[index][i] = true;
                }
            } else {
                for (let i = 0; i < this.inboxCheckboxes[index].length; i++) {
                    this.inboxCheckboxes[index][i] = false;
                }
            }
        },
        selectAllCoalition(index) {
            if (this.coalitionCheckboxes[index].includes(false)) {
                for (let i = 0; i < this.coalitionCheckboxes[index].length; i++) {
                    this.coalitionCheckboxes[index][i] = true;
                }
            } else {
                for (let i = 0; i < this.coalitionCheckboxes[index].length; i++) {
                    this.coalitionCheckboxes[index][i] = false;
                }
            }
        },
        allCheckedInbox(index) {
            return !this.inboxCheckboxes[index].includes(false);
        },
        allCheckedCoalition(index) {
            return !this.coalitionCheckboxes[index].includes(false);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
    font-size: 1.5rem;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 3rem 1rem 1rem 1rem;
}

.nav-tabs {
    border: none;
}

.nav-tabs .nav-link {
    border-radius: 0;
    border: 2px solid #434343;
    border-bottom: none;
    color: #afafaf;
    transform: translateY(15px);
}

.nav-tabs .nav-item {
    margin-right: 0.25rem;
}

.nav-tabs .nav-item:nth-last-child(2) {
    margin-right: 0;
}

.nav-tabs .nav-link:hover {
    color: lightgray;
    border: 2px solid #434343;
    border-bottom: none;
}

.nav-tabs .nav-link.active {
    background: none;
    border: 2px solid #434343;
    border-bottom: none;
    color: ghostwhite;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transform: translateY(-1px);
}

.nav-filler {
    flex-grow: 1;
    border-bottom: 2px solid #434343;
}

.tab-pane {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    background: none;
    border: 2px solid #434343;
    border-top: none;
}

.pagination {
    gap: 2px;
    flex-wrap: wrap;
}

.page-link {
    border: none;
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.25);
    color: ghostwhite;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    border-radius: 0;
}

.page-item:not(:first-child) .page-link {
    margin-left: 0;
}

.page-link:focus {
    box-shadow: none;
    color: ghostwhite;
    background: rgba(255, 255, 255, 0.25);
}

.page-link.selected {
    background: rgba(255, 255, 255, 0.5);
    color: ghostwhite;
}

.page-link.disabled {
    opacity: 0.5 !important;
    pointer-events: none;
}

.page-link:hover {
    color: ghostwhite;
    background: rgba(255, 255, 255, 0.6125);
}

.row {
    margin: 0;
    gap: 2px;
    flex-wrap: nowrap;
}

.label .col {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
}

.large {
    flex-basis: 40%;
}

.small {
    flex-basis: 30%;
}

.write-message-wrapper .large {
    flex-basis: 56%;
}

.write-message-wrapper .small {
    flex-basis: 22%;
}

.btn {
    padding: 0.25rem 1rem;
    width: fit-content;
    height: fit-content;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.form-control {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
    height: 1.5rem;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.form-check-input {
    cursor: pointer;
}

.form-check-input:checked {
    background-color: #d34848;
    border: none;
    background-size: 10px;
}

.form-check-input:focus {
    box-shadow: none;
}

.page-transition-enter-active,
.page-transition-leave-active {
    transition: filter 0.2s linear;
}

.page-transition-enter-from,
.page-transition-leave-to {
    filter: blur(3px);
}

.messages-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.clickable:hover {
    color: cornflowerblue;
    cursor: pointer;
}

.write-message-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.markup-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border: 1px solid #434343;
    margin-right: 0.125rem;
}

.markup-icon {
    height: 1rem;
}

.write-message-window {
    width: 100%;
    background: rgba(0, 0, 0, 0.35);
    padding: 1rem;
    outline: none;
    color: ghostwhite;
    border: 1px solid #434343;
    height: 16rem;
}

@media screen and (max-width: 1199px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 850px) {
    .col,
    .label .col {
        font-size: 0.75rem;
        min-width: 3rem;
        padding: 0.25rem;
    }
    .col {
        gap: 0.25rem;
    }
    .nav-link {
        font-size: 0.875rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .btn {
        font-size: 0.75rem;
        padding: 0.25rem;
    }
    .modal-content {
        padding: 4rem 0 1rem 0;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 96%;
    }
    .building-description {
        width: 100%;
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

@media screen and (max-width: 500px) {
    .nav-tabs .nav-item {
        margin-right: 0.125rem;
    }
    .nav-link {
        font-size: 0.75rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
    .tab-pane {
        padding: 2rem 1rem 1rem 1rem;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
