<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close" @click="closeModal">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                    <div class="w-100">
                        <p>Surrender terms:</p>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault6"
                                id="white-peace"
                                @input="toggleTributeSelected"
                                checked
                            />
                            <label class="form-check-label" for="white-peace">White peace</label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault6"
                                id="tribute"
                                @input="toggleTributeSelected"
                            />
                            <label class="form-check-label" for="tribute">Tribute</label>
                        </div>
                    </div>
                    <div v-if="tributeSelected" class="d-flex justify-content-center mt-5 w-75">
                        <vue-slider v-model="tributeAmount" v-bind="options" />
                    </div>
                    <div class="d-flex justify-content-center gap-3 mt-5">
                        <button
                            class="btn yellow-button"
                            @click="
                                proposePeaceWithCoalitionCommand({
                                    coalition_id: coalitionId,
                                    war_id: warId,
                                    type_of_peace: tributeSelected ? 'tribute' : 'white peace',
                                    tribute: tributeSelected ? tributeAmount : null,
                                })
                            "
                        >
                            Confirm
                        </button>
                        <button class="btn red-button" @click="closeModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import { mapActions } from 'vuex';

export default {
    name: 'EndWarModal',
    components: { VueSlider },
    props: {
        coalitionId: {
            type: String,
            default: '',
        },
        warId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            tributeAmount: 30,
            tributeSelected: false,
            options: {
                dotSize: 14,
                width: '100%',
                height: 4,
                contained: false,
                direction: 'ltr',
                data: null,
                dataLabel: 'label',
                dataValue: 'value',
                min: 30,
                max: 50,
                interval: 1,
                disabled: false,
                clickable: true,
                duration: 0,
                adsorb: true,
                lazy: false,
                tooltip: 'always',
                tooltipPlacement: 'top',
                tooltipFormatter: '{value}%',
                useKeyboard: false,
                keydownHook: null,
                dragOnClick: false,
                enableCross: true,
                fixed: false,
                order: true,
                marks: false,
            },
        };
    },
    created() {
        this.options.value = 30;
    },
    methods: {
        ...mapActions(['proposePeaceWithCoalition']),
        closeModal() {
            document.getElementById('end-war-modal').style.transition = 'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('end-war-modal').style.transform = 'translateY(-20px)';
            document.getElementById('end-war-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('end-war-modal').style.display = 'none';
                document.getElementById('end-war-modal').classList.remove('show');
                document.getElementById('end-war-modal').style.removeProperty('transform');
                document.getElementById('end-war-modal').style.removeProperty('opacity');
            }, 150);
        },
        toggleTributeSelected() {
            this.tributeSelected = !this.tributeSelected;
        },
        async proposePeaceWithCoalitionCommand({ coalition_id, war_id, type_of_peace, tribute }) {
            await this.proposePeaceWithCoalition({ coalition_id, war_id, type_of_peace, tribute });
            this.closeModal();
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 26vh;
    padding: 0;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 3rem 2rem 3rem;
}

.btn {
    padding: 0.25rem 1rem;
    width: fit-content;
    height: fit-content;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.form-check-input {
    cursor: pointer;
}

.form-check-input:checked {
    background-color: #d34848;
    border: none;
    background-size: 10px;
}

.form-check-input:focus {
    box-shadow: none;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 60%;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 75%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 450px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
