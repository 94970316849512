<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="building-image-wrapper">
                        <img
                            :src="
                                require(`../assets/buildings/${
                                    toSnakeCase(building?.name) || 'main_building'
                                }_${getCurrentBuildingAppearance()}_mobile.avif`)
                            "
                            alt=""
                            class="building-image"
                        />
                    </div>
                    <h4 class="modal-title">
                        {{ building?.name }}
                        <span class="title-building-level">Level {{ building?.level }}</span>
                    </h4>
                    <div class="troop-description">
                        {{ building?.description }}
                    </div>
                    <button type="button" class="close" @click="closeModal()">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <h5 class="next-level-requirements">Requirements for next level</h5>
                    <div class="d-flex flex-wrap justify-content-between">
                        <div class="statistics-window">
                            <h6 class="small-header">Resources</h6>
                            <div class="d-flex flex-wrap">
                                <div class="resource-group">
                                    <div class="icon-tooltip">Aluminium</div>
                                    <img src="../assets/icons/aluminum_circle_icon.svg" class="resource-icon" />
                                    <span>{{ building?.aluminum[building?.level] }}</span>
                                </div>
                                <div class="resource-group">
                                    <div class="icon-tooltip">Oil</div>
                                    <img src="../assets/icons/oil_circle_icon.svg" class="resource-icon" />
                                    <span>{{ building?.oil[building?.level] }}</span>
                                </div>
                                <div class="resource-group">
                                    <div class="icon-tooltip">Metal</div>
                                    <img src="../assets/icons/metal_circle_icon.svg" class="resource-icon" />
                                    <span>{{ building?.metal[building?.level] }}</span>
                                </div>
                                <div class="resource-group">
                                    <div class="icon-tooltip">Population</div>
                                    <img src="../assets/icons/population_circle_icon.svg" class="resource-icon" />
                                    <span>{{ building?.population[building?.level] }}</span>
                                </div>
                                <div class="resource-group">
                                    <div class="icon-tooltip">Build Time</div>
                                    <img src="../assets/icons/building_time_icon.svg" class="resource-icon" />
                                    <span>{{ building?.time[building?.level] }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="shouldDisplayRequirements" class="costs-window">
                            <h6 class="small-header">Buildings</h6>
                            <div
                                v-for="(req, req_index) in building?.requirements"
                                :key="req_index"
                                class="requirements-group"
                                :class="{
                                    'd-none': buildingRequirementsMet.requirementsMet?.includes(req.name),
                                }"
                            >
                                <img
                                    :src="
                                        require(`../assets/icons/buildings/${toSnakeCase(req.name)}_mini_${getBuildingAppearance(
                                            req,
                                        )}.avif`)
                                    "
                                    alt=""
                                    class="requirement-icon"
                                    @click="setCurrentBuilding(getBuildingFromRequirementName(req.name))"
                                />
                                <div class="requirement-name-wrapper">
                                    <span
                                        class="requirement-name"
                                        @click="setCurrentBuilding(getBuildingFromRequirementName(req.name))"
                                        >{{ req.name }}</span
                                    >
                                    <span class="requirement-level">Level {{ req.level }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { snakeCase } from 'change-case';

export default {
    name: 'BuildingDetailsModal',
    props: {
        building: {
            type: Object,
            default: () => {},
        },
        buildings: {
            type: Array,
            default: () => [],
        },
        setCurrentBuilding: {
            type: Function,
            default: () => null,
        },
    },
    computed: {
        buildingRequirementsMet() {
            const building = this.building;
            const buildingRequirements = {
                name: building.name,
                requirementsMet: [],
            };
            for (let i = 0; i < building.requirements.length; i++) {
                if (this.checkSingleRequirementIsMet(building.requirements[i])) {
                    buildingRequirements.requirementsMet.push(building.requirements[i].name);
                }
            }
            return buildingRequirements;
        },
        shouldDisplayRequirements() {
            return this.building && this.buildingRequirementsMet?.requirementsMet.length < this.building?.requirements.length;
        },
    },
    methods: {
        checkSingleRequirementIsMet(requirement) {
            const allBuildings = this.buildings;
            const requiredBuilding = allBuildings.find(building => building.name === requirement.name);
            return requiredBuilding?.level >= requirement.level;
        },
        getBuildingAppearance(requirement) {
            const building = this.buildings.filter(bld => bld.name === requirement.name)[0];
            if (building) {
                for (let i = building?.appearance?.length; i >= 0; i--) {
                    if (requirement.level >= building.appearance[i]) {
                        return i + 2;
                    }
                }
            }
            return 1;
        },
        getBuildingFromRequirementName(requirement_name) {
            return this.buildings.find(building => building.name === requirement_name);
        },
        closeModal() {
            document.getElementById('building-details-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('building-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('building-details-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('building-details-modal').style.display = 'none';
                document.getElementById('building-details-modal').classList.remove('show');
                document.getElementById('building-details-modal').style.removeProperty('transform');
                document.getElementById('building-details-modal').style.removeProperty('opacity');
            }, 150);
        },
        toSnakeCase(string) {
            if (string) {
                return snakeCase(string);
            }
        },
        getCurrentBuildingAppearance() {
            if (this.building) {
                for (let i = this.building?.appearance?.length; i >= 0; i--) {
                    if (this.building.level >= this.building.appearance[i]) {
                        return i + 2;
                    }
                }
            }
            return 1;
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header,
.modal-footer {
    border: none;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.modal-title {
    position: relative;
    width: 60%;
    font-style: italic;
    font-family: 'bebas', sans-serif;
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: 1px;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}

.title-building-level {
    display: inline-block;
    font-style: italic;
    font-family: 'bebas', sans-serif;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: burlywood;
}

.troop-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    padding-right: 1rem;
    max-height: 3.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.troop-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.troop-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 12vh;
    padding: 4rem 0;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.building-image-wrapper {
    position: absolute;
    left: 10%;
    height: 7rem;
}

.building-image {
    height: 100%;
    width: auto;
}

.next-level-requirements {
    font-style: italic;
    font-family: 'bebas', sans-serif;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
}

.statistics-window,
.costs-window {
    width: 50%;
    padding: 0.5rem;
}

.small-header {
    font-family: 'bebas', sans-serif;
    font-size: 1rem;
    font-style: italic;
    margin: 0 2rem 1rem 0;
    padding: 0.375rem 0 0.5rem 0;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    color: wheat;
}

.resource-group {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 1rem;
    width: 50%;
    padding: 0.25rem 0.5rem;
    position: relative;
}

.icon-tooltip {
    opacity: 0;
    filter: blur(3px);
    position: absolute;
    top: -1.375rem;
    pointer-events: none;
    transition: opacity 0.3s, filter 0.3s;
    background: black;
    color: ghostwhite;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.25rem;
    border: 1px solid #434343;
}

.resource-group:hover .icon-tooltip {
    opacity: 1;
    filter: blur(0);
}

.resource-icon {
    width: 1.875rem;
}

.requirements-group {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-top: 0.5rem;
}

.requirement-icon {
    filter: grayscale(1) contrast(1.25);
    cursor: pointer;
	height: 2.5rem;
	width: auto;
}

.requirement-name-wrapper {
    display: flex;
    flex-direction: column;
}

.requirement-name {
    font-size: 1rem;
    line-height: 1rem;
    color: lightgray;
    cursor: pointer;
}

.requirement-name:hover {
    color: cornflowerblue;
}

.requirement-level {
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: darkgray;
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
