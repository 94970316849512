<template>
    <div id="confirm-page">
        <div v-if="requestResponse === undefined" class="spinner-border text-light m-4" role="status" />
        <p class="message">
            {{ message }}
        </p>
        <router-link v-if="requestResponse !== undefined" to="/" tag="button">
            <button class="btn return-button">Go to login</button>
        </router-link>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ConfirmPage',
    computed: {
        token() {
            return this.$route.query.token;
        },
        requestResponse() {
            return this.getConfirmAccountResponse();
        },
        message() {
            switch (this.requestResponse) {
                case null:
                    return 'Something went wrong during account confirmation. Please try again.';
                case true:
                    return 'Account confirmed successfully.';
                default:
                    return 'Please wait while we confirm your account.';
            }
        },
    },
    methods: {
        ...mapGetters(['getConfirmAccountResponse']),
        ...mapActions(['verifyAccount']),
    },
    created() {
        this.verifyAccount({ confirmationToken: this.token });
    },
};
</script>

<style scoped>
#confirm-page {
    position: absolute;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.message {
    width: 50%;
    font-family: 'sansation', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    color: ghostwhite;
    text-align: center;
    margin-bottom: 2rem;
}

.return-button {
    font-family: 'bebas', sans-serif;
    width: 10rem;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
}

.return-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}
</style>
