<template>
    <form id="register-form" class="row g-3" novalidate>
        <div class="form-group">
            <label for="email" :class="{ invalid: emailErrors.length !== 0 }" class="form-label">Email:</label>
            <input
                type="text"
                :class="{ invalid: emailErrors.length !== 0 }"
                class="form-control"
                id="email"
                name="email"
                v-model="v$.email.$model"
                @input.prevent="resetErrorList('emailErrors')"
                required
            />
            <p v-for="error of emailErrors" :key="error.uid" class="form-error">
                {{ error.message }}
            </p>
        </div>
        <div class="form-group">
            <label for="password" :class="{ invalid: passwordErrors.length !== 0 }" class="form-label">Password:</label>
            <input
                type="password"
                :class="{ invalid: passwordErrors.length !== 0 }"
                class="form-control"
                name="password"
                id="password"
                v-model="v$.password.$model"
                @input.prevent="resetErrorList('passwordErrors')"
                required
            />
            <p v-for="error of passwordErrors" :key="error.uid" class="form-error">
                {{ error.message }}
            </p>
        </div>
        <div class="form-group">
            <label for="username" :class="{ invalid: usernameErrors.length !== 0 }" class="form-label">Username:</label>
            <input
                type="text"
                :class="{ invalid: usernameErrors.length !== 0 }"
                class="form-control"
                id="username"
                name="username"
                v-model="v$.username.$model"
                @input.prevent="resetErrorList('usernameErrors')"
                required
            />
            <p v-for="error of usernameErrors" :key="error.uid" class="form-error">
                {{ error.message }}
            </p>
        </div>
        <div class="col-12" style="display: flex">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="invalidCheck"
                    name="checkbox"
                    v-model="v$.tandc.$model"
                    @input.prevent="resetErrorList('tandcErrors')"
                    required
                />
                <label :class="{ invalid: tandcErrors.length !== 0 }" class="form-check-label">
                    Agree to
                    <span class="clickable" data-bs-toggle="modal" data-bs-target="#terms-and-conditions-modal"
                        >Terms & Conditions</span
                    >
                </label>
            </div>
        </div>
        <p v-for="error of tandcErrors" :key="error.uid" class="form-error">
            {{ error.message }}
        </p>
        <div class="register-button-wrapper">
            <router-link to="/city" tag="button">
                <button class="btn register-button" v-on:click="storeUserCredentials()">Register</button>
            </router-link>
        </div>
        <div class="login-group">
            <p class="account-question">Already have an account?</p>
            <router-link to="/" tag="button">
                <button class="btn login-button">Authenticate</button>
            </router-link>
        </div>
    </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength, maxLength, alphaNum, helpers } from '@vuelidate/validators';

export default {
    name: 'RegisterForm',
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            email: '',
            password: '',
            username: '',
            tandc: false,
            emailErrors: [],
            usernameErrors: [],
            passwordErrors: [],
            tandcErrors: [],
        };
    },
    validations() {
        return {
            email: {
                required: helpers.withMessage('Email cannot be empty', required),
                email: helpers.withMessage('This email is not valid', email),
                uniqueEmail: helpers.withMessage('This email is already used', this.checkSameEmailInDb),
                $lazy: true,
            },
            username: {
                required: helpers.withMessage('Username cannot be empty', required),
                alphaNum: helpers.withMessage('Username must contain only alphanumeric characters', alphaNum),
                maxLengthValue: helpers.withMessage('Username cannot be longer than 16 characters', maxLength(16)),
                uniqueUsername: helpers.withMessage('This username is already used', this.checkSameUsernameInDb),
                $lazy: true,
            },
            password: {
                required: helpers.withMessage('Password cannot be empty', required),
                minLengthValue: helpers.withMessage('Password cannot be less than 8 characters', minLength(3)),
                maxLengthValue: helpers.withMessage('Password cannot be longer than 32 characters', maxLength(32)),
                $lazy: true,
            },
            tandc: {
                required: helpers.withMessage('You need to agree with terms and conditions', this.checkedTermsAndConditions),
                $lazy: true,
            },
        };
    },
    components: {},
    methods: {
        ...mapActions(['storeCredentials', 'emailAlreadyExists', 'usernameAlreadyExists']),
        ...mapGetters(['getUniqueEmailBool', 'getUniqueUsernameBool', 'getEmail', 'getPassword', 'getUsername']),

        storeUserCredentials() {
            this.emailErrors = [];
            this.usernameErrors = [];
            this.passwordErrors = [];
            this.tandcErrors = [];

            this.emailAlreadyExists({ email: this.email }).then(() => {
                this.usernameAlreadyExists({ username: this.username }).then(() => {
                    this.v$.$touch();

                    if (this.v$.$errors.length) {
                        if (this.v$.email.$errors.length) {
                            for (let err of this.v$.email.$errors) {
                                this.emailErrors.push({ message: err.$message, uid: err.$uid });
                            }
                        }

                        if (this.v$.username.$errors.length) {
                            for (let err of this.v$.username.$errors) {
                                this.usernameErrors.push({
                                    message: err.$message,
                                    uid: err.$uid,
                                });
                            }
                        }

                        if (this.v$.password.$errors.length) {
                            for (let err of this.v$.password.$errors) {
                                this.passwordErrors.push({
                                    message: err.$message,
                                    uid: err.$uid,
                                });
                            }
                        }

                        if (this.v$.tandc.$errors.length) {
                            for (let err of this.v$.tandc.$errors) {
                                this.tandcErrors.push({ message: err.$message, uid: err.$uid });
                            }
                        }
                        return;
                    }
                    this.storeCredentials({
                        email: this.email,
                        password: this.password,
                        username: this.username,
                    });
                });
            });
        },

        checkedTermsAndConditions(val) {
            return val;
        },

        checkSameEmailInDb() {
            return !this.getUniqueEmailBool();
        },

        checkSameUsernameInDb() {
            return !this.getUniqueUsernameBool();
        },

        resetErrorList(field) {
            switch (field) {
                case 'emailErrors':
                    if (this.emailErrors) return (this.emailErrors = []);
                    break;
                case 'passwordErrors':
                    if (this.passwordErrors) return (this.passwordErrors = []);
                    break;
                case 'usernameErrors':
                    if (this.usernameErrors) return (this.usernameErrors = []);
                    break;
                case 'tandcErrors':
                    if (this.tandcErrors) return (this.tandcErrors = []);
                    break;
            }
        },
    },
};
</script>

<style scoped>
#register-form {
    position: relative;
    font-family: 'sansation', sans-serif;
    font-weight: 500;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-label {
    font-size: 1rem;
    font-weight: 500;
    color: whitesmoke;
}

.form-control {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.form-check-label {
    color: ghostwhite;
}

.form-check-input {
    background: none;
    border: 2px solid ghostwhite;
}

.form-error {
    margin: 0.25rem 0;
    color: #c72626;
}

.form-label.invalid {
    color: #c72626;
}

.form-control.invalid {
    border-color: #c72626;
}

.form-check-input.invalid {
    border: 2px solid #c72626;
}

.register-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.login-button {
    font-family: 'bebas', sans-serif;
    width: 10rem;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
}

.login-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.register-button {
    font-family: 'bebas', sans-serif;
    width: 10rem;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
}

.register-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.login-group {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
}

.account-question {
    color: ghostwhite;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.clickable {
    text-decoration: underline;
}

.clickable:hover {
    color: cornflowerblue;
    cursor: pointer;
}
</style>
