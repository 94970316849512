<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Contacts</h4>
                    <div class="building-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="friends-actions-wrapper">
                        <div class="invite-friend-wrapper">
                            <span class="section-description"
                                >Do you have a friend who doesn't play War Battalions?<br />Invite them to support you in your
                                battles!</span
                            >
                            <button class="btn red-button" @click="openModal">Invite</button>
                        </div>
                        <div class="add-friend-wrapper">
                            <span class="section-title">Add Friend</span>
                            <input class="form-control" />
                            <button class="btn yellow-button">Add</button>
                        </div>
                    </div>
                    <div class="current-friends-table">
                        <div class="row">
                            <div class="col label">Friends</div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="friend online">Costy-Fortza</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="friend offline">Lyric</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="friend online">Eike</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="friend online">Kunzang</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="friend online">Goda</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="friend offline">Bích</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="friend offline">Grier</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="friend online">Shams</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <invite-link-modal id="invite-link-modal" />
    </div>
</template>

<script>
import InviteLinkModal from '@/components/InviteLinkModal';

export default {
    name: 'ContactsModal',
    components: { InviteLinkModal },
    methods: {
        openModal() {
            document.getElementById('invite-link-modal').style.transform = 'translateY(-20px)';
            document.getElementById('invite-link-modal').style.opacity = '0';
            document.getElementById('invite-link-modal').style.display = 'block';
            document.getElementById('invite-link-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('invite-link-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('invite-link-modal').style.transform = 'translateY(0)';
                document.getElementById('invite-link-modal').style.opacity = '1';
            }, 0);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2px;
    margin: 0;
    min-height: 2rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding: 0.25rem 0 0.25rem 2rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
}

.col.label {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    font-size: 1rem;
    padding-left: 0.5rem;
}

.form-control {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
    height: 1.875rem;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.btn {
    padding: 0.25rem 1rem;
    width: fit-content;
    height: fit-content;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.friends-actions-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin-bottom: 3rem;
}

.invite-friend-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    position: relative;
}

.add-friend-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    max-width: 50%;
    position: relative;
    height: fit-content;
}

.section-title {
    position: absolute;
    top: -2rem;
    font-size: 1.125rem;
}

.section-description {
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
}

.current-friends-table {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 50%;
}

.friend {
    position: relative;
    width: fit-content;
}

.friend:hover {
    color: cornflowerblue;
    cursor: pointer;
}

.friend:before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    position: absolute;
    left: -1rem;
    top: 50%;
    transform: translateY(-50%);
}

.friend.online:before {
    background: forestgreen;
}

.friend.offline:before {
    background: brown;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 80%;
    }
    .modal-title {
        width: 80%;
    }
    .building-description {
        width: 80%;
    }
}

@media screen and (max-width: 700px) {
    .modal-content {
        padding: 3rem 2rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 96%;
        margin: 0 auto;
    }
    .building-description {
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

@media screen and (max-width: 450px) {
    .modal-title {
        width: 100%;
    }
    .building-description {
        width: 100%;
    }
    .add-friend-wrapper {
        max-width: 100%;
    }
    .section-description {
        font-size: 0.75rem;
    }
    .section-title {
        font-size: 0.875rem;
    }
    .btn {
        font-size: 0.8125rem;
    }
    .current-friends-table {
        width: 100%;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
