<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close" @click="closeModal">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                    <p class="text-center">{{ title }}</p>
                    <div class="d-flex justify-content-center gap-3 mt-5">
                        <button class="btn yellow-button" @click="executeConfirmAction">Confirm</button>
                        <button class="btn red-button" @click="closeModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConfirmationModal',
	props: {
		title: {
			type: String,
			default: 'Are you sure you want to proceed with this action?'
		},
		action: {
			type: Function,
			default: () => {}
		}
	},
    methods: {
		async executeConfirmAction() {
			await this.action();
			this.closeModal()
		},
        closeModal() {
			document.getElementById('confirmation-modal-coalition').style.transition =
				'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
			document.getElementById('confirmation-modal-coalition').style.transform = 'translateY(-20px)';
			document.getElementById('confirmation-modal-coalition').style.opacity = '0';
			setTimeout(() => {
				document.getElementById('confirmation-modal-coalition').style.display = 'none';
				document.getElementById('confirmation-modal-coalition').classList.remove('show');
				document.getElementById('confirmation-modal-coalition').style.removeProperty('transform');
				document.getElementById('confirmation-modal-coalition').style.removeProperty('opacity');
			}, 150);

			document.getElementById('confirmation-modal-commercial-zone').style.transition =
				'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
			document.getElementById('confirmation-modal-commercial-zone').style.transform = 'translateY(-20px)';
			document.getElementById('confirmation-modal-commercial-zone').style.opacity = '0';
			setTimeout(() => {
				document.getElementById('confirmation-modal-commercial-zone').style.display = 'none';
				document.getElementById('confirmation-modal-commercial-zone').classList.remove('show');
				document.getElementById('confirmation-modal-commercial-zone').style.removeProperty('transform');
				document.getElementById('confirmation-modal-commercial-zone').style.removeProperty('opacity');
			}, 150);

            document.getElementById('confirmation-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('confirmation-modal').style.transform = 'translateY(-20px)';
            document.getElementById('confirmation-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('confirmation-modal').style.display = 'none';
                document.getElementById('confirmation-modal').classList.remove('show');
                document.getElementById('confirmation-modal').style.removeProperty('transform');
                document.getElementById('confirmation-modal').style.removeProperty('opacity');
            }, 150);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 26vh;
    padding: 0;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6rem 2rem 2rem 2rem;
}

.btn {
    padding: 0.25rem 1rem;
    width: fit-content;
    height: fit-content;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 60%;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 75%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 450px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
