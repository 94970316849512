<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Ranking</h5>
                    <div class="building-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs" id="ranking-nav" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link active"
                                id="players-ranking"
                                data-bs-toggle="tab"
                                data-bs-target="#players-ranking-page"
                                type="button"
                                role="tab"
                                aria-controls="players-ranking-tab"
                                aria-selected="true"
                            >
                                Players
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="coalition-ranking"
                                data-bs-toggle="tab"
                                data-bs-target="#coalition-ranking-page"
                                type="button"
                                role="tab"
                                aria-controls="coalition-ranking-tab"
                                aria-selected="false"
                            >
                                Coalition
                            </button>
                        </li>
                        <li class="nav-filler" />
                    </ul>
                    <div class="tab-content" id="ranking-pages">
                        <div
                            class="tab-pane fade show active"
                            id="players-ranking-page"
                            role="tabpanel"
                            aria-labelledby="players-ranking-tab"
                        >
                            <nav aria-label="players-ranking-pages">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === 0 }"
                                            @click="changeVisiblePage(currentPage - 1)"
                                            >&larr;</a
                                        >
                                    </li>
                                    <li v-for="page in pages" :key="pages.indexOf(page)" class="page-item">
                                        <a
                                            :class="{ selected: visiblePage[pages.indexOf(page)] }"
                                            class="page-link"
                                            href="#"
                                            @click="changeVisiblePage(pages.indexOf(page))"
                                            >{{ pages.indexOf(page) + 1 }}</a
                                        >
                                    </li>
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === this.pages.length - 1 }"
                                            @click="changeVisiblePage(currentPage + 1)"
                                            >&rarr;</a
                                        >
                                    </li>
                                </ul>
                            </nav>
                            <div v-for="page in pages" :key="pages.indexOf(page)" class="ranking-table-wrapper">
                                <Transition name="page-transition">
                                    <div v-if="this.visiblePage[pages.indexOf(page)]" class="ranking-table">
                                        <div class="row label">
                                            <div class="col small">Place</div>
                                            <div class="col large">Name</div>
                                            <div class="col large">Coalition</div>
                                            <div class="col large">Points</div>
                                            <div class="col small">Cities</div>
                                        </div>
                                        <div v-for="player in page" :key="page.indexOf(player)" class="row">
                                            <div class="col small">
                                                {{ pages.indexOf(page) * playersPerPage + page.indexOf(player) + 1 }}
                                            </div>
                                            <div class="col large">
                                                <span class="clickable">
                                                    {{ player.name }}
                                                </span>
                                            </div>
                                            <div class="col large">
                                                <span class="clickable">
                                                    {{ player.coalition }}
                                                </span>
                                            </div>
                                            <div class="col large">
                                                {{ getRandomInt(1000000, 9000000).toLocaleString('ro') }}
                                            </div>
                                            <div class="col small">
                                                {{ getRandomInt(5, 90) }}
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                            <div class="search-table">
                                <div class="row label">
                                    <div class="col">Search Player</div>
                                </div>
                                <div class="row">
                                    <div class="col">Place</div>
                                    <div class="col">
                                        <input
                                            v-model="playerSearch.place"
                                            class="form-control"
                                            :disabled="playerSearch.name !== ''"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Name</div>
                                    <div class="col">
                                        <input
                                            v-model="playerSearch.name"
                                            class="form-control"
                                            :disabled="playerSearch.place !== ''"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <button class="btn yellow-button">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="coalition-ranking-page"
                            role="tabpanel"
                            aria-labelledby="coalition-ranking-tab"
                        >
                            <nav aria-label="coalition-ranking-pages">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === 0 }"
                                            @click="changeVisiblePage(currentPage - 1)"
                                            >&larr;</a
                                        >
                                    </li>
                                    <li v-for="page in pages" :key="pages.indexOf(page)" class="page-item">
                                        <a
                                            :class="{ selected: visiblePage[pages.indexOf(page)] }"
                                            class="page-link"
                                            href="#"
                                            @click="changeVisiblePage(pages.indexOf(page))"
                                            >{{ pages.indexOf(page) + 1 }}</a
                                        >
                                    </li>
                                    <li class="page-item">
                                        <a
                                            class="page-link"
                                            href="#"
                                            :class="{ disabled: currentPage === this.pages.length - 1 }"
                                            @click="changeVisiblePage(currentPage + 1)"
                                            >&rarr;</a
                                        >
                                    </li>
                                </ul>
                            </nav>
                            <div v-for="page in pages" :key="pages.indexOf(page)" class="ranking-table-wrapper">
                                <Transition name="page-transition">
                                    <div v-if="this.visiblePage[pages.indexOf(page)]" class="ranking-table">
                                        <div class="row label">
                                            <div class="col small">Place</div>
                                            <div class="col large">Name</div>
                                            <div class="col large">Points</div>
                                            <div class="col small">Members</div>
                                            <div class="col small">Cities</div>
                                        </div>
                                        <div v-for="player in page" :key="page.indexOf(player)" class="row">
                                            <div class="col small">
                                                {{ pages.indexOf(page) * playersPerPage + page.indexOf(player) + 1 }}
                                            </div>
                                            <div class="col large">
                                                <span class="clickable">
                                                    {{ player.coalition }}
                                                </span>
                                            </div>
                                            <div class="col large">
                                                {{ getRandomInt(10000000, 90000000).toLocaleString('ro') }}
                                            </div>
                                            <div class="col small">
                                                {{ getRandomInt(5, 90) }}
                                            </div>
                                            <div class="col small">
                                                {{ getRandomInt(100, 900) }}
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                            <div class="search-table">
                                <div class="row label">
                                    <div class="col">Search Coalition</div>
                                </div>
                                <div class="row">
                                    <div class="col">Place</div>
                                    <div class="col">
                                        <input
                                            v-model="coalitionSearch.place"
                                            class="form-control"
                                            :disabled="coalitionSearch.name !== '' || coalitionSearch.abbreviation !== ''"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Name</div>
                                    <div class="col">
                                        <input
                                            v-model="coalitionSearch.name"
                                            class="form-control"
                                            :disabled="coalitionSearch.place !== '' || coalitionSearch.abbreviation !== ''"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Abbreviation</div>
                                    <div class="col">
                                        <input
                                            v-model="coalitionSearch.abbreviation"
                                            class="form-control"
                                            :disabled="coalitionSearch.place !== '' || coalitionSearch.name !== ''"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <button class="btn yellow-button">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RankingModal',
    data() {
        return {
            playersPerPage: 10,
            players: [
                {
                    name: 'AllyMama',
                    coalition: 'Lorem ipsum',
                },
                {
                    name: 'Chooseas',
                    coalition: 'Dolor sit amet',
                },
                {
                    name: 'Digypsaw',
                    coalition: 'Consectetur',
                },
                {
                    name: 'GrabsChone',
                    coalition: 'Adipiscing elit',
                },
                {
                    name: 'Laxrad',
                    coalition: 'Sed do',
                },
                {
                    name: 'Qicogn',
                    coalition: 'Eiusmod',
                },
                {
                    name: 'Aproma',
                    coalition: 'Tempor',
                },
                {
                    name: 'ExtraSlash',
                    coalition: 'Incididunt',
                },
                {
                    name: 'HeavenWanna',
                    coalition: 'Ut labore',
                },
                {
                    name: 'Markizz',
                    coalition: 'Dolore magna',
                },
                {
                    name: 'Returicra',
                    coalition: 'Aliqua',
                },
                {
                    name: 'AwayLeak',
                    coalition: 'Mi in nulla',
                },
                {
                    name: 'Closwa',
                    coalition: 'Posuere',
                },
                {
                    name: 'Folioneti',
                    coalition: 'Sollicitudin',
                },
                {
                    name: 'Icebill',
                    coalition: 'Aliquam',
                },
                {
                    name: 'MdoggSolomon',
                    coalition: 'Ultrices',
                },
                {
                    name: 'RosesDark',
                    coalition: 'Sagittis orci',
                },
                {
                    name: 'Countrymerg',
                    coalition: 'Egestas',
                },
                {
                    name: 'PlatinumSexy',
                    coalition: 'Pretium',
                },
                {
                    name: 'Chattype',
                    coalition: 'Aenean',
                },
                {
                    name: 'Dianista',
                    coalition: 'Pharetra',
                },
                {
                    name: 'Gloryta',
                    coalition: 'Magna ac',
                },
                {
                    name: 'KurisuHi',
                    coalition: 'Dui ut ornare',
                },
                {
                    name: 'Vetcham',
                    coalition: 'Lectus',
                },
                {
                    name: 'Stealmola',
                    coalition: 'Felis',
                },
                {
                    name: 'Istingal',
                    coalition: 'Imperdiet',
                },
            ],
            visiblePage: [],
            playerSearch: {
                place: '',
                name: '',
            },
            coalitionSearch: {
                place: '',
                name: '',
                abbreviation: '',
            },
        };
    },
    computed: {
        pages() {
            let numberOfPages;
            if (this.players.length % this.playersPerPage === 0) {
                numberOfPages = this.players.length / this.playersPerPage;
            } else {
                numberOfPages = Math.floor(this.players.length / this.playersPerPage) + 1;
            }

            let pagesArray = [];
            for (let i = 0; i < numberOfPages; i++) {
                let playerSlice = this.players.slice(this.playersPerPage * i, this.playersPerPage * (i + 1));
                pagesArray.push(playerSlice);
            }

            return pagesArray;
        },
        currentPage() {
            return this.visiblePage.indexOf(this.visiblePage.find(page => page === true));
        },
    },
    created() {
        this.visiblePage = new Array(this.pages.length).fill(false);
        this.visiblePage[0] = true;
    },
    methods: {
        changeVisiblePage(index) {
            const currentVisiblePage = this.visiblePage.indexOf(this.visiblePage.find(page => page === true));
            if (currentVisiblePage === index || index < 0 || index >= this.pages.length) {
                return;
            }
            this.visiblePage[currentVisiblePage] = false;
            setTimeout(() => {
                this.visiblePage[index] = true;
            }, 200);
        },
        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
    font-size: 1.5rem;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 3rem 1rem 1rem 1rem;
}

.nav-tabs {
    border: none;
}

.nav-tabs .nav-link {
    border-radius: 0;
    border: 2px solid #434343;
    border-bottom: none;
    color: #afafaf;
    transform: translateY(15px);
}

.nav-tabs .nav-item {
    margin-right: 0.25rem;
}

.nav-tabs .nav-item:nth-last-child(2) {
    margin-right: 0;
}

.nav-tabs .nav-link:hover {
    color: lightgray;
    border: 2px solid #434343;
    border-bottom: none;
}

.nav-tabs .nav-link.active {
    background: none;
    border: 2px solid #434343;
    border-bottom: none;
    color: ghostwhite;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transform: translateY(-1px);
}

.nav-filler {
    flex-grow: 1;
    border-bottom: 2px solid #434343;
}

.tab-pane {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    background: none;
    border: 2px solid #434343;
    border-top: none;
}

.pagination {
    gap: 2px;
    flex-wrap: wrap;
}

.page-link {
    border: none;
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.25);
    color: ghostwhite;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    border-radius: 0;
}

.page-item:not(:first-child) .page-link {
    margin-left: 0;
}

.page-link:focus {
    box-shadow: none;
    color: ghostwhite;
    background: rgba(255, 255, 255, 0.25);
}

.page-link.selected {
    background: rgba(255, 255, 255, 0.5);
    color: ghostwhite;
}

.page-link.disabled {
    opacity: 0.5 !important;
    pointer-events: none;
}

.page-link:hover {
    color: ghostwhite;
    background: rgba(255, 255, 255, 0.6125);
}

.row {
    margin: 0;
    gap: 2px;
    flex-wrap: nowrap;
}

.label .col {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
    word-break: break-all;
}

.large {
    flex-basis: 24%;
}

.small {
    flex-basis: 14%;
}

#coalition-ranking-page .large {
    flex-basis: 28%;
}

#coalition-ranking-page .small {
    flex-basis: 18%;
}

.btn {
    padding: 0.25rem 1rem;
    width: fit-content;
    height: fit-content;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.form-control {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
    height: 1.5rem;
    transition: opacity 0.3s;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:disabled {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
    opacity: 0.25;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.page-transition-enter-active,
.page-transition-leave-active {
    transition: filter 0.2s linear;
}

.page-transition-enter-from,
.page-transition-leave-to {
    filter: blur(3px);
}

.ranking-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.clickable:hover {
    color: cornflowerblue;
    cursor: pointer;
}

.search-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 50%;
    margin-top: 1.5rem;
}

.search-table .col:not(.label):first-child {
    flex-basis: 30%;
}

.search-table .col:not(.label):not(:first-child) {
    flex-basis: 70%;
}

@media screen and (max-width: 1199px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 850px) {
    .col,
    .label .col {
        font-size: 0.75rem;
        min-width: 2rem;
        padding: 0.25rem;
    }
    .col {
        gap: 0.25rem;
    }
    .nav-link {
        font-size: 0.875rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .btn {
        font-size: 0.75rem;
        padding: 0.25rem;
    }
    .modal-content {
        padding: 4rem 0 1rem 0;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 96%;
    }
    .building-description {
        width: 100%;
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

@media screen and (max-width: 500px) {
    .nav-tabs .nav-item {
        margin-right: 0.125rem;
    }
    .nav-link {
        font-size: 0.75rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
    .tab-pane {
        padding: 2rem 1rem 1rem 1rem;
    }
}

@media screen and (max-width: 450px) {
    .col {
        font-size: 0.625rem;
    }
    .search-table {
        width: 100%;
    }
    #players-ranking-page .ranking-table .col:last-child,
    #coalition-ranking-page .ranking-table .col:last-child {
        display: none;
    }
    .large {
        flex-basis: 30%;
    }
    .small {
        flex-basis: 10%;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
