<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Cartofi Prajiti's City</h4>
                    <button type="button" class="close" aria-label="Close" @click="closeModal">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="minimap-container">
                        <minimap />
                    </div>
                    <div class="city-info-table mt-4">
                        <div class="row">
                            <div class="col label">Coordinates</div>
                            <div class="col">444|360</div>
                        </div>
                        <div class="row">
                            <div class="col label">Points</div>
                            <div class="col">8.533</div>
                        </div>
                        <div class="row">
                            <div class="col label">Player</div>
                            <div class="col">
                                <span class="clickable" @click="closeModal"> Costy-Fortza </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col label">Coalition</div>
                            <div class="col">
                                <span class="clickable" @click="openCoalitionDetailsModal"> Cartofi </span>
                            </div>
                        </div>
                    </div>
                    <div class="actions-table mt-4">
                        <div class="row">
                            <div class="col label">Actions</div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="clickable">
                                    <img src="../assets/icons/current_city_icon.svg" class="profile-icon" />
                                    Center Map
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="clickable" @click="openGeneralsOfficeModal">
                                    <img src="../assets/icons/point_circle_icon.svg" class="profile-icon" />
                                    Send Troops
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="clickable" @click="openCommercialCenterModal">
                                    <img src="../assets/icons/metal_circle_icon.svg" class="profile-icon" />
                                    Send Resources
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="clickable" @click="openConfirmationModal">
                                    <img src="../assets/icons/red_close_icon.svg" class="profile-icon" />
                                    Report City Name
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Minimap from '@/components/Minimap';

export default {
    name: 'CityModal',
    components: { Minimap },
    data() {
        return {};
    },
    methods: {
        openConfirmationModal() {
            document.getElementById('confirmation-modal').style.transform = 'translateY(-20px)';
            document.getElementById('confirmation-modal').style.opacity = '0';
            document.getElementById('confirmation-modal').style.display = 'block';
            document.getElementById('confirmation-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('confirmation-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('confirmation-modal').style.transform = 'translateY(0)';
                document.getElementById('confirmation-modal').style.opacity = '1';
            }, 0);
        },
        openGeneralsOfficeModal() {
            document.getElementById('generals-office-modal').style.transform = 'translateY(-20px)';
            document.getElementById('generals-office-modal').style.opacity = '0';
            document.getElementById('generals-office-modal').style.display = 'block';
            document.getElementById('generals-office-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('generals-office-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('generals-office-modal').style.transform = 'translateY(0)';
                document.getElementById('generals-office-modal').style.opacity = '1';
            }, 0);
        },
        openCommercialCenterModal() {
            // reset all tabs
            document.getElementById('exchange').classList.remove('active');
            document.getElementById('exchange').ariaSelected = 'false';
            document.getElementById('exchange-page').classList.remove('active', 'show');

            document.getElementById('create-offer').classList.remove('active');
            document.getElementById('create-offer').ariaSelected = 'false';
            document.getElementById('create-offer-page').classList.remove('active', 'show');

            document.getElementById('send-resources').classList.remove('active');
            document.getElementById('send-resources').ariaSelected = 'false';
            document.getElementById('send-resources-page').classList.remove('active', 'show');

            document.getElementById('transports').classList.remove('active');
            document.getElementById('transports').ariaSelected = 'false';
            document.getElementById('transports-page').classList.remove('active', 'show');

            // open send-resources tab
            document.getElementById('send-resources').classList.add('active');
            document.getElementById('send-resources').ariaSelected = 'true';
            document.getElementById('send-resources-page').classList.add('active', 'show');

            // open modal
            document.getElementById('commercial-center-modal').style.transform = 'translateY(-20px)';
            document.getElementById('commercial-center-modal').style.opacity = '0';
            document.getElementById('commercial-center-modal').style.display = 'block';
            document.getElementById('commercial-center-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('commercial-center-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('commercial-center-modal').style.transform = 'translateY(0)';
                document.getElementById('commercial-center-modal').style.opacity = '1';
            }, 0);
        },
        closeModal() {
            document.getElementById('city-modal').style.transition = 'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('city-modal').style.transform = 'translateY(-20px)';
            document.getElementById('city-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('city-modal').style.display = 'none';
                document.getElementById('city-modal').classList.remove('show');
                document.getElementById('city-modal').style.removeProperty('transform');
                document.getElementById('city-modal').style.removeProperty('opacity');
            }, 150);
        },
        openCoalitionDetailsModal() {
            document.getElementById('coalition-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('coalition-details-modal').style.opacity = '0';
            document.getElementById('coalition-details-modal').style.display = 'block';
            document.getElementById('coalition-details-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('coalition-details-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('coalition-details-modal').style.transform = 'translateY(0)';
                document.getElementById('coalition-details-modal').style.opacity = '1';
            }, 0);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-dialog {
    max-width: 750px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
    padding: 0;
}

.modal-title {
    width: 100%;
    text-align: center;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 8vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2px;
    margin: 0;
    min-height: 2rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
}

.col.label {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    font-size: 1rem;
}

.clickable:hover {
    color: cornflowerblue;
    cursor: pointer;
}

.minimap-container {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.city-info-table,
.actions-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.city-info-table .col {
    flex-basis: 70%;
}

.city-info-table .col.label {
    flex-basis: 30%;
    font-size: 0.875rem;
    line-height: 0.875rem;
}

.profile-icon {
    height: 0.875rem;
    width: 0.875rem;
    margin-right: 0.25rem;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 74%;
    }
    .modal-content {
        padding: 3rem 1rem;
    }
}

@media screen and (max-width: 700px) {
    .col {
        font-size: 0.75rem;
        min-width: 3rem;
        padding: 0.25rem;
        gap: 0.25rem;
    }
    .col.label {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
    .city-info-table,
    .actions-table {
        width: 90%;
    }
}

@media screen and (max-width: 450px) {
    .modal-content {
        padding: 3rem 0;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
