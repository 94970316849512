export function getBuildingAppearance(building) {
    if (building) {
        for (let i = building?.appearance?.length; i >= 0; i--) {
            if (building.level >= building.appearance[i]) {
                return i + 2;
            }
        }
    }
    return 1;
}

export function getTravelTimeBetweenCities({ first_city_x, first_city_y, second_city_x, second_city_y }) {
    const MINUTES_PER_TILE = 15;
    return Math.sqrt(Math.pow(first_city_x - second_city_x, 2) + Math.pow(first_city_y - second_city_y, 2)) * MINUTES_PER_TILE;
}

export function getTravelTimeInHours({ first_city_x, first_city_y, second_city_x, second_city_y }) {
    return getTravelTimeBetweenCities({ first_city_x, first_city_y, second_city_x, second_city_y }) / 60;
}

export function getTravelTimeInHoursFormatted({ first_city_x, first_city_y, second_city_x, second_city_y }) {
    const RAW_TIME = getTravelTimeBetweenCities({ first_city_x, first_city_y, second_city_x, second_city_y });

    const hours = Math.floor(RAW_TIME / 60);
    const minutes = Math.floor(RAW_TIME % 60);
    const seconds = Math.floor((RAW_TIME % 1) * 60);

    return `${hours ? hours + 'h ' : ''}${minutes ? minutes + 'm ' : ''}${seconds ? seconds + 's' : ''}`;
}
