export const BuildingIdentifier = Object.freeze({
	MAIN_BUILDING: 'Main Building',
	BARRACKS: 'Barracks',
	GENERAL_OFFICE: 'General Office',
	ALUMINUM_SMELTER: 'Aluminum Smelter',
	METAL_MINING_CAMP: 'Metal Mining Camp',
	OIL_EXTRACTOR: 'Oil Extractor',
	ARMORED_FACTORY: 'Armored Factory',
	PLANE_FACTORY: 'Plane Factory',
	AIRPORT: 'Airport',
	STORAGE: 'Storage',
	COMMERCIAL_ZONE: 'Commercial Zone',
	FARM: 'Farm',
	FORTIFICATIONS: 'Fortifications',
});