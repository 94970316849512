<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ effect?.name }}</h4>
                    <button type="button" class="close" @click="closeModal">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <span class="remaining-time"
                        >Effect disappears in <span class="time">{{ remainingTime }}</span></span
                    >
                    <p class="effect-description mt-4">{{ effect?.description }}</p>
                    <div class="effects-table mt-5">
                        <div class="row label">
                            <div class="col">Modified property</div>
                            <div class="col">Amount</div>
                        </div>
                        <div
                            class="row"
                            v-for="(property, property_index) in effect?.influencedProperties"
                            :key="`property-${property}`"
                        >
                            <div class="col">{{ property }}</div>
                            <div class="col">{{ '+' + effect?.ammount[property_index] + '%' }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WarEffectModal',
    props: {
        effect: {
            type: Object,
            default: () => {},
        },
        startTime: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            remainingTime: '',
            intervalId: null,
        };
    },
    watch: {
        startTime: {
            handler(newVal) {
				if (newVal) {
					setTimeout(() => {
						this.remainingTime = this.getRemainingTime(this.startTime);
					}, 10)
					this.intervalId = setInterval(() => {
						this.remainingTime = this.getRemainingTime(this.startTime);
					}, 1000);
				}
            },
            deep: true,
        },
    },
    methods: {
        closeModal() {
            document.getElementById('war-effect-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('war-effect-modal').style.transform = 'translateY(-20px)';
            document.getElementById('war-effect-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('war-effect-modal').style.display = 'none';
                document.getElementById('war-effect-modal').classList.remove('show');
                document.getElementById('war-effect-modal').style.removeProperty('transform');
                document.getElementById('war-effect-modal').style.removeProperty('opacity');
            }, 150);
            clearInterval(this.intervalId);
        },
        getRemainingTime(date_string) {
            // Parse the input string into a Date object
            const dateParts = date_string.split(/[/ :]/);
            // Note: Months are 0-based in JavaScript Date objects, so subtract 1 from the month
            const currentDate = new Date(
                dateParts[2],
                dateParts[1] - 1,
                dateParts[0],
                dateParts[3],
                dateParts[4],
                dateParts[5],
                dateParts[6],
            );

            // Add 24 hours to the dateCreated
            const futureDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);

            // Calculate the time remaining from current time to adjusted time
            const timeRemaining = futureDate.getTime() - Date.now();

            // Convert time remaining to hours, minutes, and seconds
            let hours = Math.floor(timeRemaining / (1000 * 60 * 60));
            let minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

            hours = String(hours).padStart(2, '0');
            minutes = String(minutes).padStart(2, '0');
            seconds = String(seconds).padStart(2, '0');

            return `${hours}:${minutes}:${seconds}`;
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    padding: 0;
    border: none;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 100%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 16vh;
    padding: 4rem 2rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

.remaining-time {
    color: #b7b6b6;
    font-weight: 500;
    font-size: 1rem;
}

.time {
    letter-spacing: 1px;
    color: ghostwhite;
}

.effect-description {
    color: #b7b6b6;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
}

.row {
    flex-wrap: nowrap;
    gap: 2px;
    margin: 0;
}

.label .col {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.col {
    position: relative;
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 2px;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
}

.effects-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.effects-table .col:first-child {
    word-break: break-all;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 60%;
    }
    .modal-content {
        padding: 3rem 2rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 75%;
        margin: 0 auto;
    }
    .effect-description {
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
    .modal-content {
        padding: 3rem 1rem;
    }
}

@media screen and (max-width: 450px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
