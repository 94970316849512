import { createClient } from "graphql-ws";

class Subscriptions {
    constructor() {
        this.client = createClient({
            url: `${process.env.VUE_APP_WS_ADDRESS}`,
        });
    }

    // // subscription
    async subscribeFunc(subscription, action) {
        const onNext = (data) => {
            /* handle incoming values */
            action({ data });
        };

        let unsubscribe = (e) => {
            /* complete the subscription */
            console.error("\nUNSUBSCRIBE BECAUSE OF ERROR: ", e);
        };

        await new Promise((resolve) => {
            unsubscribe = this.client.subscribe(
                {
                    query: subscription
                },
                {
                    next: onNext,
                    error: unsubscribe,
                    complete: resolve,
                },
            );
        });

    }

}

export default new Subscriptions();