<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ building?.name }}</h4>
                    <div class="building-description">
                        {{ building?.description }}
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="resource-table-wrapper">
                        <div class="resource-table-row">
                            <div class="building-level label">Building Level</div>
                            <div class="resource-production label">Production per hour</div>
                            <div class="resource-premium-production label">Production with Premium</div>
                        </div>
                        <div v-for="(production_per_level, index) in building?.perLevel" :key="index" class="resource-table-row">
                            <div class="building-level">{{ index + 1 }}</div>
                            <div class="resource-production">{{ production_per_level.toLocaleString('ro') }}</div>
                            <div class="resource-premium-production">
                                {{ (production_per_level + (production_per_level * 20) / 100).toLocaleString('ro') }}
                            </div>
                        </div>
                    </div>
                    <div class="premium-wrapper">
                        <span class="premium-title">
                            <img src="../assets/icons/premium_icon.svg" class="resource-icon" />
                            Metal production +20%
                        </span>
                        <button class="btn premium-button">Acquire Premium</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ResourceBuildingModal',
    props: {
        building: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

.resource-table-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.resource-table-row {
    display: flex;
    flex-direction: row;
    gap: 2px;
}

.building-level,
.resource-production,
.resource-premium-production {
    padding: 0.5rem;
    width: 33%;
    background: rgba(0, 0, 0, 0.5);
    color: lightgray;
}

.resource-premium-production {
    color: burlywood;
}

.label {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
}

.premium-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    align-items: center;
    margin-top: 2rem;
}

.premium-title {
    font-size: 1rem;
}

.premium-title img {
    height: 1.375rem;
    margin-right: 0.375rem;
}

.premium-button {
    font-family: 'bebas', sans-serif;
    width: 10rem;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
}

.premium-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

@media screen and (max-width: 991px) {
    .modal-content {
        padding: 3rem 1rem;
    }
    .modal-title {
        width: 80%;
    }
    .building-description {
        width: 80%;
    }
}

@media screen and (max-width: 450px) {
    .modal-title {
        width: 100%;
    }
    .building-description {
        width: 100%;
    }
}

@media screen and (max-width: 380px) {
    .resource-table-wrapper {
        font-size: 0.75rem;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
