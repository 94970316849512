export const OFFERS_TEMPLATE = Object.freeze({
    x: 0,
    y: 0,
    repeats: 0,
    maximumTime: 0,
    _id: '',
    fromUser: {
        username: '',
        _id: ''
    },
    fromCity: {
        name: '',
        _id: ''
    },
    resourceToOffer: {
        name: '',
        quantity: 0,
    },
    resourceToGet: {
        name: '',
        quantity: 0,
    },
    dateCreated: '',
});
