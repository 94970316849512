import { CITY_TEMPLATE } from '@/utils/state/templates/cityTemplate';
import { COALITION_TEMPLATE } from '@/utils/state/templates/coalitionTemplate';
import { REPORTS_TEMPLATE } from '@/utils/state/templates/reportsTemplate';
import { CONVERSATIONS_TEMPLATE } from '@/utils/state/templates/conversationsTemplate';
import { arrayWrapper } from '@/utils/functions/templateUtils';

export const GENERAL_TEMPLATE = Object.freeze({
    _id: '',
    username: '',
    email: '',
    cities: arrayWrapper(CITY_TEMPLATE),
    verified: false,
    coalition: COALITION_TEMPLATE,
    reports: REPORTS_TEMPLATE,
    conversations: CONVERSATIONS_TEMPLATE,
    blockedUsers: [],
    blockedByUsers: [],
    friends: [],
    effects: arrayWrapper({
        name: '',
        description: '',
        influencedProperties: [],
        ammount: [],
    }),
    userEvents: arrayWrapper({
        text: '',
        dateCreated: '',
    }),
    requests: arrayWrapper({
        _id: '',
        name: '',
    }),
    // dateCreated: '',
});
