<template>
    <div
        v-dragscroll
        id="world-map-wrapper"
        @scroll="throttle(emitScrollCoords, throttleTimerCoordsEmit, 16)"
        @touchstart="stopCoordsEmit"
        @mousedown="stopCoordsEmit"
		:style="`
			transform: scale(${mapScaleFactor});
			width: calc(${(1 / mapScaleFactor) * 100}vw);
			margin-left: calc(${(100 - (1 / mapScaleFactor) * 100) / 2}vw);
			height: calc(${(1 / mapScaleFactor) * 100}vh);
			margin-top: calc(${(100 - (1 / mapScaleFactor) * 100) / 2}vh);
		`"
    >
        <div
            class="world-map"
            :style="`
				height: ${(496 * ((mapMatrix.length - 1) / 2))}px;
				width: ${(856 * (mapMatrix.length - 1))}px;
			`"
        >
            <!--        <div v-for="(row, row_index) of mapMatrix" :key="row_index" class="map-row">-->
            <!--            <picture v-for="(tile, tile_index) of row" :key="tile_index">-->
            <!--                <source media="(max-width: 799px)" :srcset="require(`../assets/map_tiles/map_${tile.piece}_mobile.avif`)" />-->
            <!--                <source media="(min-width: 800px)" :srcset="require(`../assets/map_tiles/map_${tile.piece}_desktop.avif`)" />-->
            <!--                <img-->
            <!--                    :src="require(`../assets/map_tiles/map_${tile.piece}_desktop.avif`)"-->
            <!--                    class="map-tile"-->
            <!--                    v-html="tile_index + ' / ' + mapMatrix.indexOf(row)"-->
            <!--                    draggable="false"-->
            <!--                />-->
            <!--            </picture>-->
            <!--        </div>-->
            <template v-for="(row, row_index) of mapMatrix" :key="row[0].tile.toString() + row[1].tile.toString()">
                <picture v-for="(tile, tile_index) of row" :key="tile.tile">
                    <source media="(max-width: 799px)" :srcset="require(`../assets/map_tiles/map_${tile.piece}_mobile.avif`)" />
                    <source media="(min-width: 800px)" :srcset="require(`../assets/map_tiles/map_${tile.piece}_desktop.avif`)" />
                    <img
                        :src="require(`../assets/map_tiles/map_${tile.piece}_desktop.avif`)"
                        class="map-tile"
                        v-html="tile_index + ' / ' + mapMatrix.indexOf(row)"
                        draggable="false"
                        :style="`
						top: ${(496 / 2) * row_index - 496 / 2}px;
						left: ${856 * tile_index + (row_index % 2 === 0 ? 856 / 2 : 0) - 856 / 2}px;
					`"
                    />
                </picture>
            </template>
            <div
                v-for="(decoration, index) of decorationsMatrix"
                :key="index"
                class="decoration-tile-wrapper"
                :style="`
					left: ${decoration?.x * 214 + (decoration?.y % 2 === 0 ? 107 : 0)}px;
					top: ${(decoration?.y - 2) * 62}px;
				`"
            >
                <picture>
                    <source
                        media="(max-width: 799px)"
                        :srcset="
                            require(`../assets/map_tiles/${
                                decoration.name === 'Forest'
                                    ? `trees_${decoration.piece + 1 > 5 ? 5 : decoration.piece + 1}_mobile.avif`
                                    : `rock_${decoration.piece + 1 > 4 ? 4 : decoration.piece + 1}_mobile.avif`
                            }`)
                        "
                    />
                    <source
                        media="(min-width: 800px)"
                        :srcset="
                            require(`../assets/map_tiles/${
                                decoration.name === 'Forest'
                                    ? `trees_${decoration.piece + 1 > 5 ? 5 : decoration.piece + 1}_desktop.avif`
                                    : `rock_${decoration.piece + 1 > 4 ? 4 : decoration.piece + 1}_desktop.avif`
                            }`)
                        "
                    />
                    <img
                        :src="
                            require(`../assets/map_tiles/${
                                decoration.name === 'Forest'
                                    ? `trees_${decoration.piece + 1 > 5 ? 5 : decoration.piece + 1}_desktop.avif`
                                    : `rock_${decoration.piece + 1 > 4 ? 4 : decoration.piece + 1}_desktop.avif`
                            }`)
                        "
                        class="decoration-tile"
                        draggable="false"
                    />
                </picture>
            </div>
            <div
                v-for="city of citiesToShowOnMap"
                :key="city._id"
                class="city-tile-wrapper"
                :class="{ 'menu-opened': cityActionsMenuOpened === city._id }"
                :style="`
				left: ${city?.coords.x * 214 + (city?.coords.y % 2 === 0 ? 107 : 0)}px;
				top: ${(city?.coords.y - 2) * 62}px;
            `"
            >
                <span style="position: absolute; top: 0; left: 0; color: white; z-index: 1000">{{ city.coords }}</span>
                <div
                    class="city-clickable-overlay"
                    @click="
                        toggleCityMenuActions(city._id);
                        setTargetCity(city);
                    "
                    @mouseover="toggleCityHoverInfo(city._id)"
                    @mouseleave="toggleCityHoverInfo(null)"
                />
                <picture>
                    <source
                        media="(max-width: 799px)"
                        :srcset="
                            require(`../assets/map_tiles/${
                                city.name === 'Rebel City' ? 'city_destroyed_' : 'city_'
                            }${calculateCityLevel(city.points)}_mobile.avif`)
                        "
                    />
                    <source
                        media="(min-width: 800px)"
                        :srcset="
                            require(`../assets/map_tiles/${
                                city.name === 'Rebel City' ? 'city_destroyed_' : 'city_'
                            }${calculateCityLevel(city.points)}_desktop.avif`)
                        "
                    />
                    <img
                        class="city-tile"
                        :class="{
                            highlighted: cityActionsMenuOpened === city._id || centeredOnCity === city._id,
                        }"
                        :src="
                            require(`../assets/map_tiles/${
                                city.name === 'Rebel City' ? 'city_destroyed_' : 'city_'
                            }${calculateCityLevel(city.points)}_desktop.avif`)
                        "
                        draggable="false"
                    />
                </picture>
                <div
                    class="city-info-wrapper"
                    :style="`
            transform: translateX(-50%) scale(${0.85 / mapScaleFactor});
          `"
                >
                    <span
                        class="city-name"
                        :class="{
                            'city-rebel': city.color === 'Dark Grey',
                            'city-current': city._id === currentCityId,
                            'city-other': city.color === 'White' && city._id !== currentCityId,
                        }"
                        >{{ city.name }}</span
                    >
                    <span class="city-points">{{ city.points }}</span>
                </div>
                <div class="menu-item item-1" data-bs-toggle="modal" data-bs-target="#generals-office-modal">
                    <img src="../assets/icons/map_actions_icon.svg" />
                    <span class="action-title">Send<br />Troops</span>
                </div>
                <div class="menu-item item-2" data-bs-toggle="modal" data-bs-target="#generals-office-modal">
                    <img src="../assets/icons/interface/map_spy_icon.svg" />
                    <span class="action-title">Spy</span>
                </div>
                <div
                    class="menu-item item-3"
                    :class="{ disabled: city.name === 'Rebel City' }"
                    @click="openCommercialCenterModal"
                >
                    <img src="../assets/icons/metal_icon.svg" />
                    <span class="action-title">Send<br />Resources</span>
                </div>
                <div
                    class="menu-item item-4"
                    :class="{ disabled: city.name === 'Rebel City' }"
                    data-bs-toggle="modal"
                    data-bs-target="#profile-modal"
                >
                    <img src="../assets/icons/interface/map_profile_icon.svg" />
                    <span class="action-title">Profile</span>
                </div>
                <div class="menu-item item-5" :class="{ disabled: city.name === 'Rebel City' }" @click="openMessageModal">
                    <img src="../assets/icons/interface/map_message_icon.svg" />
                    <span class="action-title">Message</span>
                </div>
                <Transition name="tooltip-transition">
                    <div
                        v-if="cityHoverInfoVisible === city._id && cityActionsMenuOpened !== city._id"
                        class="city-hover-info-wrapper"
                        :style="`
              transform: scale(${0.85 / mapScaleFactor});
              left: ${60 * (0.85 / mapScaleFactor)}%;
              top: ${60 * (0.85 / mapScaleFactor)}%;
            `"
                    >
                        <div class="hover-info-group">
                            <img src="../assets/icons/city_icon.svg" class="hover-info-icon" />
                            <div class="hover-info-text">
                                <span class="hover-info-title"> City </span>
                                <div class="hover-info-details">
                                    <span class="hover-info-name">Miserupia (554|231)</span>
                                    <span class="hover-info-points">12.775</span>
                                </div>
                            </div>
                        </div>
                        <div class="hover-info-group">
                            <img src="../assets/icons/population_icon.svg" class="hover-info-icon" />
                            <div class="hover-info-text">
                                <span class="hover-info-title"> Player </span>
                                <div class="hover-info-details">
                                    <span class="hover-info-name">Costy-Fortza</span>
                                    <span class="hover-info-points">903.398</span>
                                </div>
                            </div>
                        </div>
                        <div class="hover-info-group">
                            <img src="../assets/icons/alliance_icon.svg" class="hover-info-icon" />
                            <div class="hover-info-text">
                                <span class="hover-info-title"> Coalition </span>
                                <div class="hover-info-details">
                                    <span class="hover-info-name">Cartofi (Crt)</span>
                                    <span class="hover-info-points">15.902.370</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
            <div
                v-for="(arrows_group, arrows_group_index) in mapActionsGroupedByCityPair"
                :key="arrows_group_index"
                class="arrows-wrapper"
                :style="`
        left: ${calculateCityCenterX(arrows_group.coords[0].x, arrows_group.coords[0].y)}px;
        top: ${calculateCityCenterY(arrows_group.coords[0].x, arrows_group.coords[0].y) - 62}px;
        width: ${calculateDistanceBetweenCities(
            calculateCityCenterX(arrows_group.coords[0].x, arrows_group.coords[0].y),
            calculateCityCenterY(arrows_group.coords[0].x, arrows_group.coords[0].y),
            calculateCityCenterX(arrows_group.coords[1].x, arrows_group.coords[1].y),
            calculateCityCenterY(arrows_group.coords[1].x, arrows_group.coords[1].y),
        )}px;
        transform: rotateZ(${calculateAngleBetweenCities(
            calculateCityCenterX(arrows_group.coords[0].x, arrows_group.coords[0].y),
            calculateCityCenterY(arrows_group.coords[0].x, arrows_group.coords[0].y),
            calculateCityCenterX(arrows_group.coords[1].x, arrows_group.coords[1].y),
            calculateCityCenterY(arrows_group.coords[1].x, arrows_group.coords[1].y),
        )}deg)
      `"
            >
                <div
                    v-for="(arrow, arrow_index) in arrows_group.uniqueDirections"
                    :key="arrow_index"
                    :class="{
                        'arrow-reversed':
                            (!arrow.isOwnAction && arrows_group.cities[0] === currentCityId) ||
                            (arrow.isOwnAction && arrows_group.cities[0] !== currentCityId),
                        'd-none': !arrow.troopMovements.some(movement => movement.percentageDone < 100),
                    }"
                    class="arrow"
                >
                    <div class="arrow-tail" />
                    <div class="arrow-body" />
                    <div class="arrow-head" />
                    <div
                        v-for="(marker, marker_index) in arrow.troopMovements"
                        :key="marker_index"
                        src="../assets/icons/current_city_icon.svg"
                        class="action-progress-indicator-wrapper"
                        :style="`
          left: ${marker.action === 'intercept' ? 100 - marker.percentageDone : marker.percentageDone}%;
          transform: rotateZ(${
              ((!arrow.isOwnAction && arrows_group.cities[0] === currentCityId) ||
              (arrow.isOwnAction && arrows_group.cities[0] !== currentCityId)
                  ? 180
                  : 0) -
              calculateAngleBetweenCities(
                  calculateCityCenterX(arrows_group.coords[0].x, arrows_group.coords[0].y),
                  calculateCityCenterY(arrows_group.coords[0].x, arrows_group.coords[0].y),
                  calculateCityCenterX(arrows_group.coords[1].x, arrows_group.coords[1].y),
                  calculateCityCenterY(arrows_group.coords[1].x, arrows_group.coords[1].y),
              )
          }deg) translateY(-${
                            arrows_group.coords[0].y < arrows_group.coords[1].y &&
                            ((!arrow.isOwnAction && arrows_group.cities[0] === currentCityId) ||
                                (arrow.isOwnAction && arrows_group.cities[0] !== currentCityId))
                                ? 20
                                : arrows_group.coords[0].y > arrows_group.coords[1].y &&
                                  ((!arrow.isOwnAction && arrows_group.cities[0] === currentCityId) ||
                                      (arrow.isOwnAction && arrows_group.cities[0] !== currentCityId))
                                ? 20
                                : 95
                        }%)
        `"
                    >
                        <Transition name="tooltip-transition">
                            <div v-if="indicatorSelectPanelVisible === marker.id" class="indicator-select-panel">
                                <div
                                    class="panel-indicator-wrapper"
                                    v-for="(indicator, indicator_index) in stackedIndicatorGroups.find(group =>
                                        group.find(m => m.id === marker.id),
                                    )"
                                    :key="indicator_index"
                                    @click="toggleTroopInfoPanel(indicator.id)"
                                    @mouseover="selectIndicator(indicator.id)"
                                    @mouseleave="deselectIndicator(indicator.id)"
                                >
                                    <img
                                        src="../assets/icons/current_city_icon.svg"
                                        class="panel-indicator"
                                        :class="{
                                            'indicator-red': indicator.action === 'attack',
                                            'indicator-black':
                                                indicator.action === 'attack' &&
                                                indicator.troops.find(troop => troop.troopsName.includes('General')),
                                            'indicator-purple': indicator.action === 'returning_attack',
                                            'indicator-blue': indicator.action === 'reinforcement',
                                            'indicator-lightblue': indicator.action === 'returning_reinforcement',
                                            'indicator-orange': indicator.action === 'spy_mission',
                                            'indicator-yellow': indicator.action === 'returning_spies',
                                            'indicator-brown': indicator.action === 'intercept',
                                            'indicator-gray': indicator.action === 'returning_intercept',
                                            // 'indicator-green': indicator.action === 'trading_transport',
                                            // 'indicator-dark-green': indicator.action === 'send_resources_transport',
                                            'd-none':
                                                indicator.action === 'intercept'
                                                    ? indicator.percentageDone >= indicator.interceptMeetingPoint
                                                    : indicator.percentageDone >= 100,
                                        }"
                                    />
                                    <span class="city-name-and-coords">
                                        {{ getCityNameFromId(indicator.targetId) }}
                                        <span>( {{ indicator.targetCoords.x }} | {{ indicator.targetCoords.y }} )</span>
                                    </span>
                                </div>
                            </div>
                        </Transition>
                        <Transition name="tooltip-transition">
                            <div v-if="troopInfoPanelVisible === marker.id" class="troop-info-hover-panel">
                                <div class="troop-info-rows-wrapper">
                                    <div
                                        v-for="(troop_name, troop_index) in Object.keys(calculateTroopsToShow(marker.troops))"
                                        :key="troop_index"
                                        class="troop-info-row"
                                    >
                                        <div class="troop-image-wrapper">
                                            <img
                                                :src="require(`../assets/icons/troops/${toKebabCase(troop_name)}-tall.avif`)"
                                                class="troop-image"
                                            />
                                        </div>
                                        <span class="troop-number">
                                            {{ calculateTroopsToShow(marker.troops)[troop_name] }}
                                        </span>
                                    </div>
                                </div>
                                <span
                                    v-if="marker.action !== 'intercept'"
                                    class="go-to-target-button"
                                    @click="scrollToCity(marker.targetId, marker.targetCoords, 'smooth')"
                                    @touchstart.stop
                                    @mousedown.stop
                                >
                                    {{ getCityNameFromId(marker.targetId) }} ( {{ marker.targetCoords.x }} |
                                    {{ marker.targetCoords.y }} )
                                </span>
                            </div>
                        </Transition>
                        <img
                            :id="marker.id"
                            src="../assets/icons/current_city_icon.svg"
                            class="action-progress-indicator"
                            :class="{
                                'indicator-red': marker.action === 'attack',
                                'indicator-black':
                                    marker.action === 'attack' &&
                                    marker.troops.find(troop => troop.troopsName.includes('General')),
                                'indicator-purple': marker.action === 'returning_attack',
                                'indicator-blue': marker.action === 'reinforcement',
                                'indicator-lightblue': marker.action === 'returning_reinforcement',
                                'indicator-orange': marker.action === 'spy_mission',
                                'indicator-yellow': marker.action === 'returning_spies',
                                'indicator-brown': marker.action === 'intercept',
                                'indicator-gray': marker.action === 'returning_intercept',
                                // 'indicator-green': marker.action === 'trading_transport',
                                // 'indicator-dark-green': marker.action === 'send_resources_transport',
                                'd-none':
                                    marker.action === 'intercept'
                                        ? marker.percentageDone >= marker.interceptMeetingPoint
                                        : marker.percentageDone >= 100,
                            }"
                            @click="openTroopPanelOrIndicatorSelectPanel(marker.id)"
                            @mouseover="selectEntireGroup(marker.id)"
                            @mouseleave="deselectEntireGroup(marker.id)"
                        />
                    </div>
                    <div
                        v-for="(marker, marker_index) in arrow.troopMovements.filter(action => action.action === 'intercept')"
                        :key="marker_index"
                        class="intercept-meeting-point"
                        :class="{
                            'd-none': marker.percentageDone >= marker.interceptMeetingPoint,
                        }"
                        :style="`
                    left: ${100 - marker.interceptMeetingPoint}%;
					`"
                    >
                        &#10006;
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { paramCase } from 'change-case';

export default {
    name: 'WorldMap',
    props: {
        allCities: {
            type: Array,
            default: () => [],
        },
        attacks: {
            type: Array,
            default: () => [],
        },
        returningAttacks: {
            type: Array,
            default: () => [],
        },
        reinforcements: {
            type: Array,
            default: () => [],
        },
        returningReinforcements: {
            type: Array,
            default: () => [],
        },
        spyMissions: {
            type: Array,
            default: () => [],
        },
        returningSpies: {
            type: Array,
            default: () => [],
        },
        intercepts: {
            type: Array,
            default: () => [],
        },
        returningIntercepts: {
            type: Array,
            default: () => [],
        },
        tradingTransports: {
            type: Array,
            default: () => [],
        },
        returningTradingTransports: {
            type: Array,
            default: () => [],
        },
        sendResourcesTransports: {
            type: Array,
            default: () => [],
        },
        returningSendResourcesTransports: {
            type: Array,
            default: () => [],
        },
        mapScaleFactor: {
            type: Number,
            default: 1,
        },
        isMapCentered: {
            type: Boolean,
            default: false,
        },
        worldMapScrollCoordsFromMinimap: {
            type: Object,
            default: () => {},
        },
        currentCityId: {
            type: String,
            default: '',
        },
        currentCityCoords: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            mapMatrix: [],
            decorationsMatrix: [],
            citiesToShowOnMap: [],
            cityActionsMenuOpened: null,
            cityHoverInfoVisible: null,
            indicatorSelectPanelVisible: null,
            troopInfoPanelVisible: null,
            centeredOnCity: null,
            worldMap: null,
            windowSize: {
                width: window.innerWidth,
                height: window.innerHeight,
            },
            mapActionsGroupedByCityPair: [],
            actionPercentageDone: 0,
            actionProgressInterval: null,
            coordsEmitStopped: false,
            progressIndicatorUpdateInterval: 5000,
            // throttle timers are objects so that they can be passed by reference
            // to throttle function, rather than by value
            throttleTimerCoordsEmit: {
                timer: null,
            },
            throttleTimerCityVisibility: {
                timer: null,
            },
            cityLevels: [
                [0, 500],
                [501, 1500],
                [1501, 2500],
                [2501, 9999999999],
            ],
            indicatorArea: 1600, // bounding box area of the action indicator icon, based on a width and height of 40px
            intersectionThreshold: 0.5, // threshold for determining if two indicators are stacked
            allIndicators: [],
            stackedIndicatorGroups: [],
        };
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
        // window.removeEventListener("mousedown", this.stopCoordsEmit);
        // window.removeEventListener("mouseup", this.allowCoordsEmit);
        clearInterval(this.actionProgressInterval);
    },
    created() {
        this.mapMatrix = this.getMapMatrix();
        this.decorationsMatrix = this.getDecorationsMatrix();
        this.mapActionsGroupedByCityPair = this.groupActionsByCityPair();
        this.$nextTick(() => {
            this.worldMap = document.getElementById('world-map-wrapper');
        });

        this.startProgressIndicatorUpdate();

        window.addEventListener('resize', this.onResize);
        // window.addEventListener("mousedown", this.stopCoordsEmit);
        // window.addEventListener("mouseup", this.allowCoordsEmit);
        this.$nextTick(() => {
            this.scrollToCity(this.currentCityId, this.currentCityCoords, 'instant');
        });
    },
    watch: {
        mapScaleFactor: {
            handler() {
                this.getVisibleCities();
                // stop view from sliding when zooming out on map border
                this.stopCoordsEmit();
                this.allowCoordsEmit();
            },
        },
        isMapCentered: {
            handler(isCentered) {
                if (isCentered) {
                    this.scrollToCity(this.currentCityId, this.currentCityCoords, 'smooth');
                }
            },
            deep: true,
        },
        worldMapScrollCoordsFromMinimap: {
            handler() {
                if (!this.coordsEmitStopped) {
                    const worldMap = document.getElementById('world-map-wrapper');
                    if (worldMap !== undefined) {
                        worldMap.scrollLeft = this.worldMapScrollCoordsFromMinimap.x;
                        worldMap.scrollTop = this.worldMapScrollCoordsFromMinimap.y;
                    }
                }
            },
            deep: true,
        },
        // 'attacks.length': {
        //     handler(newVal, oldVal) {
        //         this.mapActionsGroupedByCityPair = this.groupActionsByCityPair();
        //         if (
        //             !oldVal &&
        //             !this.returningAttacks.length &&
        //             !this.reinforcements.length &&
        //             !this.returningReinforcements.length &&
        //             !this.spyMissions.length &&
        //             !this.returningSpies.length &&
        //             !this.returningIntercepts.length
        //         ) {
        //             this.startProgressIndicatorUpdate();
        //         } else {
        //             clearInterval(this.actionProgressInterval);
        //             this.startProgressIndicatorUpdate();
        //         }
        //     },
        // },
        // 'returningAttacks.length': {
        //     handler(newVal, oldVal) {
        //         this.mapActionsGroupedByCityPair = this.groupActionsByCityPair();
        //         if (
        //             !oldVal &&
        //             !this.attacks.length &&
        //             !this.reinforcements.length &&
        //             !this.returningReinforcements.length &&
        //             !this.spyMissions.length &&
        //             !this.returningSpies.length &&
        //             !this.returningIntercepts.length
        //         ) {
        //             this.startProgressIndicatorUpdate();
        //         } else {
        //             clearInterval(this.actionProgressInterval);
        //             this.startProgressIndicatorUpdate();
        //         }
        //     },
        // },
        // 'reinforcements.length': {
        //     handler(newVal, oldVal) {
        //         this.mapActionsGroupedByCityPair = this.groupActionsByCityPair();
        //         if (
        //             !oldVal &&
        //             !this.attacks.length &&
        //             !this.returningAttacks.length &&
        //             !this.returningReinforcements.length &&
        //             !this.spyMissions.length &&
        //             !this.returningSpies.length &&
        //             !this.returningIntercepts.length
        //         ) {
        //             this.startProgressIndicatorUpdate();
        //         } else {
        //             clearInterval(this.actionProgressInterval);
        //             this.startProgressIndicatorUpdate();
        //         }
        //     },
        // },
        // 'returningReinforcements.length': {
        //     handler(newVal, oldVal) {
        //         this.mapActionsGroupedByCityPair = this.groupActionsByCityPair();
        //         if (
        //             !oldVal &&
        //             !this.attacks.length &&
        //             !this.reinforcements.length &&
        //             !this.returningAttacks.length &&
        //             !this.spyMissions.length &&
        //             !this.returningSpies.length &&
        //             !this.returningIntercepts.length
        //         ) {
        //             this.startProgressIndicatorUpdate();
        //         } else {
        //             clearInterval(this.actionProgressInterval);
        //             this.startProgressIndicatorUpdate();
        //         }
        //     },
        // },
        // 'spyMissions.length': {
        //     handler(newVal, oldVal) {
        //         this.mapActionsGroupedByCityPair = this.groupActionsByCityPair();
        //         if (
        //             !oldVal &&
        //             !this.attacks.length &&
        //             !this.returningAttacks.length &&
        //             !this.reinforcements.length &&
        //             !this.returningReinforcements.length &&
        //             !this.returningSpies.length &&
        //             !this.returningIntercepts.length
        //         ) {
        //             this.startProgressIndicatorUpdate();
        //         } else {
        //             clearInterval(this.actionProgressInterval);
        //             this.startProgressIndicatorUpdate();
        //         }
        //     },
        // },
        // 'returningSpies.length': {
        //     handler(newVal, oldVal) {
        //         this.mapActionsGroupedByCityPair = this.groupActionsByCityPair();
        //         if (
        //             !oldVal &&
        //             !this.attacks.length &&
        //             !this.returningAttacks.length &&
        //             !this.reinforcements.length &&
        //             !this.returningReinforcements.length &&
        //             !this.spyMissions.length &&
        //             !this.returningIntercepts.length
        //         ) {
        //             this.startProgressIndicatorUpdate();
        //         } else {
        //             clearInterval(this.actionProgressInterval);
        //             this.startProgressIndicatorUpdate();
        //         }
        //     },
        // },
        // 'returningIntercepts.length': {
        //     handler(newVal, oldVal) {
        //         this.mapActionsGroupedByCityPair = this.groupActionsByCityPair();
        //         if (
        //             !oldVal &&
        //             !this.attacks.length &&
        //             !this.returningAttacks.length &&
        //             !this.reinforcements.length &&
        //             !this.returningReinforcements.length &&
        //             !this.spyMissions.length &&
        //             !this.returningSpies.length
        //         ) {
        //             this.startProgressIndicatorUpdate();
        //         } else {
        //             clearInterval(this.actionProgressInterval);
        //             this.startProgressIndicatorUpdate();
        //         }
        //     },
        // },
        'allActions.length': {
            handler(newVal, oldVal) {
                if (newVal > oldVal) {
                    clearInterval(this.actionProgressInterval);
                    this.startProgressIndicatorUpdate();
                }
            },
        },
        mapActionsGroupedByCityPair: {
            handler() {
                this.allIndicators = this.getAllIndicators();
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions(['getMap', 'getAllUsers']),
        ...mapGetters(['getMapMatrix', 'getDecorationsMatrix']),

        selectIndicator(action_id) {
            document.getElementById(action_id).classList.add('hovered');
        },

        deselectIndicator(action_id) {
            document.getElementById(action_id).classList.remove('hovered');
        },

        selectEntireGroup(action_id) {
            const GROUP = this.stackedIndicatorGroups.find(group => group.find(action => action.id === action_id));
            GROUP.forEach(indicator => this.selectIndicator(indicator.id));
        },

        deselectEntireGroup(action_id) {
            const GROUP = this.stackedIndicatorGroups.find(group => group.find(action => action.id === action_id));
            GROUP.forEach(indicator => this.deselectIndicator(indicator.id));
        },

        getAllIndicators() {
            const ALL_INDICATORS = [];

            this.mapActionsGroupedByCityPair.forEach(city_pair => {
                city_pair.uniqueDirections.forEach(unique_direction => {
                    unique_direction.troopMovements.forEach(troop_movement => {
                        ALL_INDICATORS.push(troop_movement);
                    });
                });
            });
            return ALL_INDICATORS;
        },

        getStackedIndicatorGroups() {
            const STACKED_INDICATOR_GROUPS = [];

            // Iterate over each indicator
            for (let i = 0; i < this.allIndicators.length; i++) {
                let indicatorStacked = false;

                // Iterate over each group of stacked indicators
                for (let j = 0; j < STACKED_INDICATOR_GROUPS.length; j++) {
                    // Iterate over each indicator in the current group
                    for (let k = 0; k < STACKED_INDICATOR_GROUPS[j].length; k++) {
                        // Check if the current indicator is stacked with any indicator in the current group
                        if (this.areIndicatorsStacked(this.allIndicators[i].id, STACKED_INDICATOR_GROUPS[j][k].id)) {
                            // If stacked, add the current indicator to the group and set indicatorStacked to true
                            STACKED_INDICATOR_GROUPS[j].push(this.allIndicators[i]);
                            indicatorStacked = true;
                            break;
                        }
                    }
                    // If the current indicator is stacked with any indicator in the current group,
                    // no need to check further groups
                    if (indicatorStacked) {
                        break;
                    }
                }

                // If the current indicator is not stacked with any existing group, create a new group for it
                if (!indicatorStacked) {
                    STACKED_INDICATOR_GROUPS.push([this.allIndicators[i]]);
                }
            }

            this.stackedIndicatorGroups = STACKED_INDICATOR_GROUPS;
        },

        areIndicatorsStacked(element1, element2) {
            const rect1 = document.getElementById(element1).getBoundingClientRect();
            const rect2 = document.getElementById(element2).getBoundingClientRect();

            // Calculate the overlap area
            const overlapWidth = Math.min(rect1.right, rect2.right) - Math.max(rect1.left, rect2.left);
            const overlapHeight = Math.min(rect1.bottom, rect2.bottom) - Math.max(rect1.top, rect2.top);
            const overlapArea = Math.max(0, overlapWidth) * Math.max(0, overlapHeight);

            // Check if the overlap area exceeds the threshold
            return overlapArea >= this.intersectionThreshold * this.indicatorArea;
        },

        // to be used temporarily
        getCityNameFromId(city_id) {
            if (!city_id) {
                return '';
            }
            return this.allCities.length ? this.allCities.find(city => city._id === city_id)?.name : '';
        },

        calculateTroopsToShow(troops_array) {
            const TROOPS = {};
            troops_array.forEach(array => {
                array.troopsName.forEach((troop, index) => {
                    if (TROOPS[troop]) {
                        TROOPS[troop] += array.numOfTroops[index];
                    } else {
                        TROOPS[troop] = array.numOfTroops[index];
                    }
                });
            });

            return TROOPS;
        },

        toKebabCase(string) {
            return paramCase(string);
        },

        calculateCityLevel(city_points) {
            return this.cityLevels.findIndex(range => city_points >= range[0] && city_points <= range[1]) + 1;
        },

        isCityVisible(city) {
            const visibleHorizontally =
                this.worldMap.scrollLeft + 214 < city.coords.x * 214 + (city.coords.y % 2 === 0 ? 107 : 0) &&
                city.coords.x * 214 + (city.coords.y % 2 === 0 ? 107 : 0) <
                    this.worldMap.scrollLeft + 428 + this.windowSize.width / this.mapScaleFactor;
            const visibleVertically =
                this.worldMap.scrollTop < city.coords.y * 62 &&
                city.coords.y * 62 < this.worldMap.scrollTop + (this.windowSize.height + 156) / this.mapScaleFactor;
            return visibleHorizontally && visibleVertically;
        },

        getVisibleCities() {
            this.citiesToShowOnMap = this.allCities.filter(city => this.isCityVisible(city));
        },

        throttle(func, timer, delay) {
            if (timer.timer === null) {
                func();
                timer.timer = setTimeout(() => {
                    timer.timer = null;
                }, delay);
            }
        },

        convertDateStringToMiliseconds(date_string) {
            // !!! remove this exception when data arrives in correct form
            if (date_string.split('/').length === 1) {
                return parseInt(date_string);
            }

            const [datePart, timePart] = date_string.split(' ');
            const [day, month, year] = datePart.split('/');
            const [hour, minute, second, millisecond] = timePart.split(':');

            const dateObject = new Date(year, month - 1, day, hour, minute, second, millisecond);
            return dateObject.getTime();
        },

        scrollToCity(city_id, coords, behavior) {
            if (behavior === 'smooth') {
                this.centeredOnCity = city_id;
                setTimeout(() => {
                    this.centeredOnCity = null;
                }, 2000);
            }
            this.stopCoordsEmit();
            document.getElementById('world-map-wrapper').scrollTo({
                left: this.calculateCityCenterX(coords.x, coords.y) - (this.windowSize.width / this.mapScaleFactor + 856) / 2,
                top: this.calculateCityCenterY(coords.x, coords.y) - (this.windowSize.height / this.mapScaleFactor + 124) / 2,
                behavior: behavior,
            });
            setTimeout(() => {
                this.allowCoordsEmit();
            }, 1000);
        },

        calculateCityCenterX(x, y) {
            return x * 214 + (y % 2 === 0 ? 214 : 107);
        },

        calculateCityCenterY(x, y) {
            return y * 62;
        },

        calculateDistanceBetweenCities(currentX, currentY, targetX, targetY) {
            return Math.sqrt((targetX - currentX) ** 2 + (targetY - currentY) ** 2);
        },

        calculateAngleBetweenCities(currentX, currentY, targetX, targetY) {
            return Math.atan2(targetY - currentY, targetX - currentX) * (180 / Math.PI);
        },

        stopCoordsEmit() {
            this.coordsEmitStopped = true;
            window.addEventListener('mouseup', this.allowCoordsEmit);
            window.addEventListener('touchend', this.allowCoordsEmit);
        },

        allowCoordsEmit() {
            setTimeout(() => {
                this.coordsEmitStopped = false;
                window.removeEventListener('mouseup', this.allowCoordsEmit);
                window.removeEventListener('touchend', this.allowCoordsEmit);
            }, 100);
        },

        startProgressIndicatorUpdate() {
            let now = new Date().getTime() - 3600000;
            this.$nextTick(() => {
                this.calculateActionProgress(now);
                this.getStackedIndicatorGroups();
            });
            this.actionProgressInterval = setInterval(() => {
                now = new Date().getTime() - 3600000;
                this.calculateActionProgress(now);
                if (!this.mapActionsGroupedByCityPair.length) {
                    clearInterval(this.actionProgressInterval);
                }
            }, this.progressIndicatorUpdateInterval);
        },

        calculateActionProgress(now) {
            this.getStackedIndicatorGroups();
            for (let i = 0; i < this.mapActionsGroupedByCityPair.length; i++) {
                const uniqueDirections = this.mapActionsGroupedByCityPair[i].uniqueDirections;
                for (let j = 0; j < uniqueDirections.length; j++) {
                    const troopMovements = uniqueDirections[j].troopMovements;
                    for (let k = 0; k < troopMovements.length; k++) {
                        const currentAction = troopMovements[k];
                        if (currentAction.percentageDone < 100) {
                            const actionDuration = currentAction.end - currentAction.start;
                            const timeElapsed = now - currentAction.start;
                            if (currentAction.action === 'intercept') {
                                currentAction.percentageDone =
                                    (timeElapsed / actionDuration) * currentAction.interceptMeetingPoint;
                                continue;
                            }
                            currentAction.percentageDone =
                                currentAction.action === 'returning_intercept'
                                    ? currentAction.interceptMeetingPoint -
                                      (timeElapsed / actionDuration) * currentAction.interceptMeetingPoint
                                    : (timeElapsed / actionDuration) * 100;
                        }
                    }
                }
            }
        },

        onResize() {
            this.windowSize = {
                width: window.innerWidth,
                height: window.innerHeight,
            };
            this.$emit('window-resize', this.windowSize);
        },
        setTargetCity(city) {
            this.$store.commit('setMapActionTarget', city);
        },
        toggleCityMenuActions(city_id) {
            // const alreadyOpened = this.cityActionsMenuOpened.findIndex(menu => menu === true);
            // if (alreadyOpened !== -1) {
            //     this.cityActionsMenuOpened[alreadyOpened] = false;
            // }
            // if (alreadyOpened === index) {
            //     return;
            // }
            // this.cityActionsMenuOpened[index] = !this.cityActionsMenuOpened[index];
            if (this.cityActionsMenuOpened === city_id) {
                this.cityActionsMenuOpened = null;
                return;
            }
            this.cityActionsMenuOpened = city_id;
        },
        toggleCityHoverInfo(city_id) {
            // this.cityHoverInfoVisible[index] = !this.cityHoverInfoVisible[index];
            this.cityHoverInfoVisible = city_id;
        },
        openTroopPanelOrIndicatorSelectPanel(action_id) {
            const GROUP = this.stackedIndicatorGroups.find(group => group.find(action => action.id === action_id));
            if (GROUP.length > 1) {
                this.toggleIndicatorSelectPanel(GROUP, action_id);
            } else {
                this.toggleTroopInfoPanel(action_id);
            }
        },
        toggleIndicatorSelectPanel(group, action_id) {
            if (group.find(action => action.id === this.indicatorSelectPanelVisible)) {
                this.indicatorSelectPanelVisible = null;
                return;
            }
            this.troopInfoPanelVisible = null;
            this.indicatorSelectPanelVisible = action_id;
        },
        toggleTroopInfoPanel(action_id) {
            if (this.troopInfoPanelVisible === action_id) {
                this.troopInfoPanelVisible = null;
                return;
            }
            this.indicatorSelectPanelVisible = null;
            this.troopInfoPanelVisible = action_id;
        },
        emitScrollCoords() {
            this.throttle(this.getVisibleCities, this.throttleTimerCityVisibility, 100);
            this.$emit('world-map-scroll', {
                x: this.worldMap.scrollLeft,
                y: this.worldMap.scrollTop,
            });
        },
        openCommercialCenterModal() {
            // reset all tabs
            document.getElementById('exchange').classList.remove('active');
            document.getElementById('exchange').ariaSelected = 'false';
            document.getElementById('exchange-page').classList.remove('active', 'show');

            document.getElementById('create-offer').classList.remove('active');
            document.getElementById('create-offer').ariaSelected = 'false';
            document.getElementById('create-offer-page').classList.remove('active', 'show');

            document.getElementById('send-resources').classList.remove('active');
            document.getElementById('send-resources').ariaSelected = 'false';
            document.getElementById('send-resources-page').classList.remove('active', 'show');

            document.getElementById('transports').classList.remove('active');
            document.getElementById('transports').ariaSelected = 'false';
            document.getElementById('transports-page').classList.remove('active', 'show');

            // open send-resources tab
            document.getElementById('send-resources').classList.add('active');
            document.getElementById('send-resources').ariaSelected = 'true';
            document.getElementById('send-resources-page').classList.add('active', 'show');

            // open modal
            document.getElementById('commercial-center-modal').style.transform = 'translateY(-20px)';
            document.getElementById('commercial-center-modal').style.opacity = '0';
            document.getElementById('commercial-center-modal').style.display = 'block';
            document.getElementById('commercial-center-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('commercial-center-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('commercial-center-modal').style.transform = 'translateY(0)';
                document.getElementById('commercial-center-modal').style.opacity = '1';
            }, 0);
        },
        openMessageModal() {
            // reset all tabs
            document.getElementById('inbox').classList.remove('active');
            document.getElementById('inbox').ariaSelected = 'false';
            document.getElementById('inbox-page').classList.remove('active', 'show');

            document.getElementById('coalition').classList.remove('active');
            document.getElementById('coalition').ariaSelected = 'false';
            document.getElementById('coalition-page').classList.remove('active', 'show');

            document.getElementById('write-message').classList.remove('active');
            document.getElementById('write-message').ariaSelected = 'false';
            document.getElementById('write-message-page').classList.remove('active', 'show');

            // open write-message tab
            document.getElementById('write-message').classList.add('active');
            document.getElementById('write-message').ariaSelected = 'true';
            document.getElementById('write-message-page').classList.add('active', 'show');

            // open modal
            document.getElementById('messages-modal').style.transform = 'translateY(-20px)';
            document.getElementById('messages-modal').style.opacity = '0';
            document.getElementById('messages-modal').style.display = 'block';
            document.getElementById('messages-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('messages-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('messages-modal').style.transform = 'translateY(0)';
                document.getElementById('messages-modal').style.opacity = '1';
            }, 0);
        },
        groupActionsByCityPair() {
            // console.time('groupActionsByCityPair:');
            const reinforcementsNotArrivedYet = this.filterReinforcementsNotArrivedYet(this.reinforcements);
            const mapActions = {
                attacks: this.attacks,
                returningAttacks: this.returningAttacks,
                reinforcements: reinforcementsNotArrivedYet,
                returningReinforcements: this.returningReinforcements,
                spyMissions: this.spyMissions,
                returningSpies: this.returningSpies,
                returningIntercepts: this.returningIntercepts,
                // tradingTransports: this.tradingTransports.map(transport => {
                //     return {
                //         ...transport,
                //         toCity: transport.toCity._id,
                //         fromCity: transport.fromCity._id,
                //         troops: [],
                //         arrivalDateToShow: transport.arrivalDate,
                //     };
                // }),
                // returningTradingTransports: this.returningTradingTransports.map(transport => {
                //     return {
                //         ...transport,
                //         toCity: transport.toCity._id,
                //         fromCity: transport.fromCity._id,
                //         troops: [],
                //         arrivalDateToShow: transport.arrivalDate,
                //     };
                // }),
                // sendResourcesTransports: this.sendResourcesTransports.map(transport => {
                //     return {
                //         ...transport,
                //         toCity: transport.toCity._id,
                //         fromCity: transport.fromCity._id,
                //         troops: [],
                //         arrivalDateToShow: transport.arrivalDate,
                //     };
                // }),
                // returningSendResourcesTransports: this.returningSendResourcesTransports.map(transport => {
                //     return {
                //         ...transport,
                //         toCity: transport.toCity._id,
                //         fromCity: transport.fromCity._id,
                //         troops: [],
                //         arrivalDateToShow: transport.arrivalDate,
                //     };
                // }),
            };
            const mapActionsArray = [];
            // TO DO: put command type property on the map action object itself,
            // as soon as its received and set into state
            for (const type of Object.keys(mapActions)) {
                let actionType;
                switch (type) {
                    case 'attacks':
                        actionType = 'attack';
                        break;
                    case 'returningAttacks':
                        actionType = 'returning_attack';
                        break;
                    case 'reinforcements':
                        actionType = 'reinforcement';
                        break;
                    case 'returningReinforcements':
                        actionType = 'returning_reinforcement';
                        break;
                    case 'spyMissions':
                        actionType = 'spy_mission';
                        break;
                    case 'returningSpies':
                        actionType = 'returning_spies';
                        break;
                    case 'returningIntercepts':
                        actionType = 'returning_intercept';
                        break;
                    // case 'tradingTransports':
                    //     actionType = 'trading_transport';
                    //     break;
                    // case 'returningTradingTransports':
                    //     actionType = 'returning_trading_transport';
                    //     break;
                    // case 'sendResourcesTransports':
                    //     actionType = 'send_resources_transport';
                    //     break;
                    // case 'returningSendResourcesTransports':
                    //     actionType = 'returning_send_resources_transport';
                    //     break;
                }
                for (let i = 0; i < mapActions[type].length; i++) {
                    const cityPairObj = {
                        cities: [],
                        coords: [],
                        uniqueDirections: [],
                    };
                    const uniqueDirectionObj = {
                        isOwnAction: false,
                        troopMovements: [],
                        hasIntercept: false,
                    };

                    const troopMovementsObj = {
                        id: mapActions[type][i]._id,
                        action: actionType,
                        troops: mapActions[type][i].troops || mapActions[type][i].spies,
                        start: this.convertDateStringToMiliseconds(mapActions[type][i].dateCreated),
                        end: this.convertDateStringToMiliseconds(mapActions[type][i].arrivalDateToShow),
                        percentageDone: type === 'returning_intercept' ? 50 : 0,
                        targetId: mapActions[type][i].toCity,
                        targetCoords: this.allCities.find(city => city._id === mapActions[type][i].toCity)?.coords,
                    };

                    uniqueDirectionObj.troopMovements.push(troopMovementsObj);

                    // add intercepts to troopMovementsObject
                    if (type === 'attacks') {
                        let intercepts = this.intercepts.filter(
                            intercept => intercept.interceptedAttack === troopMovementsObj.id,
                        );
                        intercepts.forEach(intercept => {
                            const interceptObj = {
                                action: 'intercept',
                                id: intercept._id,
                                troops: intercept.troops,
                                start: this.convertDateStringToMiliseconds(intercept.dateCreated),
                                end: this.convertDateStringToMiliseconds(intercept.arrivalDateToShow),
                                interceptedAttack: troopMovementsObj.id,
                                interceptMeetingPoint: intercept.interceptPoint,
                                // interceptedAttackPercentageDone: 0,
                                percentageDone: 0,
                            };
                            uniqueDirectionObj.troopMovements.push(interceptObj);
                            uniqueDirectionObj.hasIntercept = true;
                        });
                    }

                    if (mapActions[type][i].fromCity === this.currentCityId) {
                        uniqueDirectionObj.isOwnAction = true;
                    }

                    const samePairIndex = mapActionsArray.findIndex(
                        pair =>
                            pair.cities.includes(mapActions[type][i].toCity) &&
                            pair.cities.includes(mapActions[type][i].fromCity),
                    );
                    if (samePairIndex !== -1) {
                        if (
                            !mapActionsArray[samePairIndex].uniqueDirections.some(
                                action => action.isOwnAction === uniqueDirectionObj.isOwnAction,
                            )
                        ) {
                            mapActionsArray[samePairIndex].uniqueDirections.push(uniqueDirectionObj);
                        } else {
                            const sameUniqueActionIndex = mapActionsArray[samePairIndex].uniqueDirections.findIndex(
                                unique_action => unique_action.isOwnAction === uniqueDirectionObj.isOwnAction,
                            );
                            mapActionsArray[samePairIndex].uniqueDirections[sameUniqueActionIndex].troopMovements.push(
                                troopMovementsObj,
                            );
                            if (type === 'attacks') {
                                let intercepts = this.intercepts.filter(
                                    intercept => intercept.interceptedAttack === troopMovementsObj.id,
                                );
                                intercepts.forEach(intercept => {
                                    const interceptObj = {
                                        action: 'intercept',
                                        troops: intercept.troops,
                                        start: this.convertDateStringToMiliseconds(intercept.dateCreated),
                                        end: this.convertDateStringToMiliseconds(intercept.arrivalDateToShow),
                                        interceptedAttack: troopMovementsObj.id,
                                        interceptMeetingPoint: intercept.interceptPoint,
                                        // interceptedAttackPercentageDone: 0,
                                        percentageDone: 0,
                                    };
                                    mapActionsArray[samePairIndex].uniqueDirections[sameUniqueActionIndex].troopMovements.push(
                                        interceptObj,
                                    );
                                });
                            }
                        }
                    } else {
                        const originCityCoords = this.allCities?.find(city => city._id === mapActions[type][i].fromCity).coords;
                        const targetCityCoords = this.allCities?.find(city => city._id === mapActions[type][i].toCity).coords;
                        cityPairObj.cities.push(mapActions[type][i].fromCity, mapActions[type][i].toCity);
                        cityPairObj.coords.push(originCityCoords, targetCityCoords);
                        cityPairObj.uniqueDirections.push(uniqueDirectionObj);
                        mapActionsArray.push(cityPairObj);
                    }
                }
            }
            return mapActionsArray;
        },

        filterReinforcementsNotArrivedYet(reinforcements) {
            const reinforcementsNotArrived = reinforcements.filter(reinforcement => reinforcement.arrived === false);
            for (let i = 0; i < reinforcementsNotArrived.length; i++) {
                const reinforcementObj = reinforcementsNotArrived[i];
                reinforcementObj.mission = 'Reinforcing';
            }
            return reinforcementsNotArrived;
        },
    },
};
</script>

<style scoped>
#world-map-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.world-map {
    position: relative;
    /*width: 100vw;*/
    /*height: 100vh;*/
    overflow: hidden;
    /*background: #79664b;*/
    background: black;
    user-select: none;
    cursor: move;
}

.map-row {
    display: flex;
    margin-top: -248px;
    position: relative;
    width: fit-content;
}

.map-row:nth-child(odd) {
    transform: translateX(428px);
    /*filter: contrast(0.5);*/
}

.map-row:nth-child(even) {
    transform: translateX(0);
}

.map-tile {
    position: absolute;
    height: 496px;
    width: 856px;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
	transform: scale(0.995);
}

.decoration-tile-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 124px;
    width: 214px;
}

.decoration-tile {
    position: relative;
    height: 80px;
    width: auto;
    bottom: 6px;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.city-tile-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 124px;
    width: 214px;
}

.city-tile {
    position: relative;
    height: 124px;
    width: 185px;
    bottom: 6px;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.city-info-wrapper {
    position: absolute;
    top: -10%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transition: opacity 0.25s;
    pointer-events: none;
}

.city-tile-wrapper.menu-opened .city-info-wrapper {
    opacity: 0;
}

.city-name {
    position: relative;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-shadow: -1px 1px 1px #000, 1px 1px 1px #000, 1px -1px 1px #000, -1px -1px 1px #000;
}

.city-name::before {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -40%;
    border-radius: 50%;
    box-shadow: 0 0 3px 1px black;
}

.city-rebel {
    color: darkgray;
}

.city-rebel::before {
    background: darkgray;
}

.city-current {
    color: gold;
}

.city-current::before {
    background: gold;
}

.city-other {
    color: white;
}

.city-other::before {
    background: white;
}

.city-points {
    font-size: 0.6875rem;
    line-height: 0.6875rem;
    color: ghostwhite;
    text-shadow: -1px 1px 1px #000, 1px 1px 1px #000, 1px -1px 1px #000, -1px -1px 1px #000;
}

.city-clickable-overlay {
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
    width: 120px;
    height: 80px;
    top: 18px;
    z-index: 1;
}

.city-clickable-overlay:hover + .city-tile,
.city-tile.highlighted {
    -webkit-filter: drop-shadow(1px 1px 0 palegoldenrod) drop-shadow(-1px -1px 0 lightgoldenrodyellow);
    filter: drop-shadow(1px 1px 0 palegoldenrod) drop-shadow(-1px -1px 0 lightgoldenrodyellow);
}

.menu-item {
    z-index: 1;
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 50%;
    top: 30%;
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid darkgray;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s, transform 0.5s;
}

.menu-item.disabled {
    background: transparent;
    opacity: 0.75;
}

.city-tile-wrapper.menu-opened .menu-item {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
}

.city-tile-wrapper.menu-opened .menu-item.disabled {
    pointer-events: none;
    cursor: default;
}

.city-tile-wrapper:not(.menu-opened) .menu-item {
    opacity: 0;
    pointer-events: none;
}

.city-tile-wrapper.menu-opened .item-1 {
    transform: rotate(0deg) translate(calc(-7rem - v-bind(1 / mapScaleFactor + 'rem'))) rotate(-0deg)
        scale(v-bind(0.85 / mapScaleFactor));
}

.city-tile-wrapper.menu-opened .item-2 {
    transform: rotate(41deg) translate(calc(-6.5rem - v-bind(1 / mapScaleFactor + 'rem'))) rotate(-41deg)
        scale(v-bind(0.85 / mapScaleFactor));
}

.city-tile-wrapper.menu-opened .item-3 {
    transform: rotate(90deg) translate(calc(-6rem - v-bind(1 / mapScaleFactor + 'rem'))) rotate(-90deg)
        scale(v-bind(0.85 / mapScaleFactor));
}

.city-tile-wrapper.menu-opened .item-4 {
    transform: rotate(139deg) translate(calc(-6.5rem - v-bind(1 / mapScaleFactor + 'rem'))) rotate(-139deg)
        scale(v-bind(0.85 / mapScaleFactor));
}

.city-tile-wrapper.menu-opened .item-5 {
    transform: rotate(180deg) translate(calc(-7rem - v-bind(1 / mapScaleFactor + 'rem'))) rotate(-180deg)
        scale(v-bind(0.85 / mapScaleFactor));
}

.action-title {
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.625rem;
    line-height: 0.625rem;
    text-align: center;
    color: ghostwhite;
    text-shadow: -1px 1px 1px #000, 1px 1px 1px #000, 1px -1px 1px #000, -1px -1px 1px #000;
}

.city-hover-info-wrapper {
    z-index: 3;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid darkgray;
    width: fit-content;
    padding: 0.5rem;
    opacity: 1;
}

.hover-info-group {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    min-width: max-content;
    padding: 0 0.25rem;
}

.hover-info-group:not(:last-child) {
    margin-bottom: 0.25rem;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid darkgray;
}

.hover-info-icon {
    width: 1.625rem;
    height: 1.625rem;
}

.hover-info-text {
    display: flex;
    flex-direction: column;
}

.hover-info-title {
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: ghostwhite;
}

.hover-info-details {
    display: flex;
    gap: 1rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ghostwhite;
}

.hover-info-name {
    color: darkgray;
    position: relative;
}

.hover-info-name::after {
    content: '.';
    font-weight: 800;
    color: lightgray;
    position: absolute;
    right: -0.6875rem;
    top: -25%;
}

.hover-info-points {
    color: burlywood;
}

.tooltip-transition-enter-active,
.tooltip-transition-leave-active {
    transition: filter 0.15s linear, opacity 0.15s linear;
}

.tooltip-transition-enter-from,
.tooltip-transition-leave-to {
    filter: blur(3px);
    opacity: 0;
}

.arrows-wrapper {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transform-origin: left center;
}

.arrow {
    display: flex;
    align-items: center;
    width: 100%;
    filter: drop-shadow(0 0 2px black);
    -webkit-filter: drop-shadow(0 0 2px black);
}

.arrow-tail {
    width: 100px;
    height: 3px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), white);
}

.arrow-body {
    height: 3px;
    background: white;
    width: 100%;
}

.arrow-head {
    width: 10px;
    height: 10px;
    transform: translateX(-50%);
    background-image: url('../assets/icons/arrowhead.png');
    background-size: cover;
}

.arrow-reversed {
    transform: rotateZ(180deg);
}

.action-progress-indicator-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.25s;
}

.action-progress-indicator {
    width: 40px;
    height: 40px;
    /*top: -200%;*/
    opacity: 0.75;
    cursor: pointer;
}

/*.action-progress-indicator:hover {*/
/*    opacity: 1;*/
/*}*/

.action-progress-indicator.hovered {
    opacity: 1;
}

.indicator-select-panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: max-content;
    height: auto;
    bottom: calc(125% / v-bind(mapScaleFactor));
    left: 50%;
    transform: translateX(-50%) scale(v-bind(1 / mapScaleFactor));
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid darkgray;
    padding: 0.5rem;
}

.panel-indicator-wrapper {
    display: flex;
    gap: 0.75rem;
    cursor: pointer;
}

.panel-indicator {
    width: 1.5rem;
    height: 1.5rem;
}

.city-name-and-coords {
    display: flex;
    column-gap: 0.125rem;
    flex-wrap: wrap;
    font-size: 0.75rem;
    color: palegoldenrod;
}

.city-name-and-coords:before {
    content: 'Target: ';
    color: ghostwhite;
}

.troop-info-hover-panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: max-content;
    height: auto;
    bottom: calc(125% / v-bind(mapScaleFactor));
    left: 50%;
    transform: translateX(-50%) scale(v-bind(1 / mapScaleFactor));
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid darkgray;
    padding: 0.5rem;
}

.troop-info-rows-wrapper {
    column-count: 3;
    column-gap: 1rem;
    break-inside: avoid;
}

.troop-info-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
}

.troop-image-wrapper {
    min-width: 1.75rem;
    height: 1.75rem;
    overflow: hidden;
}

.troop-image {
    width: 1.75rem;
    cursor: pointer;
}

.troop-number {
    font-size: 0.875rem;
    color: ghostwhite;
}

.go-to-target-button {
    color: cornflowerblue;
    text-decoration: underline;
    font-size: 0.75rem;
    line-height: 1;
    cursor: pointer;
    -moz-osx-font-smoothing: unset;
    -webkit-font-smoothing: unset;
}

.go-to-target-button:hover {
    text-decoration: none;
}

.intercept-arrow .arrow-tail,
.intercept-arrow .arrow-body,
.intercept-arrow .arrow-head {
    opacity: 0;
}

.intercept-meeting-point {
    color: brown;
    font-size: 2rem;
    position: absolute;
    transform: translateX(50%);
}

.indicator-top {
    top: -200%;
}

.indicator-bottom {
    bottom: -200%;
}

.indicator-red {
    filter: drop-shadow(-1px -1px 1px black);
    -webkit-filter: drop-shadow(-1px -1px 1px black);
}

.indicator-brown {
    filter: brightness(0.75) saturate(0.75) drop-shadow(-1px -1px 1px black);
    -webkit-filter: brightness(0.75) saturate(0.75) drop-shadow(-1px -1px 1px black);
}

.indicator-purple {
    filter: hue-rotate(290deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(290deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
}

.indicator-blue {
    filter: hue-rotate(200deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(200deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
}

.indicator-lightblue {
    filter: hue-rotate(200deg) brightness(2.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(200deg) brightness(2.25) drop-shadow(-1px -1px 1px black);
}

.indicator-green {
    filter: hue-rotate(130deg) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(130deg) drop-shadow(-1px -1px 1px black);
}

.indicator-dark-green {
    filter: hue-rotate(130deg) brightness(0.75) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(130deg) brightness(0.75) drop-shadow(-1px -1px 1px black);
}

.indicator-orange {
    filter: hue-rotate(50deg) brightness(3) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(50deg) brightness(3) drop-shadow(-1px -1px 1px black);
}

.indicator-yellow {
    filter: hue-rotate(70deg) brightness(5) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(70deg) brightness(5) drop-shadow(-1px -1px 1px black);
}

.indicator-white {
    filter: grayscale(1) brightness(10) drop-shadow(-1px -1px 1px black);
    -webkit-filter: grayscale(1) brightness(10) drop-shadow(-1px -1px 1px black);
}

.indicator-black {
    filter: grayscale(1) brightness(0) drop-shadow(-1px -1px 1px black);
    -webkit-filter: grayscale(1) brightness(0) drop-shadow(-1px -1px 1px black);
}

.indicator-gray {
    filter: grayscale(1) brightness(0.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: grayscale(1) brightness(0.25) drop-shadow(-1px -1px 1px black);
}
</style>
