<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Invite Friend</h4>
                    <div class="building-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </div>
                    <button type="button" class="close" @click="closeModal">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="section-title">Send the following link to your friend:</div>
                </div>
                <textarea readonly id="invite-link">
					https://en.wikipedia.org/wiki/World_War_II#/media/File:Bundesarchiv_Bild_101I-646-5188-17,_Flugzeuge_Junkers_Ju_87.jpg
				</textarea>
                <div class="copy-button-wrapper">
                    <button class="btn yellow-button" @click="copyToClipboard">Copy</button>
                </div>
                <div class="rewards-wrapper">
                    <div class="section-title">When your friend reaches 5000 points you will receive the following rewards:</div>
                </div>
                <div class="reward-group">
                    <img src="../assets/icons/premium_icon.svg" class="reward-icon" />
                    <div class="reward-text">+500 Premium Points</div>
                </div>
                <div class="reward-group">
                    <img src="../assets/icons/premium_icon.svg" class="reward-icon" />
                    <div class="reward-text">+5 Days of Premium Account</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InviteLinkModal',
    methods: {
        closeModal() {
            document.getElementById('invite-link-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('invite-link-modal').style.transform = 'translateY(-20px)';
            document.getElementById('invite-link-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('invite-link-modal').style.display = 'none';
                document.getElementById('invite-link-modal').classList.remove('show');
                document.getElementById('invite-link-modal').style.removeProperty('transform');
                document.getElementById('invite-link-modal').style.removeProperty('opacity');
            }, 150);

            document.getElementById('invite-link-modal-2').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('invite-link-modal-2').style.transform = 'translateY(-20px)';
            document.getElementById('invite-link-modal-2').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('invite-link-modal-2').style.display = 'none';
                document.getElementById('invite-link-modal-2').classList.remove('show');
                document.getElementById('invite-link-modal-2').style.removeProperty('transform');
                document.getElementById('invite-link-modal-2').style.removeProperty('opacity');
            }, 150);
        },
        copyToClipboard() {
            const text = document.getElementById('invite-link');

            text.select();
            text.setSelectionRange(0, 99999);

            navigator.clipboard.writeText(text.value);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 100%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}

.building-description {
    width: 100%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 16vh;
    padding: 4rem 2rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

.btn {
    padding: 0.25rem 1rem;
    width: fit-content;
    height: fit-content;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.section-title {
    text-align: center;
}

#invite-link {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 0.875rem;
    overflow-wrap: break-word;
    background: rgba(0, 0, 0, 0.5);
    padding: 1rem 2rem;
    resize: none;
    color: ghostwhite;
    border: none;
    height: 6rem;
}

#invite-link:focus {
    outline: none;
}

.copy-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.rewards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rewards-wrapper .section-title {
    font-size: 0.875rem;
    width: 80%;
    margin-bottom: 1rem;
}

.reward-group {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    color: burlywood;
}

.reward-icon {
    height: 1.125rem;
}

.reward-text {
    font-size: 0.875rem;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 60%;
    }
    .modal-content {
        padding: 3rem 2rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 75%;
        margin: 0 auto;
    }
    .building-description {
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
    .modal-content {
        padding: 3rem 1rem;
    }
}

@media screen and (max-width: 450px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
