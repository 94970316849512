export const TroopsIdentifier = Object.freeze({
	INFANTRYMAN: 'Infantryman',
	SPY: 'Spy',
	MACHINE_GUNNER: 'Machine Gunner',
	BAZOOKA_SOLDIER: 'Bazooka soldier',
	SNIPER: 'Sniper',
	OFFICER: 'Officer',
	MEDIC: 'Medic',
	GENERAL: 'General',
	TRUCK: 'Truck',
	ANTI_TANK: 'Anti Tank',
	ANTI_AIR: 'Anti Air',
	LIGHT_TANK: 'Light Tank',
	MEDIUM_TANK: 'Medium Tank',
	HEAVY_TANK: 'Heavy Tank',
	SPY_PLANE: 'Spy Plane',
	TRANSPORT_PLANE: 'Transport Plane',
	FIGHTER: 'Fighter',
	CLOSE_AIR_SUPPORT: 'Close Air Support',
	BOMBING_PLANE: 'Bombing Plane',
});