import { arrayWrapper } from '@/utils/functions/templateUtils';

export const REPORTS_TEMPLATE = Object.freeze({
    coalitionInvites: arrayWrapper({
        _id: '',
        coalitionName: '',
        coalitionAbbreviation: '',
        text: '',
        dateCreated: '',
        coalitionId: '',
        accepted: false,
        isDeleted: false,
    }),
    coalitionEvents: arrayWrapper({
        text: '',
        dateCreated: '',
    }),
    friendInvites: arrayWrapper({
        text: '',
        dateCreated: '',
        from: '',
        accepted: false,
        isDeleted: false,
    }),
    attacksReports: arrayWrapper({
        owner: '',
        dateCreated: '',
        containsGeneral: false,
        isDeleted: false,
        attackerTroops: {
            troopsName: [],
            numOfTroops: [],
            lost: [],
            owned: false,
            reinforcement: '',
        },
        defenderTroops: {
            troopsName: [],
            numOfTroops: [],
            lost: [],
            owned: false,
            reinforcement: '',
        },
        buildingsDamaged: {
            buildingName: '',
            fromLevel: 0,
            toLevel: 0,
        },
        resourcesStolen: {
            name: '',
            quantity: 0,
        },
    }),
    reinforcementsReports: arrayWrapper({
        owner: '',
        isDeleted: false,
        dateCreated: '',
        troops: {
            troopsName: [],
            numOfTroops: [],
            lost: [],
            owned: false,
            reinforcement: '',
            lastCity: '',
        },
    }),
});
