<template>
    <Transition name="minimap">
        <div v-show="mapOpened && isMinimapVisible" class="UI-minimap-wrapper">
            <minimap
                class="UI-minimap"
                :all-cities="allCities"
                :world-map-scroll-coords="worldMapScrollCoords"
                :window-size="windowSize"
                :map-scale-factor="mapScaleFactor"
                :map-size-vertical="mapSizeVertical"
                :map-size-horizontal="mapSizeHorizontal"
                :current-city-id="currentCityId"
                @minimap-scroll="coords => emitScrollCoords(coords)"
            />
            <div class="map-legend">
                <div class="row">
                    <div class="col">
                        <span class="legend-entry current"> Current city </span>
                    </div>
                    <div class="col">
                        <span class="legend-entry own"> Controlled cities </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="legend-entry coalition"> Coalition </span>
                    </div>
                    <div class="col">
                        <span class="legend-entry ally"> Ally </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="legend-entry nap"> Non-Aggression Pact (NAP) </span>
                    </div>
                    <div class="col">
                        <span class="legend-entry enemy"> Enemy </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="legend-entry friend"> Friend </span>
                    </div>
                    <div class="col">
                        <span class="legend-entry abandoned"> Abandoned </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="legend-entry other"> Other </span>
                    </div>
                </div>
            </div>
            <div class="find-city-wrapper">
                <div class="row">
                    <div class="col justify-content-around">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault7" id="legend-coords" checked />
                            <label class="form-check-label" for="legend-coords">Coordinates</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault7" id="legend-city-name" />
                            <label class="form-check-label" for="legend-city-name">City Name</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault7" id="legend-player-name" />
                            <label class="form-check-label" for="legend-player-name">Player Name</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col large">
                        <input class="form-control" placeholder="Find city" />
                    </div>
                </div>
            </div>
        </div>
    </Transition>
    <!------------->
    <!-- TOP BAR -->
    <!------------->
    <div class="top-bar-wrapper">
        <div class="resource-group">
            <div class="icon-tooltip">
                Aluminium
                <div class="mt-2">Production: {{ resourceProduction?.aluminumProduction }}</div>
                <div v-if="tributeToReceive.aluminum > 0" class="mt-1">Tribute received: +{{ tributeToReceive.aluminum }}</div>
                <div v-if="tributeToPayOnCurrentCity.aluminum > 0" class="mt-1">
                    Tribute paid: -{{ tributeToPayOnCurrentCity.aluminum }}
                </div>
            </div>
            <img src="../assets/icons/aluminum_icon.svg" class="resource-icon" />
            <div>
                <span class="resource-name">Aluminium</span>
                <span
                    >({{ resources?.aluminum || 0 }} +{{
                        resourceProduction?.aluminumProduction + tributeToReceive.aluminum - tributeToPayOnCurrentCity.aluminum
                    }}/h)</span
                >
            </div>
        </div>
        <div class="resource-group">
            <div class="icon-tooltip">
                Oil
                <div class="mt-2">Production: {{ resourceProduction?.oilProduction }}</div>
                <div v-if="tributeToReceive.oil > 0" class="mt-1">Tribute received: +{{ tributeToReceive.oil }}</div>
                <div v-if="tributeToPayOnCurrentCity.oil > 0" class="mt-1">
                    Tribute paid: -{{ tributeToPayOnCurrentCity.oil }}
                </div>
            </div>
            <img src="../assets/icons/oil_icon.svg" class="resource-icon" />
            <div>
                <span class="resource-name">Oil</span>
                <span
                    >({{ resources?.oil || 0 }} +{{
                        resourceProduction?.oilProduction + tributeToReceive.oil - tributeToPayOnCurrentCity.oil
                    }}/h)</span
                >
            </div>
        </div>
        <div class="resource-group">
            <div class="icon-tooltip">
                Metal
                <div class="mt-2">Production: {{ resourceProduction?.metalProduction }}</div>
                <div v-if="tributeToReceive.metal > 0" class="mt-1">Tribute received: +{{ tributeToReceive.metal }}</div>
                <div v-if="tributeToPayOnCurrentCity.metal > 0" class="mt-1">
                    Tribute paid: -{{ tributeToPayOnCurrentCity.metal }}
                </div>
            </div>
            <img src="../assets/icons/metal_icon.svg" class="resource-icon" />
            <div>
                <span class="resource-name">Metal</span>
                <span
                    >({{ resources?.metal || 0 }} +{{
                        resourceProduction?.metalProduction + tributeToReceive.metal - tributeToPayOnCurrentCity.metal
                    }}/h)</span
                >
            </div>
        </div>
        <div class="resource-group">
            <div class="icon-tooltip">
                Rations
                <div class="mt-2">Production: {{ resourceProduction?.rationsProduction }}</div>
                <div v-if="tributeToReceive.rations > 0" class="mt-1">Tribute received: +{{ tributeToReceive.rations }}</div>
                <div v-if="tributeToPayOnCurrentCity.rations > 0" class="mt-1">
                    Tribute paid: -{{ tributeToPayOnCurrentCity.rations }}
                </div>
            </div>
            <img src="../assets/icons/rations_icon.svg" class="resource-icon" />
            <div>
                <span class="resource-name">Rations</span>
                <span
                    >({{ resources?.rations || 0 }} +{{
                        resourceProduction?.rationsProduction + tributeToReceive.rations - tributeToPayOnCurrentCity.rations
                    }}/h)</span
                >
            </div>
        </div>
        <div class="resource-group">
            <div class="icon-tooltip">Population</div>
            <img src="../assets/icons/population_icon.svg" class="resource-icon" />
            <div>
                <span class="resource-name">Population</span>
                <span>({{ occupiedPopulation }} / {{ maxPopulation }})</span>
            </div>
        </div>
        <div class="resource-group">
            <div class="icon-tooltip">Storage</div>
            <img src="../assets/icons/interface/storage_icon.svg" class="resource-icon" />
            <div>
                <span class="resource-name">Storage</span>
                <span>({{ occupiedStorage }} / {{ maxStorage }})</span>
            </div>
        </div>
    </div>

    <div class="city-info-wrapper">
        <div class="cities-wrapper">
            <div class="icon-tooltip">Cities</div>
            <img src="../assets/icons/city_icon.svg" class="city-icon" />
            <div>
                <span class="resource-name">Cities</span>
                <span class="cities-count">{{ numberOfOwnedCities }}</span>
            </div>
        </div>
        <div class="current-city-wrapper" @click="toggleCitiesDropdown">
            <img src="../assets/icons/current_city_icon.svg" class="city-icon" />
            <span>{{ currentCityName }} ( {{ currentCityCoords?.x }} | {{ currentCityCoords?.y }} )</span>
        </div>

        <Transition name="menu-dropdown">
            <div
                v-if="isCitiesDropdownVisible"
                class="cities-dropdown-wrapper"
                @mouseover="isHoverOnTabs = true"
                @mouseleave="isHoverOnTabs = false"
            >
                <div
                    v-for="(city, index) in allUserCities"
                    :key="index"
                    class="menu-item"
                    @click="setCurrentCityCommand({ city_id: city._id, city_index: null })"
                >
                    <img src="../assets/icons/current_city_icon.svg" class="city-icon" />
                    <!--                    <span class="menu-item-name">{{ city.name }} ( {{ city.x }} | {{ city.y }} )</span>-->
                    <span class="menu-item-name">{{ city.name }}</span>
                </div>
            </div>
        </Transition>

        <div :class="{ active: isResourcesDropdownVisible }" class="resources-dropdown-button" @click="toggleResourcesDropdown">
            Resources
        </div>
    </div>

    <Transition name="resources-dropdown">
        <div
            v-if="isResourcesDropdownVisible"
            class="resources-dropdown-wrapper"
            @mouseover="isHoverOnTabs = true"
            @mouseleave="isHoverOnTabs = false"
        >
            <div class="resource-group">
                <div class="icon-tooltip">Aluminium</div>
                <img src="../assets/icons/aluminum_icon.svg" class="resource-icon" />
                <div class="resource-info">
                    <span class="resource-name">Aluminium</span>
                    <span
                        >{{ resources?.aluminum || 0 }} +{{
                            resourceProduction?.aluminumProduction +
                            tributeToReceive.aluminum -
                            tributeToPayOnCurrentCity.aluminum
                        }}/h</span
                    >
                </div>
            </div>
            <div class="resource-group">
                <div class="icon-tooltip">Oil</div>
                <img src="../assets/icons/oil_icon.svg" class="resource-icon" />
                <div class="resource-info">
                    <span class="resource-name">Gasoline</span>
                    <span
                        >{{ resources?.oil || 0 }} +{{
                            resourceProduction?.oilProduction + tributeToReceive.oil - tributeToPayOnCurrentCity.oil
                        }}/h</span
                    >
                </div>
            </div>
            <div class="resource-group">
                <div class="icon-tooltip">Metal</div>
                <img src="../assets/icons/metal_icon.svg" class="resource-icon" />
                <div class="resource-info">
                    <span class="resource-name">Metal</span>
                    <span
                        >{{ resources?.metal || 0 }} +{{
                            resourceProduction?.metalProduction + tributeToReceive.metal - tributeToPayOnCurrentCity.metal
                        }}/h</span
                    >
                </div>
            </div>
            <div class="resource-group">
                <div class="icon-tooltip">Rations</div>
                <img src="../assets/icons/rations_icon.svg" class="resource-icon" />
                <div class="resource-info">
                    <span class="resource-name">Rations</span>
                    <span
                        >{{ resources?.rations || 0 }} +{{
                            resourceProduction?.rationsProduction + tributeToReceive.rations - tributeToPayOnCurrentCity.rations
                        }}/h</span
                    >
                </div>
            </div>
            <div class="resource-group">
                <div class="icon-tooltip">Population</div>
                <img src="../assets/icons/population_icon.svg" class="resource-icon" />
                <div class="resource-info">
                    <span class="resource-name">Population</span>
                    <span>{{ occupiedPopulation }} / {{ maxPopulation }}</span>
                </div>
            </div>
            <div class="resource-group">
                <div class="icon-tooltip">Storage</div>
                <img src="../assets/icons/rations_icon.svg" class="resource-icon" />
                <div class="resource-info">
                    <span class="resource-name">Storage</span>
                    <span>{{ occupiedStorage }} / {{ maxStorage }}</span>
                </div>
            </div>
            <div class="resource-group">
                <div class="icon-tooltip">Cities</div>
                <img src="../assets/icons/city_icon.svg" class="resource-icon" />
                <div class="resource-info">
                    <span class="resource-name">Cities</span>
                    <span class="cities-count">{{ numberOfOwnedCities }}</span>
                </div>
            </div>
        </div>
    </Transition>

    <Transition name="minimap">
        <div v-if="mapOpened && isMinimapVisible" class="map-controls-wrapper">
            <div class="zoom-button" @click="$emit('zoom-out')">
                <span>-</span>
            </div>
            <div class="zoom-button" @click="$emit('zoom-in')">
                <span>+</span>
            </div>
            <div class="center-map-button" @click="$emit('map-centered')">
                <img src="../assets/icons/point_circle_icon.svg" class="center-map-icon" />
            </div>
        </div>
    </Transition>

    <div class="top-menu-wrapper">
        <img src="../assets/icons/info_icon.svg" class="top-menu-icon" />
        <img src="../assets/icons/help_icon.svg" class="top-menu-icon" />
        <img src="../assets/icons/menu_icon.svg" class="top-menu-icon" @click="toggleMenuDropdown" />
    </div>

    <Transition name="menu-dropdown">
        <div
            v-if="isMenuDropdownVisible"
            class="menu-dropdown-wrapper"
            @mouseover="isHoverOnTabs = true"
            @mouseleave="isHoverOnTabs = false"
        >
            <div class="menu-item" data-bs-toggle="modal" data-bs-target="#profile-modal">
                <img src="../assets/icons/profile_icon.svg" class="menu-icon" />
                <span class="menu-item-name">Profile</span>
            </div>
            <div class="menu-item" data-bs-toggle="modal" data-bs-target="#ranking-modal">
                <img src="../assets/icons/premium_icon.svg" class="menu-icon" />
                <span class="menu-item-name">Ranking</span>
            </div>
            <div class="menu-item" data-bs-toggle="modal" data-bs-target="#settings-modal">
                <img src="../assets/icons/settings_icon.svg" class="menu-icon" />
                <span class="menu-item-name">Settings</span>
            </div>
            <div class="menu-item" @click="removeCookiesAndGoToLogin">
                <img src="../assets/icons/attached_icon.svg" class="menu-icon" />
                <span class="menu-item-name">Logout</span>
            </div>
        </div>
    </Transition>

    <!---------------->
    <!-- BOTTOM BAR -->
    <!---------------->

    <div class="bottom-menu-wrapper">
        <div class="bottom-icon-group" @click="closeMap">
            <img src="../assets/icons/bottom_bar/base_bottom_icon.svg" class="bottom-menu-icon" />
            <span>Base</span>
        </div>
        <div class="bottom-icon-group" @click="openMap" :class="{ disabled: !mapLoaded }">
            <div v-if="!mapLoaded" class="spinner-border text-light" role="status" />
            <img src="../assets/icons/bottom_bar/map_bottom_icon.svg" class="bottom-menu-icon" />
            <span>World Map</span>
        </div>
        <div class="bottom-icon-group" data-bs-toggle="modal" data-bs-target="#coalition-modal">
            <img src="../assets/icons/bottom_bar/alliance_bottom_icon.svg" class="bottom-menu-icon" />
            <span>Coalition</span>
        </div>
        <div class="bottom-icon-group" data-bs-toggle="modal" data-bs-target="#shop-modal">
            <img src="../assets/icons/bottom_bar/shop_bottom_icon.svg" class="bottom-menu-icon" />
            <span>Shop</span>
        </div>
        <div class="bottom-icon-group" data-bs-toggle="modal" data-bs-target="#contacts-modal">
            <img src="../assets/icons/bottom_bar/contacts_bottom_icon.svg" class="bottom-menu-icon" />
            <span>Contacts</span>
        </div>
        <div class="bottom-icon-group" data-bs-toggle="modal" data-bs-target="#messages-modal">
            <img src="../assets/icons/bottom_bar/message_bottom_icon.svg" class="bottom-menu-icon" />
            <span>Messages</span>
        </div>
        <div class="bottom-icon-group" data-bs-toggle="modal" data-bs-target="#reports-modal">
            <img src="../assets/icons/bottom_bar/reports_bottom_icon.svg" class="bottom-menu-icon" />
            <span>Reports</span>
        </div>
    </div>
    <div class="server-selector-wrapper">
        <span>Server 1</span>
        <div class="red-box" />
    </div>

    <!----------------->
    <!-- CHAT WINDOW -->
    <!----------------->

    <div v-if="user.coalition" :class="{ 'hidden-exclusive': isChatVisibleExclusive }" class="chat-window-wrapper">
        <div
            class="chat-header"
            @click="
                toggleChatWindow();
                toggleMobileLeftTabExclusive();
            "
        >
            <img src="../assets/icons/alliance_icon.svg" class="chat-icon" />
            <span>Coalition</span>
            <img src="../assets/icons/red_close_icon.svg" class="chat-close-icon" />
        </div>
        <div v-if="isChatVisible" class="chat-body" @mouseover="isHoverOnTabs = true" @mouseleave="isHoverOnTabs = false">
            <div class="post-group">
                <span class="post-timestamp">09:32 AM</span>
                <span class="post-text">
                    <span class="post-author">DanyDanutzu69: </span>
                    Lorem ipsum
                </span>
            </div>
            <div class="post-group">
                <span class="post-timestamp">09:35 AM</span>
                <span class="post-text">
                    <span class="post-author">DanyDanutzu69: </span>
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
                    aperiam
                </span>
            </div>
            <div class="post-group">
                <span class="post-timestamp">09:35 AM</span>
                <span class="post-text">
                    <span class="post-author">DanyDanutzu69: </span>
                    Ut enim
                </span>
            </div>
            <div class="post-group">
                <span class="post-timestamp">10:51 AM</span>
                <span class="post-text">
                    <span class="post-author">Costy-Fortza: </span>
                    Quis autem?
                </span>
            </div>
            <div class="post-group">
                <span class="post-timestamp">10:55 AM</span>
                <span class="post-text">
                    <span class="post-author">DanyDanutzu69: </span>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                </span>
            </div>
            <div class="post-group">
                <span class="post-timestamp">09:32 AM</span>
                <span class="post-text">
                    <span class="post-author">DanyDanutzu69: </span>
                    Lorem ipsum
                </span>
            </div>
            <div class="post-group">
                <span class="post-timestamp">09:35 AM</span>
                <span class="post-text">
                    <span class="post-author">DanyDanutzu69: </span>
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
                    aperiam
                </span>
            </div>
            <div class="post-group">
                <span class="post-timestamp">09:35 AM</span>
                <span class="post-text">
                    <span class="post-author">DanyDanutzu69: </span>
                    Ut enim
                </span>
            </div>
            <div class="post-group">
                <span class="post-timestamp">10:51 AM</span>
                <span class="post-text">
                    <span class="post-author">Costy-Fortza: </span>
                    Quis autem?
                </span>
            </div>
            <div class="post-group">
                <span class="post-timestamp">10:55 AM</span>
                <span class="post-text">
                    <span class="post-author">DanyDanutzu69: </span>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                </span>
            </div>
        </div>
        <div v-if="isChatVisible" class="text-box-wrapper">
            <textarea placeholder="Write your message here..." class="text-box" />
            <img src="../assets/icons/chat_post_icon.svg" class="chat-post-icon" />
        </div>
    </div>

    <!----------------->
    <!-- SOLDIER TAB -->
    <!----------------->

    <div class="soldier-tab tab-wrapper">
        <div class="tab-arrow-icon-wrapper" @click="toggleSoldierTab">
            <div class="icon-tooltip">Soldiers</div>
            <img src="../assets/icons/right_arrow_icon.svg" :class="{ rotated: !isSoldierTabVisible }" class="tab-arrow-icon" />
        </div>
        <div
            :class="{ 'hidden-right': !isSoldierTabVisible }"
            class="tab-window"
            @mouseover="isHoverOnTabs = true"
            @mouseleave="isHoverOnTabs = false"
        >
            <span class="tab-title">Soldiers</span>
            <div class="icons-wrapper">
                <div
                    v-for="(soldier, index) in [...unitTypes.soldiers, ...unitTypes.other].filter(
                        troop =>
                            troop.numOfTroops > 0 || troop.controlledReinforcements > 0 || troop.uncontrolledReinforcements > 0,
                    )"
                    :key="index"
                    class="unit-icon-wrapper"
                    @click="openTroopDetailsModal(soldier)"
                >
                    <div class="icon-tooltip">{{ soldier.name }}</div>
                    <img :src="require(`../assets/icons/troops/${toKebabCase(soldier.name)}-tall.avif`)" class="unit-icon" />
                    <span>{{
                        (
                            soldier.numOfTroops +
                            soldier.controlledReinforcements +
                            soldier.uncontrolledReinforcements
                        ).toLocaleString('ro')
                    }}</span>
                </div>
            </div>
        </div>
    </div>

    <!-------------->
    <!-- UNIT TAB -->
    <!-------------->

    <div class="unit-tab tab-wrapper">
        <div class="tab-arrow-icon-wrapper" @click="toggleUnitTab">
            <div class="icon-tooltip">Units</div>
            <img src="../assets/icons/right_arrow_icon.svg" :class="{ rotated: !isUnitTabVisible }" class="tab-arrow-icon" />
        </div>
        <div
            :class="{ 'hidden-right': !isUnitTabVisible }"
            class="tab-window"
            @mouseover="isHoverOnTabs = true"
            @mouseleave="isHoverOnTabs = false"
        >
            <span class="tab-title">Units</span>
            <div class="icons-wrapper">
                <div
                    v-for="(unit, index) in [...unitTypes.tanks, ...unitTypes.planes].filter(
                        troop =>
                            troop.numOfTroops > 0 || troop.controlledReinforcements > 0 || troop.uncontrolledReinforcements > 0,
                    )"
                    :key="index"
                    class="unit-icon-wrapper"
                    @click="openTroopDetailsModal(unit)"
                >
                    <div class="icon-tooltip">{{ unit.name }}</div>
                    <img :src="require(`../assets/icons/troops/${toKebabCase(unit.name)}-tall.avif`)" class="unit-icon" />
                    <span>{{
                        (unit.numOfTroops + unit.controlledReinforcements + unit.uncontrolledReinforcements).toLocaleString('ro')
                    }}</span>
                </div>
            </div>
        </div>
    </div>

    <!---------------------->
    <!-- MOBILE RIGHT TAB -->
    <!---------------------->

    <div class="mobile-right-tab tab-wrapper">
        <div class="tab-arrow-icon-wrapper" @click="toggleMobileRightTab">
            <div class="icon-tooltip">Soldiers & Units</div>
            <img
                src="../assets/icons/right_arrow_icon.svg"
                :class="{ rotated: !isMobileRightTabVisible }"
                class="tab-arrow-icon"
            />
        </div>
        <div
            :class="{ 'hidden-right': !isMobileRightTabVisible }"
            class="mobile-right-tab-window"
            @mouseover="isHoverOnTabs = true"
            @mouseleave="isHoverOnTabs = false"
        >
            <span class="tab-title">Soldiers</span>
            <div class="icons-wrapper">
                <div
                    v-for="(soldier, index) in unitTypes.soldiers.filter(
                        troop =>
                            troop.numOfTroops > 0 || troop.controlledReinforcements > 0 || troop.uncontrolledReinforcements > 0,
                    )"
                    :key="index"
                    class="unit-icon-wrapper"
                    @click="openTroopDetailsModal(soldier)"
                >
                    <div class="icon-tooltip">{{ soldier.name }}</div>
                    <img :src="require(`../assets/icons/troops/${toKebabCase(soldier.name)}-tall.avif`)" class="unit-icon" />
                    <span>{{
                        (
                            soldier.numOfTroops +
                            soldier.controlledReinforcements +
                            soldier.uncontrolledReinforcements
                        ).toLocaleString('ro')
                    }}</span>
                </div>
                <div
                    v-for="(soldier, index) in unitTypes.other.filter(
                        troop =>
                            troop.numOfTroops > 0 || troop.controlledReinforcements > 0 || troop.uncontrolledReinforcements > 0,
                    )"
                    :key="index"
                    class="unit-icon-wrapper"
                    @click="openTroopDetailsModal(soldier)"
                >
                    <div class="icon-tooltip">{{ soldier.name }}</div>
                    <img :src="require(`../assets/icons/troops/${toKebabCase(soldier.name)}-tall.avif`)" class="unit-icon" />
                    <span>{{
                        (
                            soldier.numOfTroops +
                            soldier.controlledReinforcements +
                            soldier.uncontrolledReinforcements
                        ).toLocaleString('ro')
                    }}</span>
                </div>
            </div>
            <span class="tab-title">Units</span>
            <div class="icons-wrapper">
                <div
                    v-for="(unit, index) in [...unitTypes.tanks, ...unitTypes.planes].filter(
                        troop =>
                            troop.numOfTroops > 0 || troop.controlledReinforcements > 0 || troop.uncontrolledReinforcements > 0,
                    )"
                    :key="index"
                    class="unit-icon-wrapper"
                    @click="openTroopDetailsModal(unit)"
                >
                    <div class="icon-tooltip">{{ unit.name }}</div>
                    <img :src="require(`../assets/icons/troops/${toKebabCase(unit.name)}-tall.avif`)" class="unit-icon" />
                    <span>{{
                        (unit.numOfTroops + unit.controlledReinforcements + unit.uncontrolledReinforcements).toLocaleString('ro')
                    }}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="left-tabs-wrapper">
        <!---------------------->
        <!-- CONSTRUCTION TAB -->
        <!---------------------->

        <div class="construction-tab left-tab-wrapper">
            <div
                :class="{ 'hidden-left': isConstructionTabVisible }"
                class="left-tab-icon-wrapper"
                @click="toggleConstructionTab"
            >
                <div class="icon-tooltip">Building Commands</div>
                <img src="../assets/icons/interface/building_commands_panel.svg" class="left-tab-icon" />
            </div>
            <div
                :class="{ 'hidden-left': !isConstructionTabVisible }"
                class="left-tab-window"
                @mouseover="isHoverOnTabs = true"
                @mouseleave="isHoverOnTabs = false"
            >
                <div
                    v-for="(building_command, building_command_index) in buildingCommandsToShow"
                    :key="building_command_index"
                    class="command-group"
                    :class="{ 'd-none': !building_command }"
                >
                    <img
                        v-if="building_command"
                        :src="
                            require(`../assets/icons/buildings/${toSnakeCase(
                                building_command.name,
                            )}_mini_${getNextLevelBuildingAppearance(building_command.name)}.avif`)
                        "
                        alt=""
                        class="construction-command-icon"
                        @click="openBuildingDetailsModal(getBuildingFromConstructionCommand(building_command.name))"
                    />
                    <span
                        class="command-target"
                        @click="openBuildingDetailsModal(getBuildingFromConstructionCommand(building_command.name))"
                        >{{ building_command?.name }}</span
                    >
                    <span class="command-modifier">{{ building_command?.level }}</span>
                    <span class="command-duration">{{ building_command?.time }}</span>
                    <span class="command-finish">{{ building_command?.finishDateToShow }}</span>
                </div>
            </div>
        </div>

        <!--------------------->
        <!-- RECRUITMENT TAB -->
        <!--------------------->

        <div class="recruitment-tab left-tab-wrapper">
            <div :class="{ 'hidden-left': isRecruitmentTabVisible }" class="left-tab-icon-wrapper" @click="toggleRecruitmentTab">
                <div class="icon-tooltip">Recruitment Commands</div>
                <img src="../assets/icons/interface/recruitment_panel.svg" class="left-tab-icon" />
            </div>
            <div
                :class="{ 'hidden-left': !isRecruitmentTabVisible }"
                class="left-tab-window"
                @mouseover="isHoverOnTabs = true"
                @mouseleave="isHoverOnTabs = false"
            >
                <div
                    v-for="(recruitment_command, recruitment_command_index) in troopsCommandsToShow"
                    :key="recruitment_command_index"
                    class="command-group"
                    :class="{ 'd-none': !recruitment_command }"
                >
                    <div
                        v-if="recruitment_command"
                        class="unit-recruitment-icon-wrapper"
                        @click="openTroopDetailsModal(getTroopFromRecruitmentCommand(recruitment_command.name))"
                    >
                        <img
                            :src="require(`../assets/icons/troops/${toKebabCase(recruitment_command?.name)}-tall.avif`)"
                            alt=""
                        />
                    </div>
                    <span
                        class="command-target"
                        @click="openTroopDetailsModal(getTroopFromRecruitmentCommand(recruitment_command.name))"
                        >{{ recruitment_command?.name }}</span
                    >
                    <span class="command-modifier">{{ recruitment_command?.numOfTroops }}</span>
                    <span class="command-duration">{{ recruitment_command?.time }}</span>
                    <span class="command-finish">{{ recruitment_command?.finishDateToShow }}</span>
                </div>
            </div>
        </div>

        <!--------------------->
        <!-- MAP ACTIONS TAB -->
        <!--------------------->

        <div class="map-actions-tab left-tab-wrapper">
            <div :class="{ 'hidden-left': isMapActionsTabVisible }" class="left-tab-icon-wrapper" @click="toggleMapActionsTab">
                <div class="icon-tooltip">Map Actions</div>
                <img src="../assets/icons/interface/map_actions_panel.svg" class="left-tab-icon" />
            </div>
            <div
                :class="{ 'hidden-left': !isMapActionsTabVisible }"
                class="left-tab-window"
                @mouseover="isHoverOnTabs = true"
                @mouseleave="isHoverOnTabs = false"
            >
                <CommandRowCityUI v-for="map_action in mapActions" :key="map_action.originalData._id" :command="map_action" />
            </div>
        </div>
    </div>

    <!--------------------->
    <!-- MOBILE LEFT TAB -->
    <!--------------------->

    <div
        :class="{
            'hidden-left': !isMobileLeftTabVisible,
            'hidden-exclusive': isMobileLeftTabVisibleExclusive,
        }"
        class="mobile-left-tab-wrapper"
    >
        <div class="mobile-left-tab-window" @mouseover="isHoverOnTabs = true" @mouseleave="isHoverOnTabs = false">
            <div
                class="combined-left-tab-icon-wrapper"
                @click="
                    toggleMobileLeftTab();
                    toggleChatExclusive();
                "
            >
                <div class="icon-tooltip">Commands</div>
                <img src="../assets/icons/interface/commands_panel.svg" class="left-tab-icon" />
            </div>

            <div
                v-for="(building_command, building_command_index) in buildingCommandsToShow"
                :key="building_command_index"
                class="command-group"
                :class="{ 'd-none': !building_command }"
            >
                <img
                    v-if="building_command"
                    :src="
                        require(`../assets/icons/buildings/${toSnakeCase(
                            building_command.name,
                        )}_mini_${getNextLevelBuildingAppearance(building_command.name)}.avif`)
                    "
                    alt=""
                    class="construction-command-icon"
                    @click="openBuildingDetailsModal(getBuildingFromConstructionCommand(building_command.name))"
                />
                <span
                    class="command-target"
                    @click="openBuildingDetailsModal(getBuildingFromConstructionCommand(building_command.name))"
                    >{{ building_command?.name }}</span
                >
                <span class="command-modifier">{{ building_command?.level }}</span>
                <span class="command-duration">{{ building_command?.time }}</span>
                <span class="command-finish">{{ building_command?.finishDateToShow }}</span>
            </div>

            <!-- TO DO: make a computed value out of this condition -->
            <div
                v-if="
                    buildingCommandsToShow.filter(command => command !== null).length &&
                    troopsCommandsToShow.filter(command => command !== null).length
                "
                class="separator"
            />

            <div
                v-for="(recruitment_command, recruitment_command_index) in troopsCommandsToShow"
                :key="recruitment_command_index"
                class="command-group"
                :class="{ 'd-none': !recruitment_command }"
            >
                <div
                    v-if="recruitment_command"
                    class="unit-recruitment-icon-wrapper"
                    @click="openTroopDetailsModal(getTroopFromRecruitmentCommand(recruitment_command.name))"
                >
                    <img :src="require(`../assets/icons/troops/${toKebabCase(recruitment_command?.name)}-tall.avif`)" alt="" />
                </div>
                <span
                    class="command-target"
                    @click="openTroopDetailsModal(getTroopFromRecruitmentCommand(recruitment_command.name))"
                    >{{ recruitment_command?.name }}</span
                >
                <span class="command-modifier">{{ recruitment_command?.numOfTroops }}</span>
                <span class="command-duration">{{ recruitment_command?.time }}</span>
                <span class="command-finish">{{ recruitment_command?.finishDateToShow }}</span>
            </div>

            <!-- TO DO: make a computed value out of this condition -->
            <div v-if="troopsCommandsToShow.filter(command => command !== null).length && mapActions.size" class="separator" />

            <CommandRowCityUI v-for="map_action in mapActions" :key="map_action.originalData._id" :command="map_action" />
        </div>
    </div>
    <map-action-modal
        id="map-action-modal"
        :troops="troops"
        :viewed-action="viewedAction"
        :all-cities="allCities"
        :set-current-troop="setCurrentTroop"
    />
    <contacts-modal id="contacts-modal" />
    <coalition-modal
        id="coalition-modal"
        :user="user"
        :all-users="allUsers"
        :coalition-info="coalitionInfo"
        :coalition-invites="coalitionInvites"
        :nearbyCoalitions="nearbyCoalitions"
        :requests-to-join-coalition="requestsToJoinCoalition"
        :awaiting-response="awaitingResponse"
    />
    <ranking-modal id="ranking-modal" />
    <reports-modal id="reports-modal" />
    <shop-modal id="shop-modal" />
    <profile-modal id="profile-modal" />
    <messages-modal id="messages-modal" />
    <city-modal id="city-modal" />
    <coalition-details-modal id="coalition-details-modal" />
    <settings-modal id="settings-modal" />
    <confirmation-modal id="confirmation-modal" />
</template>

<script>
import ContactsModal from '@/components/ContactsModal';
import MessagesModal from '@/components/MessagesModal';
import CoalitionModal from '@/components/CoalitionModal';
import RankingModal from '@/components/RankingModal';
import ReportsModal from '@/components/ReportsModal';
import ShopModal from '@/components/ShopModal';
import ProfileModal from '@/components/ProfileModal';
import CityModal from '@/components/CityModal';
import SettingsModal from '@/components/SettingsModal';
import CoalitionDetailsModal from '@/components/CoalitionDetailsModal';
import ConfirmationModal from '@/components/ConfirmationModal';
import MapActionModal from '@/components/MapActionModal';
import Minimap from '@/components/Minimap';
import { paramCase, snakeCase } from 'change-case';
import { mapActions, mapGetters } from 'vuex';
import { BuildingIdentifier } from '@/utils/enums/city';
import CommandRowCityUI from '@/components/CommandRowCityUI';

export default {
    name: 'CityUI',
    components: {
        CommandRowCityUI,
        Minimap,
        ConfirmationModal,
        CoalitionDetailsModal,
        SettingsModal,
        CityModal,
        ProfileModal,
        ShopModal,
        ReportsModal,
        RankingModal,
        CoalitionModal,
        MessagesModal,
        ContactsModal,
        MapActionModal,
    },
    props: {
        user: {
            type: Object,
            default: () => {},
        },
        allUserCities: {
            type: Array,
            default: () => [],
        },
        allUsers: {
            type: Array,
            default: () => [],
        },
        allCities: {
            type: Array,
            default: () => [],
        },
        troops: {
            type: Object,
            default: () => {},
        },
        currentCityName: {
            type: String,
            default: '',
        },
        currentCityTile: {
            type: Number,
            default: 0,
        },
        currentCityId: {
            type: String,
            default: '',
        },
        currentCityCoords: {
            type: Object,
            default: () => {},
        },
        buildings: {
            type: Array,
            default: () => [],
        },
        resources: {
            type: Object,
            default: () => {},
        },
        resourceProduction: {
            type: Object,
            default: () => {},
        },
        mapOpened: {
            type: Boolean,
            default: false,
        },
        worldMapScrollCoords: {
            type: Object,
            default: () => {},
        },
        windowSize: {
            type: Object,
            default: () => {},
        },
        mapScaleFactor: {
            type: Number,
            default: 1,
        },
        unitTypes: {
            type: Object,
            default: () => {},
        },
        mapSizeVertical: {
            type: Number,
            default: null,
        },
        mapSizeHorizontal: {
            type: Number,
            default: null,
        },
        buildingCommandsToShow: {
            type: Array,
            default: () => [],
        },
        troopsCommandsToShow: {
            type: Array,
            default: () => [],
        },
		// TO DO: remove this after arrived reinforcements refactor
        reinforcements: {
            type: Array,
            default: () => [],
        },
        mapActions: {
            type: Set,
            default: () => new Set(),
        },
        coalitionInfo: {
            type: Object,
            default: () => {},
        },
        coalitionInvites: {
            type: Array,
            default: () => [],
        },
        requestsToJoinCoalition: {
            type: Array,
            default: () => [],
        },
        nearbyCoalitions: {
            type: Array,
            default: () => [],
        },
        setCurrentTroop: {
            type: Function,
            default: () => null,
        },
        setCurrentBuilding: {
            type: Function,
            default: () => null,
        },
        mapLoaded: {
            type: Boolean,
            default: false,
        },
        awaitingResponse: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            BuildingIdentifier,

            windowHeight: 0,
            isChatVisible: false,
            isSoldierTabVisible: false,
            isUnitTabVisible: false,
            isMobileRightTabVisible: false,
            isConstructionTabVisible: false,
            isRecruitmentTabVisible: false,
            isMapActionsTabVisible: false,
            isMobileLeftTabVisible: false,
            isResourcesDropdownVisible: false,
            isMenuDropdownVisible: false,
            isCitiesDropdownVisible: false,
            isChatVisibleExclusive: false,
            isMobileLeftTabVisibleExclusive: false,
            isHoverOnTabs: false,
            viewedAction: {},
        };
    },
    emits: ['open-map', 'close-map', 'hover-changed', 'zoom-out', 'zoom-in', 'map-centered', 'minimap-scroll'],
    computed: {
        isMinimapVisible() {
            return !this.isSoldierTabVisible && !this.isUnitTabVisible && !this.isMobileRightTabVisible;
        },
        tributeToPayOnCurrentCity() {
            const TRIBUTE_OBJECT = {
                aluminum: 0,
                oil: 0,
                metal: 0,
                rations: 0,
            };
            if (!this.coalitionInfo?.tributeToPay) {
                return TRIBUTE_OBJECT;
            }
            this.coalitionInfo.tributeToPay.forEach(tribute => {
                tribute.tributePerUser.forEach(tribute_per_user => {
                    if (tribute_per_user.user._id === this.user._id) {
                        tribute_per_user.cities.forEach(tribute_per_city => {
                            if (tribute_per_city.city === this.currentCityId) {
                                Object.keys(TRIBUTE_OBJECT).forEach(resource => {
                                    TRIBUTE_OBJECT[resource] += tribute_per_city[resource];
                                });
                            }
                        });
                    }
                });
            });
            // round down to the nearest integer
            Object.keys(TRIBUTE_OBJECT).forEach(resource => {
                TRIBUTE_OBJECT[resource] = Math.floor(TRIBUTE_OBJECT[resource]);
            });
            return TRIBUTE_OBJECT;
        },
        tributeToReceive() {
            const TRIBUTE_OBJECT = {
                aluminum: 0,
                oil: 0,
                metal: 0,
                rations: 0,
            };
            if (!this.coalitionInfo?.tributeToReceive) {
                return TRIBUTE_OBJECT;
            }
            this.coalitionInfo?.tributeToReceive.forEach(tribute => {
                Object.keys(TRIBUTE_OBJECT).forEach(resource => {
                    TRIBUTE_OBJECT[resource] += tribute[resource];
                });
            });
            // round down to the nearest integer
            Object.keys(TRIBUTE_OBJECT).forEach(resource => {
                TRIBUTE_OBJECT[resource] = Math.floor(TRIBUTE_OBJECT[resource]);
            });
            return TRIBUTE_OBJECT;
        },
        maxStorage() {
            const StorageBuilding = this.buildings.find(building => building.name === BuildingIdentifier.STORAGE);
            if (!StorageBuilding) {
                return 0;
            }
            return StorageBuilding.perLevel[StorageBuilding.level];
        },
        occupiedStorage() {
            return this.resources.aluminum + this.resources.oil + this.resources.metal + this.resources.rations;
        },
        maxPopulation() {
            const Farm = this.buildings.find(building => building.name === BuildingIdentifier.FARM);
            if (!Farm) {
                return 0;
            }
            return Farm.perLevel[Farm.level];
        },
        occupiedPopulation() {
            let Population = 0;
            this.buildings.forEach(building => {
                Population += building?.population[building?.level];
            });
            return Population;
        },
        numberOfOwnedCities() {
            return this.allUserCities.length;
        },
    },
    created() {
        if (window) {
            window.addEventListener('resize', this.onResize);
            this.windowHeight = window.innerHeight;
        }
    },
    watch: {
        isHoverOnTabs: {
            handler() {
                this.$emit('hover-changed', this.isHoverOnTabs);
            },
        },
    },
    methods: {
        ...mapActions(['logout', 'setCurrentCityAction']),
        ...mapGetters(['getSuccessfulLogout']),

        setCurrentCityCommand({ city_id, city_index }) {
            this.setCurrentCityAction({ city_id, city_index });
        },

        toKebabCase(string) {
            return paramCase(string);
        },
        openMap() {
            if (!this.mapOpened) {
                this.$emit('open-map');
            }
        },
        closeMap() {
            this.$emit('map-centered');
            setTimeout(() => {
                this.$emit('close-map');
            }, 1000);
        },
        onResize() {
            this.windowHeight = window.innerHeight;
            if (this.windowHeight < 700) {
                this.isChatVisible = false;
                this.isMobileLeftTabVisible = false;
                this.isChatVisibleExclusive = false;
                this.isMobileLeftTabVisibleExclusive = false;
            }
        },
        emitScrollCoords(coords) {
            this.$emit('minimap-scroll', coords);
        },
        toggleChatWindow() {
            this.isChatVisible = !this.isChatVisible;
        },
        toggleSoldierTab() {
            this.isSoldierTabVisible = !this.isSoldierTabVisible;
        },
        toggleUnitTab() {
            this.isUnitTabVisible = !this.isUnitTabVisible;
        },
        toggleResourcesDropdown() {
            this.isResourcesDropdownVisible = !this.isResourcesDropdownVisible;
        },
        toggleMenuDropdown() {
            this.isMenuDropdownVisible = !this.isMenuDropdownVisible;
        },
        toggleCitiesDropdown() {
            this.isCitiesDropdownVisible = !this.isCitiesDropdownVisible;
        },
        toggleMobileRightTab() {
            this.isMobileRightTabVisible = !this.isMobileRightTabVisible;
        },
        toggleConstructionTab() {
            this.isConstructionTabVisible = !this.isConstructionTabVisible;
        },
        toggleRecruitmentTab() {
            this.isRecruitmentTabVisible = !this.isRecruitmentTabVisible;
        },
        toggleMapActionsTab() {
            this.isMapActionsTabVisible = !this.isMapActionsTabVisible;
        },
        toggleMobileLeftTab() {
            this.isMobileLeftTabVisible = !this.isMobileLeftTabVisible;
        },
        toggleChatExclusive() {
            if (this.windowHeight < 700) {
                this.isChatVisibleExclusive = !this.isChatVisibleExclusive;
            }
        },
        toggleMobileLeftTabExclusive() {
            if (this.windowHeight < 700) {
                this.isMobileLeftTabVisibleExclusive = !this.isMobileLeftTabVisibleExclusive;
            }
        },
        removeCookiesAndGoToLogin() {
            this.logout().then(() => {
                if (this.getSuccessfulLogout()) {
                    this.$router.push('/login');
                }
            });
        },
        toSnakeCase(string) {
            return snakeCase(string);
        },
        getNextLevelBuildingAppearance(name) {
            const building = this.buildings.filter(bld => bld.name === name)[0];
            if (building) {
                for (let i = building?.appearance?.length; i >= 0; i--) {
                    if (building.level + 1 >= building.appearance[i]) {
                        return i + 2;
                    }
                }
            }
            return 1;
        },
        getTroopFromRecruitmentCommand(troop_name) {
            const troops = [
                ...this.unitTypes.soldiers,
                ...this.unitTypes.tanks,
                ...this.unitTypes.planes,
                ...this.unitTypes.other,
            ];
            return troops.find(troop => troop.name === troop_name);
        },
        openTroopDetailsModal(troop) {
            if (troop) {
                this.setCurrentTroop(troop);
            }
            document.getElementById('troop-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('troop-details-modal').style.opacity = '0';
            document.getElementById('troop-details-modal').style.display = 'block';
            document.getElementById('troop-details-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('troop-details-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('troop-details-modal').style.transform = 'translateY(0)';
                document.getElementById('troop-details-modal').style.opacity = '1';
            }, 0);
        },
        openBuildingDetailsModal(building) {
            if (building) {
                this.setCurrentBuilding(building);
            }
            document.getElementById('building-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('building-details-modal').style.opacity = '0';
            document.getElementById('building-details-modal').style.display = 'block';
            document.getElementById('building-details-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('building-details-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('building-details-modal').style.transform = 'translateY(0)';
                document.getElementById('building-details-modal').style.opacity = '1';
            }, 0);
        },
        openMapActionModal(map_action) {
            if (map_action) {
                this.viewedAction = map_action.originalData;
            }
            document.getElementById('map-action-modal').style.transform = 'translateY(-20px)';
            document.getElementById('map-action-modal').style.opacity = '0';
            document.getElementById('map-action-modal').style.display = 'block';
            document.getElementById('map-action-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('map-action-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('map-action-modal').style.transform = 'translateY(0)';
                document.getElementById('map-action-modal').style.opacity = '1';
                document.body.classList.add('modal-open');
            }, 0);
        },
        getBuildingFromConstructionCommand(building_name) {
            return this.buildings.find(building => building.name === building_name);
        },
        // to be used temporarily
        getCityNameFromId(city_id) {
            return this.allCities.length ? this.allCities.find(city => city._id === city_id)?.name : '';
        },
    },
};
</script>

<style scoped>
.UI-minimap-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    top: 100px;
    right: 32px;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    padding: 1rem;
}

.UI-minimap {
    margin: 0 auto;
}

.map-legend,
.find-city-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.legend-entry {
    padding-left: 1.5rem;
    position: relative;
    font-size: 0.75rem;
    line-height: 0.75rem;
}

.legend-entry::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.5rem;
    border-radius: 50%;
}

.current::before {
    background: gold;
}

.own::before {
    background: palegoldenrod;
}

.coalition::before {
    background: royalblue;
}

.ally::before {
    background: lightskyblue;
}

.nap::before {
    background: mediumpurple;
}

.enemy::before {
    background: indianred;
}

.friend::before {
    background: darkseagreen;
}

.abandoned::before {
    background: gray;
}

.other::before {
    background: ghostwhite;
}

.row {
    margin: 0;
    flex-wrap: nowrap;
    gap: 2px;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
    min-height: 2rem;
    color: ghostwhite;
}

.form-check {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    line-height: 1;
}

.form-check-input {
    cursor: pointer;
    min-width: 14px;
}

.form-check-input:checked {
    background-color: #d34848;
    border: none;
    background-size: 10px;
}

.form-check-input:focus {
    box-shadow: none;
}

.form-control {
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
    height: 2rem;
    margin: 0.25rem 0;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
    box-shadow: none;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

/* --- TOP BAR --- */
.mobile-left-tab-wrapper.hidden-exclusive {
    margin-left: -48px;
    transition: margin 0.3s;
}

.chat-window-wrapper.hidden-exclusive {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
}

.city-info-wrapper {
    position: fixed;
    z-index: 1;
    top: 8px;
    right: 260px;
    font-family: 'sansation', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    color: ghostwhite;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.cities-wrapper {
    display: flex;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: burlywood;
}

.cities-count {
    color: ghostwhite;
}

.current-city-wrapper {
    display: flex;
    flex-direction: row;
    min-width: 12rem;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    background: black;
    border: 1px solid #434343;
    align-items: center;
    justify-content: center;
    font-style: italic;
    cursor: pointer;
    transition: all 0.15s;
    /*opacity: 0.8;*/
}

.current-city-wrapper:hover {
    /*opacity: 1;*/
    background: lightgray;
    border-color: black;
    color: black;
}

.top-bar-wrapper {
    position: fixed;
    display: flex;
    top: 18px;
    left: 32px;
    z-index: 1;
    gap: 1rem;
    font-family: 'sansation', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    color: ghostwhite;
}

.resource-group {
    display: flex;
    align-items: center;
    position: relative;
}

.icon-tooltip {
    z-index: 1;
    opacity: 0;
    filter: blur(3px);
    position: absolute;
    top: 2.5rem;
    pointer-events: none;
    transition: opacity 0.3s, filter 0.3s;
    background: black;
    color: ghostwhite;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.25rem;
    border: 1px solid #434343;
    width: max-content;
}

.tab-arrow-icon-wrapper .icon-tooltip {
    bottom: initial;
    top: -1.375rem;
    left: -3rem;
}

.unit-icon-wrapper .icon-tooltip {
    bottom: initial;
    top: -1.375rem;
}

.left-tab-icon-wrapper .icon-tooltip,
.mobile-left-tab-wrapper .icon-tooltip {
    bottom: initial;
    top: -1.375rem;
    right: -3rem;
}

.resource-group:hover .icon-tooltip,
.cities-wrapper:hover .icon-tooltip,
.tab-arrow-icon-wrapper:hover .icon-tooltip,
.unit-icon-wrapper:hover .icon-tooltip,
.left-tab-icon-wrapper:hover .icon-tooltip,
.mobile-left-tab-wrapper:hover .icon-tooltip {
    opacity: 1;
    filter: blur(0);
}

.resource-icon,
.city-icon,
.chat-icon {
    height: 1rem;
    margin-right: 0.5rem;
}

.resource-name {
    margin-right: 0.25rem;
    color: burlywood;
}

.resources-dropdown-button {
    position: relative;
    font-family: 'sansation', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.125rem 0.75rem;
    color: ghostwhite;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    display: none;
}

.resources-dropdown-button::before {
    content: '';
    position: absolute;
    top: -6px;
    right: -6px;
    display: block;
    width: 12px;
    height: 12px;
    background: darkred;
    transform: rotateZ(45deg);
}

.resources-dropdown-button.active::before {
    display: none;
}

.resources-dropdown-wrapper {
    position: fixed;
    top: 50px;
    left: 300px;
    background: rgba(0, 0, 0, 0.75);
    z-index: 2;
    display: none;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 1rem 0.5rem 0.5rem;
    font-size: 0.75rem;
    overflow: hidden;
}

.resources-dropdown-enter-active,
.resources-dropdown-leave-active {
    transition: filter 0.15s linear, opacity 0.15s ease;
}

.resources-dropdown-enter-from,
.resources-dropdown-leave-to {
    filter: blur(3px);
    opacity: 0;
}

.map-controls-wrapper {
    z-index: 1;
    position: absolute;
    top: 12px;
    right: 560px;
    display: flex;
    gap: 0.75rem;
}

.zoom-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border: 1px solid #434343;
    background: black;
    color: ghostwhite;
    cursor: pointer;
    transition: all 0.25s;
}

.center-map-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.zoom-button:hover {
    background: ghostwhite;
    color: black;
}

.zoom-button span {
    font-size: 1.75rem;
    transform: translateY(-2px);
    user-select: none;
}

.top-menu-wrapper {
    z-index: 1;
    position: fixed;
    top: 30px;
    right: 20px;
}

.top-menu-icon {
    height: 1.5rem;
    margin-right: 1.75rem;
    cursor: pointer;
}

.menu-dropdown-wrapper {
    width: 12.25rem;
    position: fixed;
    display: flex;
    top: 91px;
    right: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 2;
    /*display: none;*/
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    font-size: 0.75rem;
    overflow: hidden;
}

.cities-dropdown-wrapper {
    width: 10rem;
    position: fixed;
    display: flex;
    top: 57px;
    right: 22.5rem;
    background: rgba(0, 0, 0, 0.75);
    z-index: 2;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    font-size: 0.75rem;
    overflow: overlay;
    max-height: 13.25rem;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.cities-dropdown-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.cities-dropdown-wrapper::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.menu-item {
    color: ghostwhite;
    display: flex;
    flex-direction: row;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
    padding-bottom: 0.5rem;
    gap: 0.75rem;
    cursor: pointer;
    align-items: center;
}
.menu-item:last-child {
    border-bottom: none;
}

.menu-icon {
    height: 1.5rem;
}

.menu-item-name {
    line-height: 1.5rem;
}

.menu-dropdown-enter-active,
.menu-dropdown-leave-active {
    transition: filter 0.15s linear, opacity 0.15s ease;
}

.menu-dropdown-enter-from,
.menu-dropdown-leave-to {
    filter: blur(3px);
    opacity: 0;
}

.minimap-enter-active,
.minimap-leave-active {
    transition: filter 0.25s linear, opacity 0.25s ease-in-out;
}

.minimap-enter-from,
.minimap-leave-to {
    filter: blur(3px);
    opacity: 0;
}

/* --- BOTTOM BAR --- */

.bottom-menu-wrapper {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 1;
    display: flex;
    font-family: 'sansation', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    color: ghostwhite;
    gap: 1rem;
}

.bottom-icon-group {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: opacity 0.25s;
}

.bottom-icon-group.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}

.bottom-icon-group .spinner-border {
    position: absolute;
    top: 15%;
    width: 1.875rem;
    height: 1.875rem;
}

.bottom-menu-icon {
    width: 4.25rem;
    height: 4.25rem;
}

.server-selector-wrapper {
    position: fixed;
    bottom: 1.75rem;
    left: 16px;
    padding: 0 1.5rem;
    font-family: 'sansation', sans-serif;
    font-size: 0.75rem;
    color: lightgray;
    background: #1a1a1a;
    border: 1px solid #434343;
    letter-spacing: 0.05rem;
    z-index: 1;
    overflow: hidden;
}

.red-box {
    position: absolute;
    width: 12px;
    height: 12px;
    background: brown;
    top: -6px;
    right: -6px;
    transform: rotateZ(45deg);
}

/* --- CHAT WINDOW --- */

.chat-window-wrapper {
    position: fixed;
    bottom: 99px;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    min-width: 15rem;
    max-width: 25rem;
    z-index: 1;
    font-family: 'sansation', sans-serif;
    font-weight: 500;
    font-size: 0.6875rem;
    color: ghostwhite;
    pointer-events: auto;
    transition: opacity 0.3s;
}

.chat-header {
    padding: 0.5rem 1.5rem;
    cursor: pointer;
}

.chat-close-icon {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    height: 1rem;
}

.chat-body {
    height: 250px;
    padding: 1.5rem;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    overflow-y: overlay;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-bottom: none;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.chat-body::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.chat-body::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.post-group {
    display: flex;
    gap: 0.25rem;
    padding: 0.125rem;
}

.post-timestamp {
    color: dimgray;
    min-width: 3.5rem;
}

.post-author {
    color: burlywood;
}

.text-box-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.5rem;
}

.text-box {
    height: 1.5rem;
    width: 100%;
    resize: none;
    background: rgba(0, 0, 0, 0.35);
    outline: none;
    padding-left: 1rem;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    color: ghostwhite;
    border: 1px solid #434343;
}

.chat-post-icon {
    height: 1.5rem;
    padding: 0.375rem 0.5rem;
    background: black;
    border: 1px solid #434343;
    border-left: none;
    cursor: pointer;
}

/* --- SOLDIER / UNIT TABS --- */

.soldier-tab {
    top: 120px;
}

.unit-tab {
    bottom: 175px;
}

.mobile-right-tab {
    display: none !important;
}

.mobile-right-tab .tab-title:last-of-type {
    margin-top: 1rem;
}

.tab-wrapper {
    display: flex;
    flex-direction: row;
    z-index: 1;
    position: fixed;
    right: 0;
}

.tab-arrow-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 40px;
    background: rgba(0, 0, 0, 0.75);
    position: absolute;
    bottom: 0;
    left: -20px;
    cursor: pointer;
}

.tab-arrow-icon {
    height: 8px;
    transition: transform 0.3s;
}

.rotated {
    transform: rotateZ(180deg);
}

.tab-window {
    background: rgba(0, 0, 0, 0.75);
    padding: 16px;
    width: 146px;
    transition: margin 0.3s;
}

.mobile-right-tab-window {
    background: rgba(0, 0, 0, 0.75);
    padding: 16px;
    width: 200px;
    transition: margin 0.3s;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.mobile-right-tab-window::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.mobile-right-tab-window::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.hidden-right {
    margin-right: -200px;
}

.tab-title {
    display: block;
    font-family: 'sansation', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    color: ghostwhite;
    padding-bottom: 0.625rem;
}

.icons-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.unit-icon-wrapper {
    position: relative;
    cursor: pointer;
}

.unit-icon-wrapper > span {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    min-width: 17px;
    height: 16px;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.625rem;
    color: ghostwhite;
    align-items: center;
    justify-content: center;
    background: darkred;
    padding: 0 0.125rem;
}

.unit-icon {
    height: 64px;
    width: 34px;
}

/* --- LEFT TABS --- */

.left-tabs-wrapper {
    z-index: 1;
    position: absolute;
    top: 80px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    pointer-events: none;
    margin-left: -500px;
}

.mobile-left-tab-wrapper {
    z-index: 1;
    transition: transform 0.3s, margin 0.3s;
    width: 500px;
    min-height: 48px;
    display: none;
    margin-left: 0;
    background: rgba(0, 0, 0, 0.75);
    padding: 8px 8px 8px 12px;
    position: absolute;
    left: 0;
    top: 80px;
}

.mobile-left-tab-window {
    max-height: 164px;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    overflow-y: overlay;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.mobile-left-tab-window::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.mobile-left-tab-window::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.hidden-left .mobile-left-tab-window {
    max-height: 32px;
}

.separator {
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.left-tab-wrapper {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
}

.left-tab-window {
    position: relative;
    width: 500px;
    background: rgba(0, 0, 0, 0.75);
    padding: 8px 8px 8px 12px;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    overflow-x: overlay;
    transition: transform 0.3s;
    max-height: 92px;
    min-height: 48px;
    pointer-events: initial;
    margin-left: 0;
    transform: translateX(500px);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.left-tab-window::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.left-tab-window::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.hidden-left {
    max-height: 48px;
    transform: translateX(-500px);
}

.command-target-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 8rem;
}

.command-target {
    color: burlywood;
    cursor: pointer;
}

.command-target:hover {
    color: cornflowerblue;
}

/*.map-actions-city-icon-wrapper + .command-target::before {*/
/*  content: "Attack on";*/
/*  display: block;*/
/*  color: ghostwhite;*/
/*  cursor: default;*/
/*}*/

.combined-left-tab-icon-wrapper {
    display: none;
    width: 48px;
    height: 48px;
    background: rgba(0, 0, 0, 0.75);
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: -48px;
    cursor: pointer;
    transition: transform 0.3s;
    pointer-events: initial;
}

.left-tab-icon-wrapper {
    width: 48px;
    height: 48px;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: -48px;
    cursor: pointer;
    transition: transform 0.3s;
    pointer-events: initial;
}

.left-tab-icon-wrapper.hidden-left {
    transform: translateX(500px);
}

.left-tab-icon {
    height: 1.5rem;
}

.command-group {
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: ghostwhite;
    display: flex;
    gap: 0.75rem;
    align-items: center;
}

.unit-recruitment-icon-wrapper {
    height: 2rem;
    width: 2rem;
    overflow: hidden;
    flex-shrink: 0;
    cursor: pointer;
}

.construction-command-icon {
    cursor: pointer;
}

.construction-command-icon,
.unit-recruitment-icon-wrapper > img {
    width: 2rem;
}

.map-actions-city-icon-wrapper {
    min-width: 3.5rem;
}

.map-actions-city-icon {
    height: 2rem;
}

.command-target {
    width: 8rem;
}

.map-actions-tab .command-target {
    width: 8rem;
}

.command-modifier {
    width: 2rem;
}

.command-duration {
    width: 5.5rem;
}

.command-finish {
    width: 8.75rem;
}

@media screen and (max-width: 1720px) {
    .top-bar-wrapper {
        top: 12px;
    }
    .resource-group {
        flex-direction: column;
        gap: 0.25rem;
    }
    .city-info-wrapper {
        gap: 1.5rem;
    }
    .cities-wrapper {
        flex-direction: column;
        gap: 0.25rem;
    }
}

@media screen and (max-width: 1560px) {
    .resource-group {
        color: burlywood;
    }
    .resource-icon {
        margin: 0;
    }
    .resource-name {
        display: none;
    }
    .city-icon {
        margin: 0;
    }
    .cities-count {
        color: burlywood;
    }
    .cities-dropdown-wrapper {
        right: 20rem;
    }
    .map-controls-wrapper {
        right: 510px;
    }
}

@media screen and (max-width: 1279px) {
    .top-bar-wrapper {
        top: 8px;
    }
    .city-info-wrapper {
        top: 8px;
        right: 230px;
    }
    .current-city-wrapper {
        font-size: 0.75rem;
        padding: 0.375rem 0.5rem;
        min-width: 10rem;
    }
    .top-menu-wrapper {
        top: 20px;
        right: 10px;
    }
    .bottom-menu-wrapper {
        bottom: 10px;
        right: 45px;
    }
    .bottom-menu-icon {
        height: 4rem;
        width: 4rem;
    }
    .bottom-icon-group .spinner-border {
        top: 14.25%;
        width: 1.75rem;
        height: 1.75rem;
    }
    .server-selector-wrapper {
        left: 8px;
        bottom: 0.5rem;
    }
    .chat-window-wrapper {
        bottom: 75px;
    }
    .menu-dropdown-wrapper {
        top: 81px;
    }
    .cities-dropdown-wrapper {
        right: 17.25rem;
        top: 50px;
    }
    .map-controls-wrapper {
        top: 8px;
        right: 450px;
    }
}

@media screen and (max-width: 1160px) {
    .top-bar-wrapper,
    .cities-wrapper {
        display: none;
    }
    .resources-dropdown-button {
        display: flex;
    }
    .city-info-wrapper {
        right: unset;
        left: 300px;
    }
    .resource-group {
        color: ghostwhite;
        flex-direction: row;
        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
        border-left: none;
        border-right: none;
        border-top: none;
        padding-bottom: 0.5rem;
    }
    .resource-group:last-child {
        border-bottom: none;
    }
    .resource-info {
        display: flex;
        flex-direction: row;
    }
    .resource-icon {
        min-width: 3rem;
    }
    .resource-name {
        display: flex;
        min-width: 6rem;
    }
    .resources-dropdown-wrapper {
        display: flex;
    }
    .city-icon {
        margin-right: 0.25rem;
    }
    .cities-count {
        color: ghostwhite;
    }
    .chat-window-wrapper {
        max-width: 30%;
    }
    .cities-dropdown-wrapper {
        right: unset;
        left: 26rem;
    }
    .map-controls-wrapper {
        right: unset;
        left: 595px;
    }
}

@media screen and (max-width: 920px) {
    .city-info-wrapper {
        top: 4px;
        left: 240px;
    }
    .top-menu-wrapper {
        right: 20px;
    }
    .top-menu-icon {
        margin-right: 0.75rem;
    }
    .bottom-menu-wrapper {
        bottom: 10px;
        right: 45px;
    }
    .bottom-menu-icon {
        height: 3.25rem;
        width: 3.25rem;
    }
    .bottom-icon-group .spinner-border {
        top: 14.25%;
        width: 1.375rem;
        height: 1.375rem;
        border-width: 0.125rem;
    }
    .resources-dropdown-wrapper {
        top: 44px;
        left: 240px;
    }
    .server-selector-wrapper {
        display: none;
    }
    .chat-window-wrapper {
        bottom: 63px;
        min-width: 8.5rem;
        max-width: 16rem;
    }
    .menu-dropdown-wrapper {
        top: 71px;
        width: 10.75rem;
    }
    .cities-dropdown-wrapper {
        top: 45px;
        left: 22.125rem;
    }
    .UI-minimap-wrapper {
        display: none;
    }
    .map-controls-wrapper {
        top: 40vh;
        right: 8px;
        left: unset;
        flex-direction: column;
    }
    .zoom-button {
        width: 1.75rem;
        height: 1.75rem;
    }
}

@media screen and (max-width: 840px) {
    .city-info-wrapper {
        left: 0;
    }
    .cities-dropdown-wrapper {
        left: 7.25rem;
    }
}

@media screen and (max-width: 768px) {
    .resources-dropdown-wrapper {
        top: 44px;
        left: 0;
    }
    .bottom-menu-wrapper {
        width: 100%;
        right: unset;
        bottom: 4px;
        justify-content: center;
        font-size: 0.675rem;
    }
    .bottom-icon-group {
        gap: 0.25rem;
    }
    .bottom-menu-icon {
        height: 2.875rem;
        width: 2.875rem;
    }
    .bottom-icon-group .spinner-border {
        top: 14.5%;
        width: 1.25rem;
        height: 1.25rem;
    }
    .chat-window-wrapper {
        bottom: 80px;
    }
    .mobile-right-tab-window {
        width: 121px;
        height: 442px;
        overflow-y: overlay;
        scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
        scrollbar-width: thin;
    }
    .mobile-right-tab-window::-webkit-scrollbar {
        width: 6px;
        height: 0;
        background-color: rgba(0, 0, 0, 0);
    }
    .mobile-right-tab-window::-webkit-scrollbar-thumb {
        background-color: dimgray;
        border-radius: 10px;
    }
    .hidden-right {
        margin-right: -186px;
    }
    .icon-tooltip {
        display: none;
    }
}

@media screen and (max-width: 660px) {
    .mobile-left-tab-wrapper {
        width: 400px;
    }
    .hidden-left {
        transform: translateX(-400px);
    }
    .command-finish {
        width: 5rem;
    }
}

@media screen and (max-width: 640px) {
    .top-menu-icon {
        height: 1rem;
    }
    .top-menu-icon:not(:last-child) {
        display: none;
    }
    .top-menu-wrapper {
        top: 10px;
        right: 10px;
    }
    .city-info-wrapper {
        top: 10px;
    }
    .resources-dropdown-wrapper {
        top: 56px;
    }
    .menu-dropdown-wrapper {
        top: 56px;
    }
    .cities-dropdown-wrapper {
        top: 56px;
    }
}

@media screen and (max-width: 560px) {
    .left-tab-icon-wrapper,
    .left-tabs-wrapper {
        display: none;
    }
    .mobile-left-tab-wrapper {
        display: block;
        width: 300px;
        padding: 8px 4px 8px 4px;
    }
    .combined-left-tab-icon-wrapper {
        display: flex;
    }
    .hidden-left {
        transform: translateX(-300px);
    }
    .command-group {
        gap: 0.5rem;
    }
    .command-target-wrapper {
        display: unset;
        flex-direction: unset;
        min-width: unset;
        max-width: 6rem;
    }
}

@media screen and (max-width: 450px) {
    .top-menu-wrapper {
        top: 12px;
    }
    .top-menu-icon {
        height: 0.875rem;
    }
    .city-info-wrapper {
        gap: 0.5rem;
    }
    .bottom-menu-wrapper {
        font-size: 0.625rem;
        gap: 0.75rem;
        bottom: 10px;
    }
    .bottom-icon-group {
        gap: 0.125rem;
    }
    .bottom-icon-group .spinner-border {
        top: 14.25%;
        width: 1.125rem;
        height: 1.125rem;
    }
    .bottom-menu-icon {
        height: 2.5rem;
        width: 2.5rem;
    }
    .command-finish {
        display: none;
    }
    .mobile-left-tab-wrapper {
        width: 200px;
    }
    .hidden-left {
        transform: translateX(-200px);
    }
    .command-group {
        font-size: 0.625rem;
    }
    .construction-command-icon,
    .unit-recruitment-icon-wrapper > img {
        width: 1.75rem;
    }
    .map-actions-city-icon-wrapper {
        min-width: 2.5rem;
    }
    .map-actions-city-icon {
        height: 1.5rem;
    }
    .cities-dropdown-wrapper {
        left: 6.25rem;
    }
}

@media screen and (max-width: 380px) {
    .bottom-menu-wrapper {
        font-size: 0.5rem;
        gap: 0.5rem;
        bottom: 12px;
    }
    .bottom-icon-group > span {
        display: none;
    }
    .bottom-icon-group .spinner-border {
        top: 20%;
        width: 1rem;
        height: 1rem;
    }
    .bottom-menu-icon {
        height: 2.25rem;
        width: 2.25rem;
    }
    .chat-window-wrapper {
        bottom: 64px;
        font-size: 0.625rem;
        max-width: 12rem;
    }
    .chat-body {
        padding: 0.5rem;
        height: 200px;
    }
    .text-box-wrapper {
        padding: 1rem 0.5rem;
    }
    .post-timestamp {
        min-width: 2.5rem;
        font-size: 8px;
        font-weight: 600;
        margin-top: 2px;
    }
    .mobile-right-tab {
        bottom: 80px;
    }
    .current-city-wrapper {
        min-width: 7rem;
    }
    .current-city-wrapper span {
        font-size: 0.625rem;
        width: 4rem;
        height: 1rem;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .resources-dropdown-button {
        font-size: 0.625rem;
    }
}

@media screen and (max-width: 310px) {
    .bottom-menu-wrapper {
        gap: 0.375rem;
    }
    .bottom-icon-group .spinner-border {
        top: 19%;
        width: 0.9375rem;
        height: 0.9375rem;
    }
    .bottom-menu-icon {
        height: 2rem;
        width: 2rem;
    }
}

@media screen and (max-height: 920px) {
    .left-tab-icon-wrapper,
    .left-tabs-wrapper {
        display: none;
    }
    .mobile-left-tab-wrapper {
        display: block;
    }
    .combined-left-tab-icon-wrapper {
        display: flex;
    }
    .soldier-tab,
    .unit-tab {
        display: none;
    }
    .mobile-right-tab {
        display: block !important;
        bottom: 175px;
    }
}

@media screen and (max-height: 820px) {
    .UI-minimap-wrapper {
        width: 20rem;
    }
    .UI-minimap {
        transform: scale(0.8);
        margin-top: -2rem;
        margin-bottom: -2rem;
    }
}

@media screen and (max-height: 750px) {
    .UI-minimap-wrapper {
        width: 15.5rem;
    }
    .UI-minimap {
        transform: scale(0.6);
        margin-top: -3.5rem;
        margin-bottom: -3.5rem;
    }
    .map-legend .col {
        padding: 0.125rem 0.25rem;
    }
    .legend-entry {
        font-size: 0.625rem;
        padding-left: 0.875rem;
    }
    .legend-entry::before {
        width: 0.625rem;
        height: 0.625rem;
    }
    .form-check-label {
        font-size: 0.625rem;
    }
}

@media screen and (max-height: 719px) {
    .mobile-right-tab {
        bottom: 150px;
    }
}

@media screen and (max-height: 700px) {
    .UI-minimap {
        display: none;
    }
}

@media screen and (max-height: 660px) {
    .mobile-right-tab-window {
        height: calc(100vh - 250px);
        overflow-y: overlay;
        scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
        scrollbar-width: thin;
    }
    .mobile-right-tab-window::-webkit-scrollbar {
        width: 6px;
        height: 0;
        background-color: rgba(0, 0, 0, 0);
    }
    .mobile-right-tab-window::-webkit-scrollbar-thumb {
        background-color: dimgray;
        border-radius: 10px;
    }
}

@media screen and (max-height: 550px) {
    .UI-minimap-wrapper {
        padding: 0.375rem;
        gap: 0.375rem;
    }
}

@media screen and (max-height: 520px) {
    .UI-minimap-wrapper {
        display: none;
    }
}

@media screen and (max-height: 620px) and (max-width: 767px) {
    .mobile-right-tab-window {
        height: calc(100vh - 150px);
        overflow-y: overlay;
        scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
        scrollbar-width: thin;
    }
    .mobile-right-tab-window::-webkit-scrollbar {
        width: 6px;
        height: 0;
        background-color: rgba(0, 0, 0, 0);
    }
    .mobile-right-tab-window::-webkit-scrollbar-thumb {
        background-color: dimgray;
        border-radius: 10px;
    }
    .mobile-right-tab {
        bottom: 84px;
    }
}
</style>
