import { ActionTypeIdentifier, CityPropertiesThatAreActions } from '@/utils/enums/actions';
import MapAction from '@/classes/MapAction';

export default class MapActionFactory {
    currentCityId = null;

    constructor() {}

    setCurrentCityId(city_id) {
        this.currentCityId = city_id;
    }

    createAction({ action_data, property_name }) {
        const FromCityId = typeof action_data.fromCity === 'object' ? action_data.fromCity._id : action_data.fromCity;

        switch (property_name) {
            case CityPropertiesThatAreActions.ATTACKS: {
                if (FromCityId === this.currentCityId) {
                    return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.OUTGOING_ATTACK });
                } else {
                    return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.INCOMING_ATTACK });
                }
            }
            case CityPropertiesThatAreActions.RETURNING_ATTACKS: {
                return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.RETURNING_ATTACK });
            }
            case CityPropertiesThatAreActions.PATROLS: {
                return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.PATROL });
            }
            case CityPropertiesThatAreActions.INTERCEPTS: {
                if (FromCityId === this.currentCityId) {
                    return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.OUTGOING_INTERCEPT });
                } else {
                    return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.INCOMING_INTERCEPT });
                }
            }
            case CityPropertiesThatAreActions.RETURNING_INTERCEPTS: {
                return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.RETURNING_INTERCEPT });
            }
            case CityPropertiesThatAreActions.REINFORCEMENTS: {
                if (action_data.arrived === true) {
                    return null;
                }

                if (FromCityId === this.currentCityId) {
                    return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.OUTGOING_REINFORCEMENT });
                } else {
                    return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.INCOMING_REINFORCEMENT });
                }
            }
            case CityPropertiesThatAreActions.RETURNING_REINFORCEMENTS: {
                return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.RETURNING_REINFORCEMENT });
            }
            case CityPropertiesThatAreActions.SPY_MISSIONS: {
                if (FromCityId === this.currentCityId) {
                    return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.OUTGOING_SPY_MISSION });
                } else {
                    return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.INCOMING_SPY_MISSION });
                }
            }
            case CityPropertiesThatAreActions.RETURNING_SPY_MISSIONS: {
                return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.RETURNING_SPIES });
            }
            case CityPropertiesThatAreActions.DETECT_SPIES_MISSIONS: {
                return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.DETECT_SPIES_MISSION });
            }
            case CityPropertiesThatAreActions.TRANSPORTS: {
                if (action_data.isOffer) {
                    if (FromCityId === this.currentCityId) {
                        return new MapAction({
                            action: action_data,
                            action_type: ActionTypeIdentifier.OUTGOING_TRADING_TRANSPORT,
                        });
                    } else {
                        return new MapAction({
                            action: action_data,
                            action_type: ActionTypeIdentifier.INCOMING_TRADING_TRANSPORT,
                        });
                    }
                } else {
                    if (FromCityId === this.currentCityId) {
                        return new MapAction({
                            action: action_data,
                            action_type: ActionTypeIdentifier.OUTGOING_SEND_RESOURCES_TRANSPORT,
                        });
                    } else {
                        return new MapAction({
                            action: action_data,
                            action_type: ActionTypeIdentifier.INCOMING_SEND_RESOURCES_TRANSPORT,
                        });
                    }
                }
            }
            case CityPropertiesThatAreActions.RETURNING_TRANSPORTS: {
                if (action_data.isOffer) {
                    return new MapAction({ action: action_data, action_type: ActionTypeIdentifier.RETURNING_TRADING_TRANSPORT });
                } else {
                    return new MapAction({
                        action: action_data,
                        action_type: ActionTypeIdentifier.RETURNING_SEND_RESOURCES_TRANSPORT,
                    });
                }
            }
        }
    }
}
