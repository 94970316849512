// any object wrapped with an arrayWrapper function will
// be turned into an empty array by this parser

export default function parseTemplateForInitialization(state_template) {
    const CURRENT_KEYS = Object.keys(state_template);
    const LAYER_OBJECT = {};

    CURRENT_KEYS.forEach(key => {
        if (typeof state_template[key] === 'object' && !Array.isArray(state_template[key])) {
            LAYER_OBJECT[key] = parseTemplateForInitialization(state_template[key]);
        } else if (Array.isArray(state_template[key])) {
            LAYER_OBJECT[key] = [];
        } else {
            LAYER_OBJECT[key] = state_template[key];
        }
    });

    return LAYER_OBJECT;
}
