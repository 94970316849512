<template>
    <div id="credentials-page">
        <form v-if="!showMessage" id="recovery-form" class="row g-3 col-xl-4 col-lg-4 col-md-6 col-sm-8 col-10" novalidate>
            <p v-if="username" class="username-greeting">
                Hello, <span>{{ username }}</span> !
            </p>
            <div class="form-group">
                <label for="newPassword" :class="{ invalid: newPasswordErrors.length !== 0 }" class="form-label"
                    >New Password:</label
                >
                <input
                    type="password"
                    :class="{ invalid: newPasswordErrors.length !== 0 }"
                    class="form-control"
                    name="newPassword"
                    id="newPassword"
                    v-model="v$.newPassword.$model"
                    @input.prevent="resetErrorList('newPasswordErrors')"
                    required
                />
                <p v-for="error of this.filterNewPasswordErrors()" :key="error.uid" class="form-error">
                    {{ error.message }}
                </p>
            </div>
            <div class="form-group">
                <label for="repeatPassword" :class="{ invalid: repeatPasswordErrors.length !== 0 }" class="form-label"
                    >Repeat Password:</label
                >
                <input
                    type="password"
                    :class="{ invalid: repeatPasswordErrors.length !== 0 }"
                    class="form-control"
                    name="repeatPassword"
                    id="=repeatPassword"
                    v-model="v$.repeatPassword.$model"
                    @input.prevent="resetErrorList('repeatPasswordErrors')"
                    required
                />
                <p v-for="error of this.filterRepeatPasswordErrors()" :key="error.uid" class="form-error">
                    {{ error.message }}
                </p>
            </div>
            <div class="buttons-wrapper">
                <button class="btn reset-password-button" @click.prevent="resetPassword()">Reset Password</button>
                <router-link to="/" tag="button">
                    <button class="btn return-button">Go to login</button>
                </router-link>
            </div>
        </form>
        <div v-else>
            <div v-if="changePasswordResponse === undefined" class="spinner-border text-light m-4" role="status" />
            <div v-else class="show-message">
                <span v-if="changePasswordResponse"> Password changed successfully. </span>
                <span v-else> Something went wrong while changing your password. Please try again later. </span>
                <router-link to="/" tag="button">
                    <button class="btn return-button">Go to login</button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength, helpers } from '@vuelidate/validators';

export default {
    name: 'CredentialsPage',
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            newPassword: '',
            repeatPassword: '',
            newPasswordErrors: [],
            repeatPasswordErrors: [],
            showMessage: false,
        };
    },
    computed: {
        token() {
            return this.$route.query.token;
        },
        username() {
            return this.$route.query.username;
        },
        changePasswordResponse() {
            return this.getChangePasswordResponse();
        },
    },
    validations() {
        return {
            newPassword: {
                required: helpers.withMessage('Field cannot be empty', required),
                passwordMatch: helpers.withMessage('Password fields need to match', this.checkPasswordsMatch),
                minLengthValue: helpers.withMessage('Password cannot be less than 8 characters', minLength(3)),
                maxLengthValue: helpers.withMessage('Password cannot be longer than 32 characters', maxLength(32)),
                $lazy: true,
            },
            repeatPassword: {
                required: helpers.withMessage('Field cannot be empty', required),
                passwordMatch: helpers.withMessage('Password fields need to match', this.checkPasswordsMatch),
                minLengthValue: helpers.withMessage('Password cannot be less than 8 characters', minLength(3)),
                maxLengthValue: helpers.withMessage('Password cannot be longer than 32 characters', maxLength(32)),
                $lazy: true,
            },
        };
    },
    methods: {
        ...mapActions(['changePassword']),
        ...mapGetters(['getChangePasswordResponse']),
        resetPassword() {
            this.newPasswordErrors = [];
            this.repeatPasswordErrors = [];

            this.v$.$touch();

            if (this.v$.$errors.length) {
                if (this.v$.newPassword.$errors.length) {
                    for (let err of this.v$.newPassword.$errors) {
                        this.newPasswordErrors.push({
                            message: err.$message,
                            uid: err.$uid,
                        });
                    }
                }
                if (this.v$.repeatPassword.$errors.length) {
                    for (let err of this.v$.repeatPassword.$errors) {
                        this.repeatPasswordErrors.push({
                            message: err.$message,
                            uid: err.$uid,
                        });
                    }
                }
                return;
            }
            this.changePassword({
                credentialsToken: this.token,
                password: this.newPassword,
            }).then(() => {
                this.showMessage = true;
            });
        },

        checkPasswordsMatch() {
            return this.newPassword === this.repeatPassword;
        },

        resetErrorList(field) {
            switch (field) {
                case 'newPasswordErrors':
                    if (this.newPasswordErrors) return (this.newPasswordErrors = []);
                    break;
                case 'repeatPasswordErrors':
                    if (this.repeatPasswordErrors) return (this.repeatPasswordErrors = []);
                    break;
            }
        },
        filterNewPasswordErrors() {
            if (
                this.newPasswordErrors.find(error => error.uid === 'newPassword-passwordMatch') &&
                this.newPasswordErrors.length > 1
            ) {
                return this.newPasswordErrors.filter(error => error.uid !== 'newPassword-passwordMatch');
            } else {
                return this.newPasswordErrors;
            }
        },
        filterRepeatPasswordErrors() {
            if (
                this.repeatPasswordErrors.find(error => error.uid === 'repeatPassword-passwordMatch') &&
                this.repeatPasswordErrors.length > 1
            ) {
                return this.repeatPasswordErrors.filter(error => error.uid !== 'repeatPassword-passwordMatch');
            } else {
                return this.repeatPasswordErrors;
            }
        },
    },
};
</script>

<style scoped>
#credentials-page {
    position: absolute;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-family: 'sansation', sans-serif;
    font-weight: 500;
}

#recovery-form {
    background: rgba(0, 0, 0, 0.63);
    padding: 2rem;
}

.username-greeting {
    font-size: 1rem;
    font-weight: 500;
    color: whitesmoke;
}

.username-greeting span {
    font-weight: 500;
    color: lightcoral;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-label {
    font-size: 1rem;
    font-weight: 500;
    color: whitesmoke;
}

.form-control {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.form-error {
    margin: 0.25rem 0;
    color: #c72626;
}

.form-label.invalid {
    color: #c72626;
}

.form-control.invalid {
    border-color: #c72626;
}

.show-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.show-message span {
    font-size: 1rem;
    font-weight: 500;
    color: whitesmoke;
}

.buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 1rem;
    column-gap: 1rem;
    padding-top: 2rem;
}

.reset-password-button {
    font-family: 'bebas', sans-serif;
    width: 10rem;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
}

.reset-password-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.return-button {
    font-family: 'bebas', sans-serif;
    width: 10rem;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
}

.return-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}
</style>
