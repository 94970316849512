<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Settings</h5>
                    <div class="building-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs" id="settings-nav" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link active"
                                id="account"
                                data-bs-toggle="tab"
                                data-bs-target="#account-page"
                                type="button"
                                role="tab"
                                aria-controls="account-tab"
                                aria-selected="true"
                            >
                                Account
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="general-options"
                                data-bs-toggle="tab"
                                data-bs-target="#general-options-page"
                                type="button"
                                role="tab"
                                aria-controls="general-options-tab"
                                aria-selected="false"
                            >
                                General Options
                            </button>
                        </li>
                        <li class="nav-filler" />
                    </ul>
                    <div class="tab-content" id="settings-pages">
                        <div class="tab-pane fade show active" id="account-page" role="tabpanel" aria-labelledby="account-tab">
                            <div class="settings-section">
                                <span class="section-title"> Change Email </span>
                                <span class="section-description">
                                    Fill in the new email address and confirm the change by typing in your password.
                                </span>
                                <div class="settings-table">
                                    <div class="row">
                                        <div class="col">Current Email:</div>
                                        <div class="col">
                                            {{ emailRevealed ? email : hiddenEmail }}
                                            <span
                                                class="clickable"
                                                v-html="emailRevealed ? 'Hide Email' : 'Reveal Email'"
                                                @click="toggleEmailRevealed"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">New Email:</div>
                                        <div class="col">
                                            <input class="form-control" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">Account Password:</div>
                                        <div class="col">
                                            <input class="form-control" type="password" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <button class="btn yellow-button">Change Email</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="settings-section mt-5">
                                <span class="section-title"> Change Password </span>
                                <span class="section-description">
                                    Type in your current password and request a new one. The password will be changed on
                                    <i><strong>all servers</strong></i
                                    >.
                                </span>
                                <span class="section-description">
                                    <img src="../assets/icons/info_icon.svg" class="info-icon" />
                                    If you forgot your current password, you have the possibility of changing it to a new one.
                                    <span class="clickable">Click here!</span>
                                </span>
                                <div class="settings-table">
                                    <div class="row">
                                        <div class="col">Current Password:</div>
                                        <div class="col">
                                            <input class="form-control" type="password" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <button class="btn yellow-button">Request New Password</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="settings-section mt-5">
                                <span class="section-title"> Change Username </span>
                                <span class="section-description">
                                    Type in your current password and desired username. The change will apply on
                                    <i><strong>all servers</strong></i> and will be effective immediately. Also, don't forget to
                                    use your new username next time you log in.
                                </span>
                                <span class="section-description">
                                    <img src="../assets/icons/info_icon.svg" class="info-icon" />
                                    Once you confirm your username change, this option will be available again in
                                    <strong>180 days</strong>.
                                </span>
                                <div class="settings-table">
                                    <div class="row">
                                        <div class="col">New Username:</div>
                                        <div class="col">
                                            <input class="form-control" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">Current Password:</div>
                                        <div class="col">
                                            <input class="form-control" type="password" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <button class="btn yellow-button">Change Username</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="settings-section mt-5">
                                <span class="section-title"> Delete Account </span>
                                <span class="section-description">
                                    After confirming your account deletion you won't be able to log in anymore.
                                </span>
                                <span class="section-description">
                                    <img src="../assets/icons/info_icon.svg" class="info-icon" />
                                    Your account will only be deleted on the current server.
                                </span>
                                <div class="settings-table">
                                    <div class="row">
                                        <div class="col">Current Password:</div>
                                        <div class="col">
                                            <input class="form-control" type="password" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <button class="btn red-button">Delete Account</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="general-options-page"
                            role="tabpanel"
                            aria-labelledby="general-options-tab"
                        >
                            <div class="settings-table">
                                <div class="row label">
                                    <div class="col">Cookie Preferences</div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <input class="form-check-input" type="checkbox" checked disabled />
                                        <label class="form-check-label">
                                            Functional Cookies
                                            <span class="red-text"
                                                >(Aceste cookie-uri sunt strict necesare, iar aplicatia nu poate functiona fara
                                                ele)</span
                                            >
                                        </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <input class="form-check-input" type="checkbox" />
                                        <label class="form-check-label"> Marketing Cookies </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <button class="btn yellow-button">Confirm Changes</button>
                                    </div>
                                </div>
                            </div>
                            <div class="settings-table mt-4">
                                <div class="row label">
                                    <div class="col">Email Preferences</div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <input class="form-check-input" type="checkbox" />
                                        <label class="form-check-label"> Offer Emails </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <input class="form-check-input" type="checkbox" />
                                        <label class="form-check-label"> Event Emails </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <button class="btn yellow-button">Confirm Changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SettingsModal',
    data() {
        return {
            email: 'gigel.frone69@gmail.com',
            emailRevealed: false,
        };
    },
    computed: {
        hiddenEmail() {
            const emailFragment = this.email.split('@');

            const firstChar = emailFragment[0].charAt(0);
            const lastChar = emailFragment[0].charAt(emailFragment[0].length - 1);
            const asterisks = '*'.repeat(emailFragment[0].length - 2);

            return firstChar + asterisks + lastChar + '@' + emailFragment[1];
        },
    },
    created() {},
    methods: {
        closeModal() {
            document.getElementById('commercial-center-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('commercial-center-modal').style.transform = 'translateY(-20px)';
            document.getElementById('commercial-center-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('commercial-center-modal').style.display = 'none';
                document.getElementById('commercial-center-modal').classList.remove('show');
                document.getElementById('commercial-center-modal').style.removeProperty('transform');
                document.getElementById('commercial-center-modal').style.removeProperty('opacity');
            }, 150);
        },
        toggleEmailRevealed() {
            this.emailRevealed = !this.emailRevealed;
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
    font-size: 1.5rem;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 3rem 1rem 1rem 1rem;
}

.nav-tabs {
    border: none;
}

.nav-tabs .nav-link {
    border-radius: 0;
    border: 2px solid #434343;
    border-bottom: none;
    color: #afafaf;
    transform: translateY(15px);
}

.nav-tabs .nav-item {
    margin-right: 0.25rem;
}

.nav-tabs .nav-item:nth-last-child(2) {
    margin-right: 0;
}

.nav-tabs .nav-link:hover {
    color: lightgray;
    border: 2px solid #434343;
    border-bottom: none;
}

.nav-tabs .nav-link.active {
    background: none;
    border: 2px solid #434343;
    border-bottom: none;
    color: ghostwhite;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transform: translateY(-1px);
}

.nav-filler {
    flex-grow: 1;
    border-bottom: 2px solid #434343;
}

.tab-pane {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    background: none;
    border: 2px solid #434343;
    border-top: none;
}

.form-check-input {
    cursor: pointer;
    min-width: 0.875rem;
}

.form-check-input:checked {
    background-color: #d34848;
    border: none;
    background-size: 10px;
}

.form-check-input:focus {
    box-shadow: none;
}

.row {
    margin: 0;
    flex-wrap: nowrap;
    gap: 2px;
}

.label .col {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
    min-height: 2.5rem;
}

.btn {
    padding: 0.25rem;
    width: fit-content;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.form-control {
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
    height: 2rem;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.clickable {
    cursor: pointer;
    color: burlywood;
}

.clickable:hover {
    color: cornflowerblue;
}

.settings-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.section-description {
    font-size: 0.875rem;
    color: #afafaf;
}

.info-icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.125rem;
    transform: translateY(-0.125rem);
}

.settings-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.settings-table .col {
    flex-basis: 70%;
}

.settings-table .col:first-child {
    flex-basis: 30%;
}

.red-text {
    color: #ff7171;
}

@media screen and (max-width: 1199px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 1000px) {
}

@media screen and (max-width: 850px) {
    .col,
    .label .col {
        font-size: 0.75rem;
        min-width: 3rem;
        padding: 0.25rem;
    }
    .col {
        gap: 0.25rem;
    }
    .form-check-input {
        transform: scale(0.8);
    }
    .nav-link {
        font-size: 0.875rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .btn {
        font-size: 0.75rem;
        padding: 0.25rem;
    }
    .modal-content {
        padding: 4rem 0 1rem 0;
    }
    #account-page,
    #general-options-page {
        padding: 3rem 0.5rem 0.5rem 0.5rem;
    }
    .form-control {
        padding: 0.25rem;
        font-size: 0.75rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 96%;
    }
    .building-description {
        width: 100%;
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

@media screen and (max-width: 500px) {
    .nav-tabs .nav-item {
        margin-right: 0.125rem;
    }
    .nav-link {
        font-size: 0.75rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
