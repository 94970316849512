import axios from 'axios';
import City from '../City/index';

export default {
    state: {
        email: '',
        password: '',
        username: '',
        isUniqueEmail: false,
        isUniqueUsername: false,
        areCredentialsValid: false,
        attemptedLogin: false,
        emailValid: false,
        confirmAccountResponse: undefined,
        credentialsRecoveryResponse: undefined,
        changePasswordResponse: undefined,
        successfulLogout: false,
    },

    mutations: {
        setCredentials(state, data) {
            state.email = data.email;
            state.password = data.password;
            state.username = data.username;
        },

        setUniqueEmailBool(state, data) {
            state.isUniqueEmail = data;
        },

        setUniqueUsernameBool(state, data) {
            state.isUniqueUsername = data;
        },

        setAreCredentialsValid(state, data) {
            state.areCredentialsValid = data;
        },

        setAttemptedLogin(state, data) {
            state.attemptedLogin = data;
        },

        setEmailValid(state, data) {
            state.emailValid = data;
        },

        setLogoutState(state, data) {
            state.successfulLogout = data;
        },

        setConfirmAccountResponse(state, data) {
            state.confirmAccountResponse = data;
        },
        setCredentialsRecoveryResponse(state, data) {
            state.credentialsRecoveryResponse = data;
        },
        setChangePasswordResponse(state, data) {
            state.changePasswordResponse = data;
        },
    },

    actions: {
        async register({ commit }, userCredentials) {
            await axios
                .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/register`, { userCredentials }, { withCredentials: true })
                .then(response => {
                    if (!response.data) {
                        commit('setEmailValid', false);
                        return;
                    }
                    commit('setEmailValid', true);
                    City.state.id = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        async logout({ commit }) {
            await axios
                .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/logout`, {}, { withCredentials: true })
                .then(response => {
                    if (!response.data) {
                        commit('setLogoutState', false);
                        return;
                    }
                    commit('setLogoutState', true);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        async emailAlreadyExists({ commit }, { email }) {
            await axios
                .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/uniqueEmail`, {
                    email,
                })
                .then(response => {
                    commit('setUniqueEmailBool', response.data);
                });
        },

        async usernameAlreadyExists({ commit }, { username }) {
            await axios
                .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/uniqueUsername`, {
                    username,
                })
                .then(response => {
                    commit('setUniqueUsernameBool', response.data);
                });
        },

        async login({ commit }, userCredentials) {
            await axios
                .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/login`, userCredentials, { withCredentials: true })
                .then(response => {
                    if (!response.data) {
                        commit('setAreCredentialsValid', false);
                    } else {
                        commit('setAreCredentialsValid', true);
                        City.state.id = response.data;
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },

        async checkForToken({ commit }) {
            await axios
                .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/checkForToken`, {}, { withCredentials: true })
                .then(response => {
                    if (!response.data) {
                        commit('setAreCredentialsValid', false);
                    } else {
                        City.state.id = response.data;
                        commit('setAreCredentialsValid', true);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },

        async verifyAccount({ commit }, confirmationToken) {
            await axios
                .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/confirmAccount`, confirmationToken)
                .then(response => {
                    commit('setConfirmAccountResponse', response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        async sendCredentialsEmail({ commit }, { email, username, password, both }) {
            await axios
                .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/recoverCredentials`, { email, username, password, both })
                .then(response => {
                    commit('setCredentialsRecoveryResponse', response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        async changePassword({ commit }, { credentialsToken, password }) {
            await axios
                .post(`${process.env.VUE_APP_ADDRESS_AUTHENTICATION}/changepass`, { credentialsToken, password })
                .then(response => {
                    commit('setChangePasswordResponse', response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        storeCredentials({ commit }, { email, password, username }) {
            commit('setCredentials', { email, password, username });
        },

        updateAttemptedLogin({ commit }, data) {
            commit('setAttemptedLogin', data);
        },
    },

    getters: {
        getEmail(state) {
            return state.email;
        },

        getPassword(state) {
            return state.password;
        },

        getUsername(state) {
            return state.username;
        },

        getUniqueEmailBool(state) {
            return state.isUniqueEmail;
        },

        getUniqueUsernameBool(state) {
            return state.isUniqueUsername;
        },

        getAreCredentialsValid(state) {
            return state.areCredentialsValid;
        },

        getAttemptedLogin(state) {
            return state.attemptedLogin;
        },

        getEmailValid(state) {
            return state.emailValid;
        },

        getConfirmAccountResponse(state) {
            return state.confirmAccountResponse;
        },

        getCredentialsRecoveryResponse(state) {
            return state.credentialsRecoveryResponse;
        },

        getChangePasswordResponse(state) {
            return state.changePasswordResponse;
        },

        getSuccessfulLogout(state) {
            return state.successfulLogout;
        },
    },
};
