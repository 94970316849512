export const ActionTypeIdentifier = Object.freeze({
    INCOMING_ATTACK: 'INCOMING_ATTACK',
    OUTGOING_ATTACK: 'OUTGOING_ATTACK',
    RETURNING_ATTACK: 'RETURNING_ATTACK',

    INCOMING_REINFORCEMENT: 'INCOMING_REINFORCEMENT',
    OUTGOING_REINFORCEMENT: 'OUTGOING_REINFORCEMENT',
    RETURNING_REINFORCEMENT: 'RETURNING_REINFORCEMENT',

    DETECT_SPIES_MISSION: 'DETECT_SPIES_MISSION',
    INCOMING_SPY_MISSION: 'INCOMING_SPY_MISSION',
    OUTGOING_SPY_MISSION: 'OUTGOING_SPY_MISSION',
    RETURNING_SPIES: 'RETURNING_SPIES',

    PATROL: 'PATROL',

    INCOMING_INTERCEPT: 'INCOMING_INTERCEPT',
    OUTGOING_INTERCEPT: 'OUTGOING_INTERCEPT',
    RETURNING_INTERCEPT: 'RETURNING_INTERCEPT',

    INCOMING_TRADING_TRANSPORT: 'INCOMING_TRADING_TRANSPORT',
    OUTGOING_TRADING_TRANSPORT: 'OUTGOING_TRADING_TRANSPORT',
    RETURNING_TRADING_TRANSPORT: 'RETURNING_TRADING_TRANSPORT',

    INCOMING_SEND_RESOURCES_TRANSPORT: 'INCOMING_SEND_RESOURCES_TRANSPORT',
    OUTGOING_SEND_RESOURCES_TRANSPORT: 'OUTGOING_SEND_RESOURCES_TRANSPORT',
    RETURNING_SEND_RESOURCES_TRANSPORT: 'RETURNING_SEND_RESOURCES_TRANSPORT',
});

export const ActionDescriptionIdentifier = Object.freeze({
    INCOMING_ATTACK: 'Attack from',
    OUTGOING_ATTACK: 'Attacking',
    RETURNING_ATTACK: 'Attack returning from',

    INCOMING_REINFORCEMENT: 'Reinforcement from',
    OUTGOING_REINFORCEMENT: 'Reinforcing',
    RETURNING_REINFORCEMENT: 'Reinforcement returning from',

    DETECT_SPIES_MISSION: 'Detecting spies in',
    INCOMING_SPY_MISSION: 'Enemy spies from',
    OUTGOING_SPY_MISSION: 'Infiltrating',
    RETURNING_SPIES: 'Spies returning from',

    PATROL: 'Patrolling',

    INCOMING_INTERCEPT: 'Intercepting',
    OUTGOING_INTERCEPT: 'Enemy intercept targeting',
    RETURNING_INTERCEPT: 'Intercept returning',

    INCOMING_TRADING_TRANSPORT: 'Trade from',
    OUTGOING_TRADING_TRANSPORT: 'Trading with',
    RETURNING_TRADING_TRANSPORT: 'Transport returning from',

    INCOMING_SEND_RESOURCES_TRANSPORT: 'Receiving resources from',
    OUTGOING_SEND_RESOURCES_TRANSPORT: 'Sending resources to',
    RETURNING_SEND_RESOURCES_TRANSPORT: 'Transport returning from',
});

export const ActionTargetIdentifier = Object.freeze({
    INCOMING_ATTACK: 'fromCity',
    OUTGOING_ATTACK: 'toCity',
    RETURNING_ATTACK: 'fromCity',

    INCOMING_REINFORCEMENT: 'fromCity',
    OUTGOING_REINFORCEMENT: 'toCity',
    RETURNING_REINFORCEMENT: 'fromCity',

    DETECT_SPIES_MISSION: 'initiatedInsideCity',
    INCOMING_SPY_MISSION: 'fromCity',
    OUTGOING_SPY_MISSION: 'toCity',
    RETURNING_SPIES: 'fromCity',

    PATROL: 'fromCity',

    INCOMING_INTERCEPT: 'interceptedAttack',
    OUTGOING_INTERCEPT: 'interceptedAttack',
    RETURNING_INTERCEPT: 'toCity',

    INCOMING_TRADING_TRANSPORT: 'fromCity',
    OUTGOING_TRADING_TRANSPORT: 'toCity',
    RETURNING_TRADING_TRANSPORT: 'fromCity',

    INCOMING_SEND_RESOURCES_TRANSPORT: 'fromCity',
    OUTGOING_SEND_RESOURCES_TRANSPORT: 'toCity',
    RETURNING_SEND_RESOURCES_TRANSPORT: 'fromCity',
});

export const CityPropertiesThatAreActions = Object.freeze({
    ATTACKS: 'attacks',
    RETURNING_ATTACKS: 'returningAttacks',
    INTERCEPTS: 'attackIntercepts',
    RETURNING_INTERCEPTS: 'attackInterceptsReturning',
    REINFORCEMENTS: 'reinforcements',
    RETURNING_REINFORCEMENTS: 'reinforcementsReturning',
    SPY_MISSIONS: 'spyMovements',
    RETURNING_SPY_MISSIONS: 'spyMovementsReturning',
    DETECT_SPIES_MISSIONS: 'detectSpiesMissions',
    PATROLS: 'patrols',
    TRANSPORTS: 'transports',
    RETURNING_TRANSPORTS: 'transportsReturning',
});

export const ActionsWithResources = [
    ActionTypeIdentifier.RETURNING_ATTACK,
    ActionTypeIdentifier.INCOMING_TRADING_TRANSPORT,
    ActionTypeIdentifier.OUTGOING_TRADING_TRANSPORT,
    ActionTypeIdentifier.INCOMING_SEND_RESOURCES_TRANSPORT,
    ActionTypeIdentifier.RETURNING_SEND_RESOURCES_TRANSPORT,
];

export const ReturningActions = [
    ActionTypeIdentifier.RETURNING_ATTACK,
    ActionTypeIdentifier.RETURNING_REINFORCEMENT,
    ActionTypeIdentifier.RETURNING_SPIES,
    ActionTypeIdentifier.RETURNING_INTERCEPT,
    ActionTypeIdentifier.RETURNING_TRADING_TRANSPORT,
    ActionTypeIdentifier.RETURNING_SEND_RESOURCES_TRANSPORT,
];

export const IncomingActions = [
    ActionTypeIdentifier.INCOMING_ATTACK,
    ActionTypeIdentifier.INCOMING_REINFORCEMENT,
    ActionTypeIdentifier.INCOMING_TRADING_TRANSPORT,
    ActionTypeIdentifier.INCOMING_SEND_RESOURCES_TRANSPORT,
];
