<template>
    <div v-if="error" id="error-popup" :class="{ 'pop-in': this.popIn, 'pop-out': this.popOut }">
        <p>
            {{ error }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'ErrorPopup',
    props: {
        error: {
            type: String,
            default: '',
        },
        popOut: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            popIn: false,
        };
    },
    watch: {
        error: {
            handler() {
                if (this.error) {
                    setTimeout(() => {
                        this.popIn = true;
                    }, 10);
                } else {
                    this.popIn = false;
                }
            },
        },
    },
};
</script>

<style scoped>
#error-popup {
    display: flex;
    justify-content: center;
    z-index: 1;
    position: absolute;
    top: 8vh;
    width: 100%;
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
}

#error-popup > p {
    display: flex;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), #c72626, rgba(0, 0, 0, 0));
    margin: 0;
    padding: 2rem 8rem;
    color: ghostwhite;
    text-align: center;
    font-family: 'sansation', sans-serif;
    font-weight: 500;
}

.pop-in {
    transform: translateY(2vh);
    opacity: 1 !important;
}

.pop-out {
    transform: translateY(0);
    opacity: 0 !important;
}
</style>
